import { useEffect, useMemo, useState } from 'react';

export type BroadcastMessageType = {
  eventType: string;
  data?: Record<string, unknown>;
};

export const useBroadcastChannel = (channelName: string) => {
  const [message, setMessage] = useState<BroadcastMessageType | null>(null);
  const channel = useMemo(() => new BroadcastChannel(channelName), [channelName]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      setMessage(event.data);
    };

    channel.addEventListener('message', handleMessage);

    return () => {
      channel.removeEventListener('message', handleMessage);
      channel.close();
    };
  }, [channel]);

  const sendMessage = (msg: BroadcastMessageType) => {
    channel?.postMessage(msg);
  };

  return { message, sendMessage };
};
