import { defaultIconProps, IconProps } from './IconProps';

export const IconGraduationHat: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g clipPath="url(#clip0_46_623)">
      <path
        d="M4.16665 8.36306V14.3184C4.16665 14.674 4.16665 14.8518 4.21675 15.0088C4.26105 15.1476 4.33336 15.274 4.42843 15.379C4.53594 15.4976 4.6831 15.5771 4.9774 15.7362L9.34407 18.0958C9.58456 18.2258 9.70482 18.2908 9.83095 18.3164C9.94266 18.339 10.0573 18.339 10.169 18.3164C10.2951 18.2908 10.4154 18.2258 10.6559 18.0958L15.6059 15.4209C15.9002 15.2619 16.0474 15.1824 16.1549 15.0637C16.2499 14.9588 16.3222 14.8324 16.3665 14.6936C16.4166 14.5366 16.4166 14.3588 16.4166 14.0031V8.04783M0.833313 6.56177L9.67202 1.78544C9.79227 1.72046 9.8524 1.68797 9.91546 1.67518C9.97132 1.66386 10.0286 1.66386 10.0845 1.67518C10.1476 1.68797 10.2077 1.72046 10.3279 1.78544L19.1666 6.56177L10.3279 11.3381C10.2077 11.4031 10.1476 11.4356 10.0845 11.4484C10.0286 11.4597 9.97132 11.4597 9.91546 11.4484C9.8524 11.4356 9.79227 11.4031 9.67202 11.3381L0.833313 6.56177Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M0.833313 7.08331L0.833313 12.0833" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_46_623">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconGraduationHat.defaultProps = defaultIconProps;
