import { defaultIconProps, IconProps } from './IconProps';

export const IconData: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10989_20353)">
      <path
        d="M17.667 18.3332C17.9003 18.3332 18.017 18.3332 18.1062 18.2878C18.1846 18.2478 18.2483 18.1841 18.2882 18.1057C18.3337 18.0165 18.3337 17.8999 18.3337 17.6665V8.99984C18.3337 8.76648 18.3337 8.64981 18.2882 8.56068C18.2483 8.48228 18.1846 8.41853 18.1062 8.37859C18.017 8.33317 17.9003 8.33317 17.667 8.33317L15.667 8.33317C15.4336 8.33317 15.317 8.33317 15.2278 8.37859C15.1494 8.41853 15.0857 8.48227 15.0457 8.56068C15.0003 8.64981 15.0003 8.76648 15.0003 8.99984V10.9998C15.0003 11.2332 15.0003 11.3499 14.9549 11.439C14.915 11.5174 14.8512 11.5811 14.7728 11.6211C14.6837 11.6665 14.567 11.6665 14.3337 11.6665H12.3337C12.1003 11.6665 11.9836 11.6665 11.8945 11.7119C11.8161 11.7519 11.7524 11.8156 11.7124 11.894C11.667 11.9831 11.667 12.0998 11.667 12.3332V14.3332C11.667 14.5665 11.667 14.6832 11.6216 14.7723C11.5816 14.8507 11.5179 14.9145 11.4395 14.9544C11.3504 14.9998 11.2337 14.9998 11.0003 14.9998H9.00033C8.76697 14.9998 8.65029 14.9998 8.56116 15.0453C8.48276 15.0852 8.41902 15.1489 8.37907 15.2273C8.33366 15.3165 8.33366 15.4331 8.33366 15.6665V17.6665C8.33366 17.8999 8.33366 18.0165 8.37907 18.1057C8.41902 18.1841 8.48276 18.2478 8.56116 18.2878C8.65029 18.3332 8.76697 18.3332 9.00033 18.3332L17.667 18.3332Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33366 5.6665C8.33366 5.43315 8.33366 5.31647 8.37907 5.22734C8.41902 5.14894 8.48276 5.0852 8.56116 5.04525C8.65029 4.99984 8.76697 4.99984 9.00033 4.99984H11.0003C11.2337 4.99984 11.3504 4.99984 11.4395 5.04525C11.5179 5.0852 11.5816 5.14894 11.6216 5.22734C11.667 5.31647 11.667 5.43315 11.667 5.6665V7.6665C11.667 7.89986 11.667 8.01654 11.6216 8.10567C11.5816 8.18407 11.5179 8.24781 11.4395 8.28776C11.3504 8.33317 11.2337 8.33317 11.0003 8.33317H9.00033C8.76697 8.33317 8.65029 8.33317 8.56116 8.28776C8.48276 8.24781 8.41902 8.18407 8.37907 8.10567C8.33366 8.01654 8.33366 7.89986 8.33366 7.6665V5.6665Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.50033 10.6665C2.50033 10.4331 2.50033 10.3165 2.54574 10.2273C2.58569 10.1489 2.64943 10.0852 2.72783 10.0453C2.81696 9.99984 2.93364 9.99984 3.16699 9.99984H5.16699C5.40035 9.99984 5.51703 9.99984 5.60616 10.0453C5.68456 10.0852 5.7483 10.1489 5.78825 10.2273C5.83366 10.3165 5.83366 10.4331 5.83366 10.6665V12.6665C5.83366 12.8999 5.83366 13.0165 5.78825 13.1057C5.7483 13.1841 5.68456 13.2478 5.60616 13.2878C5.51703 13.3332 5.40035 13.3332 5.16699 13.3332H3.16699C2.93364 13.3332 2.81696 13.3332 2.72783 13.2878C2.64943 13.2478 2.58569 13.1841 2.54574 13.1057C2.50033 13.0165 2.50033 12.8999 2.50033 12.6665V10.6665Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.66699 2.33317C1.66699 2.09982 1.66699 1.98314 1.71241 1.89401C1.75235 1.81561 1.8161 1.75187 1.8945 1.71192C1.98363 1.6665 2.1003 1.6665 2.33366 1.6665H4.33366C4.56701 1.6665 4.68369 1.6665 4.77282 1.71192C4.85122 1.75187 4.91496 1.81561 4.95491 1.89401C5.00033 1.98314 5.00033 2.09982 5.00033 2.33317V4.33317C5.00033 4.56653 5.00033 4.6832 4.95491 4.77233C4.91496 4.85073 4.85122 4.91448 4.77282 4.95442C4.68369 4.99984 4.56701 4.99984 4.33366 4.99984H2.33366C2.1003 4.99984 1.98363 4.99984 1.8945 4.95442C1.8161 4.91448 1.75235 4.85073 1.71241 4.77233C1.66699 4.6832 1.66699 4.56653 1.66699 4.33317V2.33317Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10989_20353">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconData.defaultProps = defaultIconProps;
