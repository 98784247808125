import { defaultIconProps, IconProps } from './IconProps';

export const IconChart01: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M15 16.6666V8.33331M10 16.6666V3.33331M5 16.6666V11.6666"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconChart01.defaultProps = defaultIconProps;
