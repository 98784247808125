/* istanbul ignore file */
// ignoring because markdown editor not working in jest
import React from 'react';
import { MDXRemote } from 'next-mdx-remote';
import { useIsSSR } from '@react-aria/ssr';
import { MdxProps } from 'types';
import { MarkdownEditor, MarkdownPreview } from 'components/Markdown';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { replaceCustomVariables } from 'components/Markdown/customVariables';
import { EmptyMarkdown } from './EmptyMarkdown';

const MarkdownPreviewWithChecks = ({
  editableEntity,
  fallback,
  customVariables,
}: {
  editableEntity: CF.UI.EditableEntity;
  fallback: () => JSX.Element;
  customVariables: { app_id: string; user_id: string };
}) => {
  return editableEntity?.data?.notes?.length > 0 ||
    (typeof editableEntity?.data?.notes === 'function' && editableEntity?.data?.notes()?.length > 0) ? (
    <MarkdownPreview
      source={replaceCustomVariables(
        (editableEntity?.data?.notes?.length > 0 && editableEntity.data.notes) || editableEntity.data.notes(),
        customVariables,
      )}
    />
  ) : (
    <>{fallback()}</>
  );
};

function EditableMarkdown_({
  mdxSource,
  editableEntity,
  isEditing,
}: {
  mdxSource?: MdxProps;
  editableEntity: CF.UI.EditableEntity;
  isEditing: boolean;
}): JSX.Element | null {
  const isSSR = useIsSSR();
  const { appId, userOrOrgId } = useRouter().query as { userOrOrgId: string; appId: string };
  const customVariables = { app_id: appId, user_id: userOrOrgId };

  return (
    <>
      {isSSR ? (
        // react markdown doesnt work on SSR
        mdxSource ? (
          <MDXRemote {...mdxSource} />
        ) : (
          <MarkdownPreviewWithChecks editableEntity={editableEntity} fallback={() => <>--</>} customVariables={customVariables} />
        )
      ) : // eslint-disable-next-line unicorn/no-nested-ternary
      isEditing ? (
        <MarkdownEditor
          height={600}
          initialValue={('notes' in editableEntity.data && editableEntity.data.notes) || ''}
          update={(x) => editableEntity.setValue('notes', x)}
        />
      ) : (
        <MarkdownPreviewWithChecks editableEntity={editableEntity} fallback={EmptyMarkdown} customVariables={customVariables} />
      )}
    </>
  );
}

export const EditableMarkdown = observer(EditableMarkdown_);
