import { useId } from 'react-aria';
import { defaultIconProps, IconProps } from './IconProps';

export const IconEnterprisePlan: import('react').FC<IconProps> = ({ size, ...rest }) => {
  const id = useId();

  return (
    <svg width={size} height={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath={`url(#clip0_2151_2742_${id})`}>
        <path
          d="M18.2846 12.2558C16.9691 13.5713 18.0381 14.7563 16.0455 16.7489C14.0335 18.7609 12.7421 17.7983 11.5523 18.9881C10.5754 19.965 10.6025 21.5592 11.5911 22.5477C12.5797 23.5363 14.1835 23.5538 15.1507 22.5865C16.4663 21.271 15.3973 20.086 17.3899 18.0934C19.4019 16.0814 20.6933 17.044 21.883 15.8542C22.86 14.8772 22.8329 13.2831 21.8443 12.2945C20.8557 11.306 19.2616 11.2788 18.2846 12.2558Z"
          fill="#36BFFF"
        />
        <path
          d="M16.0818 4.82949C14.7566 6.15467 15.9912 7.2926 14.3275 8.95633C12.7895 10.4943 11.5938 9.33725 10.1138 10.8172C9.19491 11.7361 9.30049 13.3507 10.2396 14.2898C11.1788 15.229 12.803 15.3249 13.7993 14.3286C15.1245 13.0034 13.8898 11.8655 15.5536 10.2017C17.0915 8.66374 18.2873 9.8208 19.7672 8.34086C20.6862 7.42194 20.5806 5.80739 19.6414 4.86825C18.712 3.91943 17.0877 3.82352 16.0818 4.82949Z"
          fill="#36BFFF"
        />
        <path
          d="M7.94777 20.1809C6.97081 21.1579 5.36701 21.1404 4.36854 20.142C3.37008 19.1435 3.36251 17.5496 4.32979 16.5823C5.29708 15.615 6.90088 15.6325 7.88946 16.6211C8.87804 17.6096 8.92473 19.204 7.94777 20.1809Z"
          fill="#36BFFF"
        />
        <path
          d="M8.64629 2.61702C7.34046 3.92285 8.4094 5.10786 6.41679 7.10047C4.40484 9.11242 3.11342 8.14988 1.92367 9.33964C0.946708 10.3166 0.973841 11.9107 1.96242 12.8993C2.95099 13.8879 4.5548 13.9053 5.52208 12.9381C6.83759 11.6226 5.76865 10.4375 7.76126 8.44493C9.77321 6.43298 11.0646 7.39552 12.2544 6.20576C13.2313 5.2288 13.2042 3.63467 12.2156 2.6461C11.2271 1.65752 9.62325 1.64006 8.64629 2.61702Z"
          fill="#36BFFF"
        />
      </g>
      <defs>
        <clipPath id={`clip0_2151_2742_${id}`}>
          <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

IconEnterprisePlan.defaultProps = defaultIconProps;
