import { useState } from 'react';
import { cx } from '@linaria/core';
import { breakpoints } from 'styles/utils';
import { IconCloseSquare } from 'components/Icons/IconCloseSquare';
import { IconHamburgerMenu } from 'components/Icons/IconHamburgerMenu';
import { Drawer } from 'modules/Sidebar/components/Drawer/Drawer';
import { useBreakpoint } from 'utils/responsive';
import { menu, mobileMenu, motionMenuIcon, sidebarWrapper } from 'modules/Sidebar/Sidebar.styles';
import { removeFlexGap, sidebarBorder, sidebarPadding, userArea } from './Sidebar.styles';

export interface BaseUserSettingsSidebarProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactChild;
}

export const BaseUserSettingsSidebar: React.FC<BaseUserSettingsSidebarProps> = ({ className, children, ...props }) => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const shouldShowMobileNav = useBreakpoint(breakpoints.down('tab'));
  const toggleMenu = (): void => setIsMobileNavOpen((x) => !x);

  return (
    <aside
      className={cx(
        sidebarWrapper,
        sidebarPadding,
        shouldShowMobileNav && sidebarBorder,
        shouldShowMobileNav && !isMobileNavOpen && removeFlexGap,
        className,
      )}
      {...props}
    >
      <section className={userArea}>
        {shouldShowMobileNav && (
          <button type="button" data-testid="burger-btn" className={motionMenuIcon} onClick={toggleMenu}>
            {isMobileNavOpen ? (
              <IconCloseSquare size={20} color="#282636" data-testid="close-icon" />
            ) : (
              <IconHamburgerMenu size={20} color="#282636" data-testid="hamburger-icon" />
            )}
          </button>
        )}
      </section>

      {shouldShowMobileNav ? (
        <section className={mobileMenu} data-testid="mobile-menu">
          <Drawer isOpen={isMobileNavOpen}>{children}</Drawer>
        </section>
      ) : (
        <section className={menu} data-testid="desktop-menu">
          {children}
        </section>
      )}
    </aside>
  );
};
