import { createContext, useContext, PropsWithChildren, useEffect, useMemo } from 'react';
import { useFlags, useLDClient, LDProvider as OriginalProvider } from 'launchdarkly-react-client-sdk';
import * as ld from 'launchdarkly-js-client-sdk';
import { useQuery } from 'react-query';
import getConfig from 'next/config';
import { offlineAllowedOrgRolesForControlCenter } from 'modules/UsageDashboard/constants/accessLevel';
import { FeatureFlagsType, getLaunchDarklyClientSideID } from './common';
import { createLDContext } from './context';

const clientSideID = getLaunchDarklyClientSideID();

const isOfflineMode = getConfig()?.publicRuntimeConfig.OFFLINE_MODE as boolean;

/**
 * Gets feature flags from LaunchDarkly. For use in react components.
 * For use outside react components, use the `getFeatureFlag` util function in
 * the browser-client.ts module.
 */
export function useFeatureFlagsLD() {
  return isOfflineMode ? useOfflineFeatureFlagsLD() : useFlags<FeatureFlagsType>();
}

const FeatureFlagsContextProvider = ({ children, context }: { children: React.ReactNode; context: ld.LDContext }) => {
  const ldClient = useLDClient();
  const flags = useFlags();
  useEffect(() => {
    const fetchFlags = async () => {
      try {
        await ldClient?.waitUntilReady();
        await ldClient?.identify(context);
        localStorage.setItem('ldFlags', JSON.stringify({ ...flags }));
      } catch (e) {
        console.error('Error fetching flags', e);
      }
    };
    fetchFlags();
  }, [ldClient, flags, context]);
  return <>{children}</>;
};

export const FeatureFlagsProviderLD = ({
  children,
  initialAuthData,
}: {
  children: React.ReactNode;
  initialAuthData?: CF.LocalUserWithOrgUserAndPlan;
}) => {
  const context = useMemo(() => createLDContext(initialAuthData), [initialAuthData]);

  return isOfflineMode ? (
    <OfflineLDProvider>{children}</OfflineLDProvider>
  ) : (
    <OriginalProvider
      context={context}
      clientSideID={clientSideID}
      options={{ streaming: true }}
      reactOptions={{ useCamelCaseFlagKeys: false }}
      deferInitialization
    >
      <FeatureFlagsContextProvider context={context}>{children}</FeatureFlagsContextProvider>
    </OriginalProvider>
  );
};

const OfflineFeatureFlagContext = createContext<FeatureFlagsType>({
  inputManagerSearchResultCountIndicator: false,
  'input-viewer-sidebar-ai-assist-panel': false,
  'input-viewer-video-track-timeline': false,
  'model-creation-huggingface-import': false,
  'model-creation-triton-upload': false,
  batchPredictModal: false,
  'leaderboard-panel': false,
  'primary-input-type-app-creation': false,
  'upgrade-app-to-universal-workflow-modal-button': false,
  'filter-by-input-type': false,
  'show-inputs-counts': false,
  'annotations-mode': false,
  'default-base-workflow-option-for-text': 'Language-Understanding',
  'default-base-workflow-option-for-image': 'Universal',
  changelog: '',
  'documentation-link-lookup': {},
  'inputs-manager-grid-video-controls': false,
  'filter-by-labeler': false,
  'filter-by-status': false,
  'filters-tooltip-text': {},
  'view-annotation-metrics-button': false,
  'show-image-mask-annotations': false,
  thirdPartyApiKeyAvailableLDFF: false,
  autoAnnotationLabelingTaskFF: false,
  enableTemplateFeatureLdff: false,
  textGenImageUploadLDFF: false,
  'hide-similarity-search-for-text': false,
  'enable-resource-pricing': false,
  'show-go-to-old-task-review-screen-ldff': false,
  resourceVersionSelectorFF: true,
  resourceVersionSelectorInModelFF: true,
  'enable-dataset-listing': false,
  modulePublishing: false,
  nodePoolDeploymentsLDFF: false,
  controlCenter: false,
  controlCenterCosts: false,
  controlCenterAuditLogs: false,
  editNodePoolDeploymentsLDFF: false,
  workflowVersionEvaluation: false,
  workflowsV2LDFF: false,
  enableNewInputViewerFeatures: true,
  hideFeaturedResources: false,
  nodePoolsRestrictInstancesLDFF: false,
  computeMerchandisingLDFF: false,
  generateCoverImage: false,
  modelPlaygroundLDFF: false,
  allowAllPlaygroundModelsLDFF: false,
  streamingPlaygroundLDFF: false,
  allowedOrgRolesForControlCenter: offlineAllowedOrgRolesForControlCenter,
  'enable-new-labeler-screen': false,
  enableHomePage: false,
  homePageDeployTrendingModelLDFF: false,
  'new-explore-page': false,
  billingPlans: { plans: [] },
  'enable-vision-model-page': false,
});

/**
 * This is an offline provider that returns the feature flags from the server.
 */
function OfflineLDProvider(props: PropsWithChildren<unknown>) {
  const { data: flags } = useQuery<FeatureFlagsType>('featureFlags', async () => {
    const resp = await fetch('/api/offline-feature-flags');
    const responseJson = await resp.json();

    return responseJson.flags;
  });

  if (!flags) {
    return null;
  }

  return <OfflineFeatureFlagContext.Provider value={flags}>{props.children}</OfflineFeatureFlagContext.Provider>;
}

export function useOfflineFeatureFlagsLD() {
  return useContext(OfflineFeatureFlagContext);
}
