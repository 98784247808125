import { Popover, PopoverGroup, PopoverStateReturn } from 'components/Popover';
import { IconBell, IconBellV2, IconCloseSquare } from 'components/Icons';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { OrgInvitation } from 'modules/Organizations/components/OrgInvitation';
import { useModalActions } from 'components/ModalManager/Context';
import { Button } from 'components/ui_library/Button';
import { IconProps } from 'components/Icons/IconProps';
import { omit } from 'rambda';
import { ScrollWrapper } from 'components/Dialog/ScrollWrapper';
import { badgeStyles, notiticationPopoverStyles, emptyNotifications } from 'components/Navbar/Navbar.styles';

const NOTIFICATIONS_MODAL_ID = 'notifications_modal_id';

interface BadgeIconProps extends React.PropsWithChildren<IconProps> {
  badgeProps?: BadgeProps;
}

type DesktopNotificationsPopoverProps = {
  orgInvitesList?: CF.API.Organizations.OrgMemberInvite[];
  popover: PopoverStateReturn;
};

export function BadgeIcon({ badgeProps, ...iconProps }: BadgeIconProps) {
  const WrappedBadgeValue = badgeProps?.badgeContent ? <p data-testid="notification-badge-count">{badgeProps?.badgeContent}</p> : null;

  const restBadgeProps = omit(['badgeContent'], badgeProps);

  return (
    <Badge color="error" overlap="circular" className={badgeStyles} badgeContent={WrappedBadgeValue} {...restBadgeProps}>
      <IconBell {...iconProps} />
    </Badge>
  );
}

export function EmptyNotifications() {
  return (
    <div data-testid="empty-notifications" className={emptyNotifications}>
      <IconBellV2 size={112} />
      <h2>No new notifications.</h2>
      <p>Check back later.</p>
    </div>
  );
}

export function DesktopNotificationsPopover(props: DesktopNotificationsPopoverProps) {
  return (
    <Popover popover={props.popover} data-testid="notifications-popover" className={notiticationPopoverStyles}>
      <ScrollWrapper style={{ maxHeight: 500 }}>
        {props.orgInvitesList?.length ? (
          props.orgInvitesList.map((invitation) => (
            <PopoverGroup key={invitation.id}>
              <OrgInvitation invitation={invitation} />
            </PopoverGroup>
          ))
        ) : (
          <EmptyNotifications />
        )}
      </ScrollWrapper>
    </Popover>
  );
}

export function MobileModalContent(props: { orgInvitesList?: CF.API.Organizations.OrgMemberInvite[] }) {
  const { closeModal } = useModalActions();

  return (
    <>
      <section className="heading">
        <Button variant="unstyled" onClick={() => closeModal({ id: NOTIFICATIONS_MODAL_ID })}>
          <IconCloseSquare size={24} />
        </Button>
      </section>

      <section className="body">
        <h1>Notifications</h1>
        {props.orgInvitesList?.length ? (
          props.orgInvitesList.map((invitation) => <OrgInvitation key={invitation.id} invitation={invitation} />)
        ) : (
          <EmptyNotifications />
        )}
      </section>
    </>
  );
}
