import { cx, css } from '@linaria/core';
import { flex, theming } from 'styles/utils';
import { ellipsis } from 'polished';
export const accountSelector = cx("omponents__Navbar__components__AccountSelector__styles_ts-accountSelector-a1md0z5w", flex.init, flex.direction.column);
export const accountDefault = "omponents__Navbar__components__AccountSelector__styles_ts-accountDefault-a1wx18dh";
export const truncateName = "omponents__Navbar__components__AccountSelector__styles_ts-truncateName-tx8dp8o";
export const createOrgButton = "omponents__Navbar__components__AccountSelector__styles_ts-createOrgButton-c1q9keni";
export const accountSelectorPopoverWrapper = "omponents__Navbar__components__AccountSelector__styles_ts-accountSelectorPopoverWrapper-a1os5lgx";
export const popoverButton = "omponents__Navbar__components__AccountSelector__styles_ts-popoverButton-p1thyhzd";
export const selectedAccountStyles = "omponents__Navbar__components__AccountSelector__styles_ts-selectedAccountStyles-sktkfon";

require("./styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./styles.ts");