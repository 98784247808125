import React, { useState, useEffect } from 'react';
import { cx } from '@linaria/core';
import { useMeasure } from 'react-use';
import { format } from 'date-fns';
import { EntityDetails } from 'components/EntityDetails';
import { flex } from 'styles/utils';
import { SocialShareList } from 'components/SocialShareList/SocialShareList';
import { MdxProps } from 'types';
import { useMstModel } from 'utils/mst/useMstModel';
import { editableAppMST } from 'modules/Apps/editableAppMst';
import { observer } from 'mobx-react-lite';
import { EditableDetail } from 'modules/EntityViewers/components/EditableDetail/EditableDetail';
import { SupportedEditableModelFields } from 'modules/Models/components/ModelViewer/useEditableModel';
import { Link } from 'components/Link';
import { Col, Row } from 'components/Grid';
import { SwitchAppViewToggle } from 'modules/Apps/components/CreateApplication/SwitchAppViewToggle';
import { CoverImage } from 'components/CoverImage';
import { AppInfo } from './AppInfo';
import { AppTools } from './AppTools';
import { AppTitle } from './AppTitle';
import { flexShowOnTab, flexHideOnTab } from './styles';
import { appInfo, mainContainer, entityDetailsContainer, infoContainer, detailsContainer, mobileHeader } from './AppViewer.styles';
import { AppModelTypes } from './AppModelTypes';
import { AppResources } from './AppResources';

export enum ShowColumn {
  Info = 'Info',
  Details = 'Details',
}

interface SingleAppViewProps {
  app: CF.API.Apps.App;
  mdxSource?: MdxProps;
  userOrOrgId?: string;
  appId?: string;
  isAppTemplate?: boolean;
}

export const SingleAppView_ = ({ app, mdxSource, userOrOrgId, appId, isAppTemplate }: SingleAppViewProps): JSX.Element => {
  const [showColumn, setShowColumn] = useState<ShowColumn | null>(null);
  const [measureRef, { width }] = useMeasure();
  const mRefTyped = measureRef as React.LegacyRef<HTMLDivElement>;

  useEffect(() => {
    if (width) {
      if (width < 720) {
        setShowColumn((oldValue) => (!oldValue ? ShowColumn.Info : oldValue));
      } else {
        setShowColumn(null);
      }
    }
  }, [width]);

  const editableApp = useMstModel(editableAppMST, [userOrOrgId, appId, app], () => [{ data: app, appId: app.id, userId: app.user_id }]);

  const appFields = [
    {
      label: 'Description',
      value: (
        <EditableDetail editableEntity={editableApp as CF.UI.EditableEntity} entityName="model" field={SupportedEditableModelFields.description} />
      ),
    },
    { label: 'Base Workflow', value: app.default_workflow?.id },
    { label: 'Last Updated', value: format(new Date(editableApp.data.modified_at), 'MMM dd, yyyy') },
    { label: 'Default Language', value: app.default_language },
    {
      label: 'Share',
      value: <SocialShareList />,
    },
    {
      label: <Link href={`/${editableApp.userId}/${editableApp.appId}/models`}>Models</Link>,
      value: <AppModelTypes userOrOrgId={userOrOrgId as string} appId={appId as string} />,
    },
  ];

  if (!showColumn) {
    appFields.unshift({ label: '', value: <CoverImage entity={editableApp.data} entityName="app" /> });
  }

  return (
    <div ref={mRefTyped}>
      {showColumn && (
        <div className={cx(flex.init, flex.direction.row, flex.gap[1], mobileHeader)}>
          <div style={{ width: '25%' }}>
            <CoverImage entity={editableApp.data} entityName="app" />
          </div>
          <div className={cx(flex.init, flex.direction.column)}>
            <div className={flexShowOnTab}>
              <AppTools entity={editableApp} />
            </div>
            <div className={flexShowOnTab}>
              <AppTitle app={app} editableEntity={editableApp as CF.UI.EditableEntity} />
            </div>
          </div>
        </div>
      )}
      <div className={flexShowOnTab}>
        <SwitchAppViewToggle
          toggles={Object.entries(ShowColumn).map(([id, text]) => ({ id, name: text }))}
          active={showColumn as string}
          onToggle={(tab) => {
            setShowColumn(tab as ShowColumn);
          }}
        />
      </div>
      {showColumn !== ShowColumn.Details ? (
        <div className={mainContainer}>
          <div className={infoContainer}>
            <div className={appInfo}>
              <div className={flexHideOnTab}>
                <AppTitle app={app} editableEntity={editableApp as CF.UI.EditableEntity} />
              </div>
            </div>
          </div>

          {userOrOrgId && appId && <AppResources userOrOrgId={userOrOrgId} appId={appId} isAppTemplate={isAppTemplate} app={app} />}

          <Row spacing={0}>
            <Col xs={12} sm={7} md={9}>
              <AppInfo editableEntity={editableApp as CF.UI.EditableEntity} mdxSource={mdxSource} />
            </Col>

            {!showColumn && (
              <Col xs={12} sm={5} md={3}>
                <div className={detailsContainer}>
                  <EntityDetails className={cx('EntityProperties', entityDetailsContainer)} data-testid="app-details-sidebar" fields={appFields} />
                </div>
              </Col>
            )}
          </Row>
        </div>
      ) : (
        <div className={detailsContainer} style={{ border: '#f00 1px solid !important' }}>
          <EntityDetails className={cx('EntityProperties', entityDetailsContainer)} data-testid="app-details-sidebar" fields={appFields} />
        </div>
      )}
    </div>
  );
};

export const SingleAppView = observer(SingleAppView_);
