import { Button } from 'components/Button';
import { useRouter } from 'next/router';
import { useCallback, useRef } from 'react';
import { useFeatureFlagsLD } from 'modules/FeatureFlagsLD/FeatureFlagsProviderLD';
import { useCurrentUserId } from 'modules/Auth/hooks';
import { useClickAway } from 'react-use';
import { welcomeMsgWrapper } from '../OnboardingDialog.styles';

export function WelcomeToClarifai({ userFullname, closeAction }: { userFullname: string; closeAction: () => void }) {
  const router = useRouter();
  const { enableHomePage } = useFeatureFlagsLD();
  const userId = useCurrentUserId();
  const ref = useRef(null);

  useClickAway(ref, closeAction);

  const redirectToHome = useCallback(() => {
    const path = enableHomePage ? '/home' : `/${userId}/apps`;
    router.push(path);
    closeAction();
  }, [userId, router, closeAction, enableHomePage]);

  return (
    <div ref={ref} className={welcomeMsgWrapper}>
      <span className="confetti">🎉</span>
      <h1 className="modal-title">Welcome to Clarifai, {userFullname}!</h1>
      <p className="welcome-description">
        We&apos;re excited to have you on board as a new member of our dynamic community. Build AI faster and unlock value instantly using out
        platform!
      </p>
      <Button size="md" variant="primary" onClick={redirectToHome} style={{ marginTop: 'auto' }}>
        Lets start
      </Button>
    </div>
  );
}
