// import { darken, lighten } from 'polished';

const lightTheme = {
  boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, .05)',
  colors: {
    default: '#10182866', // #101828, 40%
    lightBlue: '#F2F6FE',
    blue: '#195AFF',
    darkBlue: '#0046D4',
    primary: '#306AFF',
    primary25: '#F7FAFE',
    primary50: '#F2F6FE',
    primary100: '#E6EDFD',
    primary200: '#CCDCFB',
    primary300: '#99B8F7',
    primary400: '#6695F3',
    primary500: '#306AFF',
    primary600: '#195AFF',
    primary700: '#0046D4',
    primary800: '#003EBC',
    primary900: '#0037A5',
    violet: '#5D00D4',
    violetLight: '#F9F2FE',
    grey: '#F3F3F8',
    darkGrey: '#333',
    bluishGrey: '#667085',
    border: 'rgb(0 0 0 / 7%)',
    borderLight: 'rgba(0, 0, 0, 0.1)',
    white: '#ffffff',
    whisperWhite: '#FAFAFA',
    black: '#282636',
    darkgrey: 'grey',
    yellow: '#F3BB1C',
    orange: '#DC6803',
    darkOrange: '#B54708',
    darkgreylight: '#808080',
    green: '#4CD08B',
    aqua: '#1BBEB7',
    red: '#CF536C',
    bg: '#ffffff',
    gray25: '#FCFCFD',
    gray50: '#F9FAFB',
    gray75: '#F5F6F7',
    gray100: '#F2F4F7',
    gray200: '#E4E7EC',
    gray300: '#D0D5DD',
    gray400: '#98A2B3',
    gray500: '#667085',
    gray600: '#475467',
    gray700: '#344054',
    gray800: '#1D2939',
    gray900: '#101828',
    error25: '#FFFBFA',
    error50: '#FEF3F2',
    error100: '#FEE4E2',
    error200: '#FECDCA',
    error300: '#FDA29B',
    error400: '#F97066',
    error500: '#F04438',
    error600: '#D92D20',
    error700: '#B42318',
    error800: '#912018',
    error900: '#7A271A',
    pink600: '#DD2590',
    success: '#039855',
    success100: '#ECFDF3',
    success500: '#12B76A',
    success700: '#027A48',
    warning: '#FEF0C7',
    warningBackground: '#FFFAEB',
    warning200: '#FEDF89',
    warning500: '#F79009',
    warning600: '#DC6803',
    warning800: '#93370D',
    cloudGrey: '#DFE3EA',
    softSteel: '#A0A5AE',
  },
  buttons: {
    primary: {
      text: '#fff',
      bg: '#006dff',
      hover: {
        bg: '#356dff',
      },
      active: {
        bg: '#356dff',
      },
      disabled: '#CCDCFB',
    },
    secondary: {
      text: '#006dff',
      bg: '#fff',
      border: 'rgb(0 0 0 / 7%)',
      hover: {
        bg: '#F3F3F8',
      },
      active: {
        bg: '#F3F3F8',
      },
    },
  },
  table: {
    header: {
      bg: '#F3F3F8',
    },
  },
  text: {
    grey: '#667085',
    blue: '#004EEB',
    red: '#F04438',
  },
  inputs: {
    label: {
      blue: '#344054',
    },
    border: {
      grey: '#D0D5DD',
      red: '#FDA29B',
      blue: '#99B8F7',
    },
    shadow: {
      blue: '#E6EDFD',
    },
  },
  icons: {
    border: {
      lightBlue: '#99B8F7',
    },
  },
};

// doing this gives free TS erros if the dark theme doesnt have all properties as light theme
const darkTheme: typeof lightTheme = {
  boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, .05)',
  colors: {
    default: '#10182866', // #101828, 40%
    lightBlue: '#F2F6FE',
    blue: '#4c7fff',
    darkBlue: '#0046D4',
    violet: '#5D00D4',
    violetLight: '#F9F2FE',
    primary: '#306AFF',
    primary25: '#F7FAFE',
    primary50: '#F2F6FE',
    primary100: '#E6EDFD',
    primary200: '#CCDCFB',
    primary300: '#99B8F7',
    primary400: '#6695F3',
    primary500: '#306AFF',
    primary600: '#195AFF',
    primary700: '#0046D4',
    primary800: '#003EBC',
    primary900: '#0037A5',
    pink600: '#DD2590',
    grey: '#2c2c2c',
    darkGrey: '#333',
    bluishGrey: '#667085',
    yellow: '#F3BB1C',
    border: 'rgb(58 58 59)',
    borderLight: 'rgba(0, 0, 0, 0.1)',
    white: 'black',
    whisperWhite: '#FAFAFA',
    black: 'white',
    bg: '#1d1d1d',
    // same
    orange: '#DC6803',
    darkOrange: '#B54708',
    darkgrey: 'grey',
    darkgreylight: '#808080',
    green: '#4CD08B',
    aqua: '#1BBEB7',
    red: '#CF536C',
    gray25: '#FCFCFD',
    gray50: '#F9FAFB',
    gray75: '#F5F6F7',
    gray100: '#F2F4F7',
    gray200: '#E4E7EC',
    gray300: '#D0D5DD',
    gray400: '#98A2B3',
    gray500: '#667085',
    gray600: '#475467',
    gray700: '#344054',
    gray800: '#1D2939',
    gray900: '#101828',
    error25: '#FFFBFA',
    error50: '#FEF3F2',
    error100: '#FEE4E2',
    error200: '#FECDCA',
    error300: '#FDA29B',
    error400: '#F97066',
    error500: '#F04438',
    error600: '#D92D20',
    error700: '#B42318',
    error800: '#912018',
    error900: '#7A271A',
    success: '#039855',
    success100: '#ECFDF3',
    success500: '#12B76A',
    success700: '#027A48',
    warning: '#FEF0C7',
    warningBackground: '#FFFAEB',
    warning200: '#FEDF89',
    warning500: '#F79009',
    warning600: '#DC6803',
    warning800: '#93370D',
    cloudGrey: '#DFE3EA',
    softSteel: '#A0A5AE',
  },
  buttons: {
    primary: {
      text: '#fff',
      bg: '#263238',
      hover: {
        bg: '#263238',
      },
      active: {
        bg: '#263238',
      },
      disabled: '#CCDCFB',
    },
    secondary: {
      text: '#006dff',
      bg: 'rgba(0,0,0,0)',
      border: 'rgb(59 56 74)',
      hover: {
        bg: '#F3F3F8',
      },
      active: {
        bg: '#F3F3F8',
      },
    },
  },
  table: {
    header: {
      bg: '#2C2C2C',
    },
  },
  text: {
    grey: '#667085',
    blue: '#004EEB',
    red: '#F04438',
  },
  inputs: {
    label: {
      blue: '#344054',
    },
    border: {
      grey: '#D0D5DD',
      red: '#FDA29B',
      blue: '#99B8F7',
    },
    shadow: {
      blue: '#E6EDFD',
    },
  },
  icons: {
    border: {
      lightBlue: '#99B8F7',
    },
  },
};

export const baseTheme = {
  light: lightTheme,
  dark: darkTheme,
};
