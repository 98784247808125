import { debounce } from 'lodash';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';

type UseLoginRouteHandlerReturn = {
  active?: { path: string; userOrOrgId: string } | null;
  previous?: { path: string; userOrOrgId: string } | null;
  /**
   * @param currentUserId Passing userId is helpful when userOrOrgId is not available URL eg. /explore
   */
  handleLoginRedirection: (currentUserId?: string) => void;
};

export const useLoginRouteHandler = (): UseLoginRouteHandlerReturn => {
  const {
    asPath,
    query: { userOrOrgId },
    pathname,
    replace,
  } = useRouter();

  const debouncedReplace = debounce(() => {
    const previousRoute = ref.current?.previous;
    const currentRoute = ref.current?.active;

    // Redirect to home page if user just logged in and current route is /explore
    const showHomePath = currentRoute?.path === '/explore';

    const path = showHomePath ? '/home' : previousRoute?.path.includes('/explore') ? currentRoute?.path : previousRoute?.path;

    if (pathname !== '/login') {
      replace(path ?? currentRoute?.path ?? '/explore');
    }
  }, 300);

  const ref = useRef<{ active: { path: string; userOrOrgId: string }; previous: { path: string; userOrOrgId: string } | null } | null>(null);

  useEffect(() => {
    ref.current = {
      active: { path: asPath, userOrOrgId: userOrOrgId as string },
      previous: ref?.current?.active || null,
    };
    return () => {
      debouncedReplace.cancel();
    };
  }, [asPath]);

  const handleLoginRedirection = () => {
    debouncedReplace?.cancel();
    debouncedReplace();
  };

  return { ...ref.current, handleLoginRedirection };
};
