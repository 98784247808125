import { cx } from '@linaria/core';
import { Button } from 'components/ui_library/Button';
import { Link, ExtendedLinkProps } from 'components/Link';
import { PopoverGroup, TextSmall } from 'components/Popover';
import { CalloutBadge, CalloutBadgeProps } from 'components/Badge/CalloutBadge';
import { cssHelpers } from 'styles/utils/core';
import { accountMenuGroup, accountMenuItem, menuText, popoverSectionTitle } from 'components/Navbar/Navbar.styles';
const DEFAULT_BADGE_COLOR = '#344054';
const DEFAULT_BADGE_BACKGROUND = '#fef0c7';
interface BadgeProps {
  badge?: React.ReactNode;
  badgeColor?: CalloutBadgeProps['color'];
  badgeBackground?: CalloutBadgeProps['background'];
}
export interface PopoverLinkProps extends BadgeProps, ExtendedLinkProps {
  href: string;
  text: string;
  className?: string;
  externalLink?: boolean;
}
export interface PopoverButtonProps extends BadgeProps {
  text: string;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
}
export interface PopoverSectionProps {
  title?: React.ReactNode;
  className?: string;
  children: React.ReactNode;
}
const PopoverBadge: React.FC<BadgeProps> = ({
  badge,
  badgeColor = DEFAULT_BADGE_COLOR,
  badgeBackground = DEFAULT_BADGE_BACKGROUND
}) => {
  if (!badge) {
    return null;
  }
  return <CalloutBadge color={badgeColor} background={badgeBackground}>
      {badge}
    </CalloutBadge>;
};
export const PopoverLink: React.FC<PopoverLinkProps> = ({
  href,
  text,
  className,
  externalLink,
  badge,
  badgeColor,
  badgeBackground,
  target,
  ...linkProps
}) => {
  return <Link className={cx(accountMenuItem, className)} href={href} target={target || externalLink ? '_blank' : undefined} {...linkProps}>
      <TextSmall className={menuText}>{text}</TextSmall>
      <PopoverBadge badge={badge} badgeColor={badgeColor} badgeBackground={badgeBackground} />
    </Link>;
};
export const PopoverButton: React.FC<PopoverButtonProps> = ({
  onClick,
  text,
  className,
  ...badgeProps
}) => <Button variant="unstyled" className={cx(accountMenuItem, cssHelpers.flex.init, cssHelpers.flex.alignItems.center, className)}>
    <TextSmall aria-label={text} onClick={onClick} className={menuText}>
      {text}
    </TextSmall>
    <PopoverBadge {...badgeProps} />
  </Button>;
export const PopoverSectionTitle: React.FC<{
  children: React.ReactNode;
}> = ({
  children
}) => <span className={popoverSectionTitle}>{children}</span>;
export const PopoverSection: React.FC<PopoverSectionProps> = ({
  children,
  title,
  className
}) => <PopoverGroup className={cx(accountMenuGroup, className)}>
    {title && <PopoverSectionTitle>{title}</PopoverSectionTitle>}
    {children}
  </PopoverGroup>;