import { IconProps } from 'components/Icons/IconProps';

export const IconVultr = ({ size, ...rest }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 23 20"
    {...rest}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        fill="#c9f4ff"
        d="M 8.019531 0.554688 C 7.8125 0.210938 7.449219 0.00390625 7.054688 0 L 1.175781 0 C 0.542969 0 0.0273438 0.519531 0.0273438 1.164062 C 0.0273438 1.382812 0.0898438 1.597656 0.203125 1.785156 L 1.714844 4.039062 L 9.28125 2.378906 Z M 8.019531 0.554688 "
      />
      <path
        fill="#51b9ff"
        d="M 9.316406 2.421875 C 9.105469 2.078125 8.738281 1.871094 8.34375 1.867188 L 2.488281 1.867188 C 2.058594 1.847656 1.652344 2.074219 1.441406 2.453125 C 1.230469 2.832031 1.246094 3.300781 1.480469 3.664062 L 3.167969 6.378906 L 10.988281 5.136719 Z M 9.316406 2.421875 "
      />
      <path
        fill="#007bfc"
        d="M 3.097656 6.269531 C 2.90625 5.957031 2.867188 5.570312 2.996094 5.226562 C 3.171875 4.769531 3.605469 4.476562 4.085938 4.484375 L 9.941406 4.484375 C 10.339844 4.484375 10.707031 4.695312 10.914062 5.039062 L 16.175781 13.5 C 16.289062 13.6875 16.351562 13.902344 16.351562 14.121094 C 16.351562 14.339844 16.289062 14.554688 16.175781 14.742188 L 13.238281 19.46875 C 13.027344 19.804688 12.660156 20.007812 12.265625 20.007812 C 11.871094 20.007812 11.503906 19.804688 11.292969 19.46875 Z M 3.097656 6.269531 "
      />
      <path
        fill="#007bfc"
        d="M 17.914062 8.972656 C 18.125 9.308594 18.492188 9.511719 18.886719 9.511719 C 19.28125 9.511719 19.644531 9.308594 19.859375 8.972656 L 20.867188 7.347656 L 22.796875 4.246094 C 22.910156 4.058594 22.972656 3.84375 22.972656 3.625 C 22.96875 3.40625 22.910156 3.191406 22.796875 3.003906 L 21.265625 0.554688 C 21.058594 0.210938 20.691406 0.00390625 20.292969 0 L 14.417969 0 C 13.785156 0 13.269531 0.519531 13.269531 1.164062 C 13.269531 1.382812 13.332031 1.601562 13.449219 1.785156 Z M 17.914062 8.972656 "
      />
    </g>
  </svg>
);
