/* eslint-disable react/default-props-match-prop-types */
import React from 'react';
import { useMenuState, MenuInitialState } from 'reakit/Menu';
import { TextSmall } from '../Popover';
import { SelectControlled } from './SelectControlled';

type SimpleSpread<L, R> = R & Pick<L, Exclude<keyof L, keyof R>>;

export type SelectOption<T extends Record<string, unknown> = Record<string, unknown>> = {
  name: string;
  value: string;
  content?: JSX.Element;
  metadata?: T;
};

export interface NestedSelectItem<T> {
  list: (SelectOption & T)[];
  segmentTitle?: React.ReactNode;
}

export interface SelectPropsExtra<ItemExtras> {
  title: string | React.ReactNode;
  options: (SelectOption & ItemExtras)[] | NestedSelectItem<ItemExtras>[];
  onClick?: () => void;
  ItemWrapper?: React.FC<{ item?: SelectPropsExtra<ItemExtras>['options']; className?: string }>;
  inline?: boolean;
  trigger?: React.ReactNode;
  selectElementClassName?: string;
  onChange?: (o: SelectOption | NestedSelectItem<ItemExtras>['list'][0]) => void;
  label?: string;
  LeftIcon?: React.ReactNode;
}

export type SelectOptions<ItemExtras> = SelectPropsExtra<ItemExtras>['options'];

export interface BaseSelectProps<ItemExtras = Record<string, unknown>>
  extends SimpleSpread<React.HTMLAttributes<HTMLDivElement>, SelectPropsExtra<ItemExtras>> {}
export interface SelectProps<ItemExtras> extends Omit<BaseSelectProps<ItemExtras>, 'defaultValue'> {
  menuState?: MenuInitialState;
  disabled?: boolean;
  modal?: boolean;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function Select<ItemExtras>({ menuState, modal, ...rest }: SelectProps<ItemExtras>): JSX.Element | null {
  const menuStateReturn = useMenuState(
    Object.assign(
      {
        animated: 150,
        placement: 'bottom-start',
      },
      menuState,
    ),
  );

  return <SelectControlled<ItemExtras> modal={modal} menuStateReturn={menuStateReturn} {...rest} />;
}

Select.defaultProps = {
  ItemWrapper: TextSmall,
  menuState: {},
  inline: false,
  menuWrapperClass: '',
  selectElementClassName: '',
};
