import { defaultIconProps, IconProps } from './IconProps';

export const IconArrowDown: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M7.00004 1.16669L7.00004 12.8334M7.00004 12.8334L12.8334 7.00002M7.00004 12.8334L1.16671 7.00002"
      stroke={color}
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconArrowDown.defaultProps = defaultIconProps;
