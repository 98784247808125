import { cx, css } from '@linaria/core';
import { IconAccount, IconTeam, IconSecurity, IconBilling, IconServer, IconUsage } from 'components/Icons';
import { MenuItem } from 'modules/Sidebar/components/Menu/Menu';
import { cssHelpers } from 'styles/utils/core';
import { useCurrentUserOrg } from 'modules/Organizations/hocs';
import { ORGANIZATION_ROLE_ID_ADMIN, ORGANIZATION_ROLE_ID_FINANCIAL_MANAGER, ORGANIZATION_ROLE_ID_MEMBER_MIN } from 'modules/Organizations/constants';
import { useOrgHasTeamRole } from 'modules/Organizations/hooks';
import { useFeatureFlagsLD } from 'modules/FeatureFlagsLD/FeatureFlagsProviderLD';
import { useIsOrgAccount } from 'modules/Auth/hooks';
import { BaseUserSettingsSidebar } from './BaseUserSettingsSidebar';
const divider = cx("omponents__Sidebar__Sidebar_tsx-divider-d1rdxpwg", 'fullWidth', cssHelpers.margin.y[1]);
export const OrgLinkList: React.FC = () => {
  const org = useCurrentUserOrg();
  const orgHasTeamRole = useOrgHasTeamRole();
  const isAdminOrOrgMember = org?.role?.id !== ORGANIZATION_ROLE_ID_MEMBER_MIN;
  const isAdmin = org?.role?.id === ORGANIZATION_ROLE_ID_ADMIN;
  const isFinanicalManager = org?.role?.id === ORGANIZATION_ROLE_ID_FINANCIAL_MANAGER;
  const {
    nodePoolDeploymentsLDFF
  } = useFeatureFlagsLD();
  return <>
      <MenuItem href="/settings/organization" icon={IconAccount}>
        Account
      </MenuItem>
      {isAdmin && <>
          <MenuItem href="/settings/organization/security" icon={IconSecurity}>
            Security
          </MenuItem>
          {nodePoolDeploymentsLDFF && <MenuItem href="/settings/compute" icon={IconServer}>
              Compute
            </MenuItem>}
        </>}
      {(isAdmin || isFinanicalManager) && <MenuItem href="/settings/billing" icon={IconBilling}>
          Billing
        </MenuItem>}

      <div className={divider} />
      {isAdminOrOrgMember && <>
          <MenuItem href="/settings/organization/members" icon={IconSecurity}>
            Members
          </MenuItem>
          {orgHasTeamRole && <MenuItem href="/settings/organization/teams" icon={IconTeam}>
              Teams
            </MenuItem>}
        </>}
    </>;
};
export const UserLinkList: React.FC = () => {
  const {
    nodePoolDeploymentsLDFF
  } = useFeatureFlagsLD();
  return <>
      <MenuItem href="/settings" icon={IconAccount}>
        Account
      </MenuItem>
      <MenuItem href="/settings/billing" icon={IconBilling}>
        Billing
      </MenuItem>
      <MenuItem href="/settings/security" icon={IconSecurity}>
        Security
      </MenuItem>
      {nodePoolDeploymentsLDFF && <MenuItem href="/settings/compute" icon={IconServer}>
          Compute
        </MenuItem>}
      <MenuItem href="/settings/usage" icon={IconUsage}>
        Usage
      </MenuItem>
    </>;
};
export const Sidebar: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
  const isOrg = useIsOrgAccount();
  return <BaseUserSettingsSidebar {...props}>{isOrg ? <OrgLinkList /> : <UserLinkList />}</BaseUserSettingsSidebar>;
};

require("./Sidebar.linaria.module.css!=!../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./Sidebar.tsx");