import { useBroadcastChannel } from 'hooks/useBroadcastChannel';
import { useEffect } from 'react';
import { EventTypes } from './constant';

const BROADCAST_CHANNEL_NAME = 'clarifai-auth';

export const useAuthBroadcastChannel = () => {
  const { message, sendMessage } = useBroadcastChannel(BROADCAST_CHANNEL_NAME);

  const sendLoginEvent = () => {
    sendMessage({ eventType: EventTypes.LoggedIn });
  };

  const sendLogoutEvent = () => {
    sendMessage({ eventType: EventTypes.LoggedOut });
  };

  useEffect(() => {
    if (message?.eventType === EventTypes.LoggedIn || message?.eventType === EventTypes.LoggedOut) {
      if (document.hidden) {
        window?.location?.reload();
      }
    }
  }, [message]);

  return { sendLoginEvent, sendLogoutEvent };
};
