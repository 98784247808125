import { Popover as ReakitPopover, PopoverOptions, PopoverStateReturn, PopoverArrow } from 'reakit/Popover';
import { cx } from '@linaria/core';
import { popoverWrapper } from 'components/Popover/Popover_styles';

export interface PopoverProps extends React.HTMLAttributes<HTMLDivElement>, Partial<PopoverOptions> {
  popover: PopoverStateReturn;
  popoverArrow?: boolean;
}

export const Popover: React.FC<PopoverProps> = ({ popover, popoverArrow, children, ...rest }) => (
  <ReakitPopover {...rest} {...popover} className={cx(popoverWrapper, rest.className)}>
    {popoverArrow && <PopoverArrow {...popover} className="popover-arrow" />}
    {children}
  </ReakitPopover>
);
