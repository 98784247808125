import { defaultIconProps, IconProps } from './IconProps';

export const IconTestTube: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M18.6175 6.72584C18.6914 6.80444 18.7455 6.8997 18.775 7.00368C18.8065 7.11441 18.8091 7.23135 18.7826 7.34338C18.7562 7.45541 18.7015 7.55881 18.6238 7.64376C18.5461 7.7287 18.448 7.79237 18.3387 7.8287M18.6175 6.72584L16.5878 8.35938M18.6175 6.72584V6.72461L18.6029 6.70996L13.9154 2.02248C13.9154 2.02247 13.9154 2.02247 13.9154 2.02246C13.8527 1.95971 13.7782 1.90993 13.6963 1.87596C13.6144 1.842 13.5265 1.82451 13.4378 1.82451C13.3491 1.82451 13.2613 1.842 13.1793 1.87596C13.0974 1.90993 13.023 1.95971 12.9603 2.02246C12.9603 2.02247 12.9603 2.02247 12.9602 2.02248L2.86418 12.1186C2.1988 12.7839 1.825 13.6864 1.825 14.6273C1.825 15.5683 2.1988 16.4708 2.86418 17.1361C3.52955 17.8015 4.43199 18.1753 5.37297 18.1753C6.31395 18.1753 7.21639 17.8015 7.88176 17.1361L16.6148 8.40308M18.6175 6.72584L16.5878 8.35938M18.3387 7.8287L18.323 7.78125L18.3388 7.82869L18.3387 7.8287ZM18.3387 7.8287L16.6148 8.40308M16.6148 8.40308L16.6232 8.39473L16.5878 8.35938M16.6148 8.40308L16.6036 8.40681L16.5878 8.35938M7.54332 9.34957L13.4378 3.45431L16.8757 6.89223L16.0369 7.17208C15.9374 7.20512 15.847 7.26088 15.7728 7.33493L15.7728 7.33496L12.6896 10.4181C12.0403 10.6375 11.0209 10.7196 9.68743 10.0267C8.89773 9.61402 8.17895 9.4181 7.54332 9.34957ZM6.92733 16.1804C6.92722 16.1805 6.9271 16.1806 6.92698 16.1807C6.5124 16.5801 5.95766 16.8009 5.38202 16.7956C4.80621 16.7903 4.25549 16.5592 3.84831 16.152C3.44114 15.7448 3.21004 15.1941 3.20473 14.6183C3.19942 14.0426 3.4202 13.4879 3.8196 13.0733C3.8197 13.0732 3.8198 13.0731 3.81991 13.073L6.06103 10.8319C6.71037 10.6125 7.72973 10.5304 9.0632 11.2233C9.85289 11.636 10.5717 11.8319 11.2073 11.9004L6.92733 16.1804Z"
      fill={color}
      stroke={color}
      strokeWidth="0.1"
    />
  </svg>
);

IconTestTube.defaultProps = defaultIconProps;
