import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchApi, fetchTE } from 'utils/request';

export const listKeysTE = <E = CF.API.ApiError>(
  { appId, userId }: { appId?: string; userId: string },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.ApiKeys.ApiKeysResponse> =>
  fetchTE<CF.API.ApiKeys.ApiKeysResponse, E>(
    {
      path: appId ? `/api/_api/v2/users/${userId}/apps/${appId}/keys` : `/api/_api/v2/users/${userId}/keys`,
      method: 'GET',
    },
    onRejected,
  );

export type TcreateKeyParams = {
  userId: string;
  scopes: string[];
  description: string;
  endpoints: string[];
} & (
  | {
      appId: string;
    }
  | {
      type: CF.API.ApiKeys.PAT_KEY_TYPE;
    }
);

type TupdateKeyParams = {
  userId: string;
  keyId: string;
  scopes: string[];
  description: string;
  endpoints: string[];
} & (
  | {
      appId?: string;
    }
  | {
      type: CF.API.ApiKeys.PAT_KEY_TYPE;
    }
);

export const createKeyTE = <E = CF.API.ApiError>(
  { userId, scopes, description, endpoints, ...rest }: TcreateKeyParams,
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.ApiKeys.ChangeScopeResponse> =>
  fetchTE<CF.API.ApiKeys.ChangeScopeResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/keys`,
      method: 'POST',
      body: {
        keys: [
          {
            description,
            scopes,
            endpoints,
            ...('appId' in rest ? { apps: [{ id: rest.appId, user_id: userId }] } : {}),
            ...('type' in rest ? { type: rest.type } : {}),
          },
        ],
      },
    },
    onRejected,
  );

export const updateKeyTE = <E = CF.API.ApiError>(
  { userId, keyId, scopes, description, endpoints, ...rest }: TupdateKeyParams,
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, CF.API.ApiKeys.ChangeScopeResponse> =>
  fetchTE<CF.API.ApiKeys.ChangeScopeResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/keys`,
      method: 'PATCH',
      body: {
        keys: [
          {
            id: keyId,
            description,
            scopes,
            endpoints,
            ...('appId' in rest ? { apps: [{ id: rest.appId, user_id: userId }] } : {}),
            ...('type' in rest ? { type: rest.type } : {}),
          },
        ],
        action: 'overwrite',
      },
    },
    onRejected,
  );

export const deleteKeyTE = <E = CF.API.ApiError>(
  { userId, id }: { userId: string; id: string },
  onRejected?: (e: CF.API.ApiError) => E,
): TaskEither<E, undefined> =>
  fetchTE<undefined, E>(
    {
      path: `/api/_api/v2/users/${userId}/keys/${id}`,
      method: 'DELETE',
    },
    onRejected,
  );

export const apiListKeys = ({ appId, userId }: { appId?: string; userId: string }): Promise<CF.API.ApiKeys.ApiKeysResponse> =>
  fetchApi({
    path: appId ? `/api/_api/v2/users/${userId}/apps/${appId}/keys` : `/api/_api/v2/users/${userId}/keys`,
    method: 'GET',
  });
