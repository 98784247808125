import { css } from '@linaria/core';
import { IconAWS, IconGCP, IconAzure, IconKubernetes, IconContainer, IconOracle, IconVultr } from 'components/Icons';

// HACK: For now, this helps ensure valid PATs are used for compute access, but future BE validation required.
export const REQUIRED_COMPUTE_SCOPES = ['ComputeClusters:Add', 'ComputeClusters:Delete', 'ComputeClusters:Get', 'Deployments:Add', 'Deployments:Delete', 'Deployments:Get', 'InstanceTypes:Get', 'Keys:Get', 'Models:Get', 'Nodepools:Add', 'Nodepools:Delete', 'Nodepools:Get', 'Runners:Add', 'Runners:Delete', 'Runners:Get', 'Workflows:Get'];
export const checkIfPatHasComputeAccess = (pat: CF.API.ApiKeys.KeyData) => {
  // Only global PATs can be used for compute access:
  if (pat?.type === 'app_specific_key') return false;

  // Full Access:
  if (pat?.scopes?.includes('All')) return true;

  // Check compute required scopes here:
  if (REQUIRED_COMPUTE_SCOPES.every(scope => pat?.scopes?.includes(scope))) return true;

  // If no compute access, return false:
  return false;
};
export const computeDocumentation = {
  setUp: 'https://docs.clarifai.com/portal-guide/compute-orchestration/set-up-compute/',
  createNodepool: 'https://docs.clarifai.com/portal-guide/compute-orchestration/set-up-compute/#how-to-create-a-nodepool',
  instanceTypes: 'https://docs.clarifai.com/portal-guide/compute-orchestration/cloud-instances/',
  marketingPage: 'https://www.clarifai.com/products/compute-orchestration'
};
const providerOptionStyles = "odules__User__components__Settings__ComputeClusters__utils_tsx-providerOptionStyles-p1hrkfph";
export const COMPUTE_PROVIDER_REGION_MAP: {
  [key: string]: string[];
} = {
  aws: ['us-east-1', 'us-west-1'],
  gcp: ['us-central1', 'us-east1', 'us-east4'],
  azure: ['eastus', 'westus'],
  local: ['na']
};
export const REGION_ICON_MAP: {
  [key: string]: string;
} = {
  us: '🇺🇸',
  ca: '🇨🇦'
  // ... add more regions and their corresponding icons
};
type ComputeProviderType = {
  icon: JSX.Element;
  name: string;
  handle: string;
};
type ComputeProviderDisplayComponent = {
  icon: JSX.Element | string;
  text: string;
};
type ComputeProviderDataType = {
  name: string;
  component: JSX.Element;
};
export type ComputeProvider = string;
export type ComputeRegion = string;
export const COMPUTE_PROVIDER_INFO_MAP: {
  [key: string]: ComputeProviderType;
} = {
  aws: {
    icon: <IconAWS size={22} />,
    name: 'Amazon Web Services',
    handle: 'AWS'
  },
  gcp: {
    icon: <IconGCP size={22} />,
    name: 'Google Cloud Platform',
    handle: 'GCP'
  },
  azure: {
    icon: <IconAzure size={22} />,
    name: 'Microsoft Azure',
    handle: 'Azure'
  },
  kubernetes: {
    icon: <IconKubernetes size={20} />,
    name: 'Kubernetes',
    handle: 'K8s'
  },
  local: {
    icon: <IconContainer size={22} color="#667085" />,
    name: 'Local Cluster',
    handle: 'Local'
  },
  oracle: {
    icon: <IconOracle size={20} />,
    name: 'Oracle Distributed Cloud',
    handle: 'Oracle'
  },
  vultr: {
    icon: <IconVultr size={20} />,
    name: 'Vultr Cloud Servers',
    handle: 'Vultr'
  }
  // 👆 Add mappings for additional providers here...
};

/**
 * Get the provider info for a given provider ID
 * @param providerId - the provider ID to get info for
 * @param useShortName - whether to use the short name for the provider
 * @returns the provider info, including the display component
 */
export const getComputeProviderInfo = (providerId: string, useShortName?: boolean) => {
  const providerDetails = COMPUTE_PROVIDER_INFO_MAP[providerId];

  // If we don't have a provider mapping, return a default fallback:
  if (!providerDetails) {
    return {
      name: providerId,
      icon: <></>,
      component: <CloudProviderInfo icon={<IconContainer size={20} color="#667085" />} text={providerId} />
    };
  }

  // We can optionally use a shorter display name for tight spaces:
  const providerText = useShortName ? providerDetails.handle : providerDetails.name;
  const providerComponent = <CloudProviderInfo icon={providerDetails.icon} text={providerText} />;

  // Otherwise, return the provider details with the generated display component:
  return {
    ...providerDetails,
    component: providerComponent
  };
};

/**
 * Get the region info for a given region ID
 * @param regionId - the region ID to get info for
 * @returns the region info, including the display component
 */
export const getComputeRegionInfo = (regionId: string) => {
  const regionIcon = getRegionIcon(regionId);
  const formattedRegionId = getFormattedRegionId(regionId);
  return {
    name: regionId,
    component: <CloudRegionInfo icon={regionIcon} text={formattedRegionId} />
  };
};

/**
 * Get the formatted region ID for a given region ID, varies by use case.
 * Ensures only one rule is applied per condition.
 * @param regionId - the region ID to format
 * @returns the formatted region ID
 */
const getFormattedRegionId = (regionId: string): string => {
  // Define an array of rules as objects
  const formattingRules = [{
    condition: (id: string) => id === 'na',
    transform: (_id: string) => 'N/A'
  }
  // Future rules can be added here...
  // {
  //   condition: (id: string) => id.startsWith('us-'),
  //   transform: (id: string) => id.replace('us-', 'USA-'),
  // },
  ];

  // Apply the first matching rule
  for (const rule of formattingRules) {
    if (rule.condition(regionId)) {
      return rule.transform(regionId);
    }
  }

  // Default return if no rules match
  return regionId;
};
const CloudProviderInfo = ({
  icon,
  text
}: ComputeProviderDisplayComponent) => {
  return <div className={providerOptionStyles}>
      <div className="provider-icon">{icon}</div>
      <div className="provider-text">{text}</div>
    </div>;
};
const CloudRegionInfo = ({
  icon,
  text
}: ComputeProviderDisplayComponent) => {
  return <div className={providerOptionStyles}>
      <div className="region-icon">{icon}</div>
      <div className="region-text">{text}</div>
    </div>;
};
const getRegionIcon = (region: string) => {
  const normalizedRegion = region?.toLowerCase();
  for (const key in REGION_ICON_MAP) {
    if (normalizedRegion?.includes(key)) {
      return REGION_ICON_MAP[key];
    }
  }
  // Fallback icon...
  return '🇺🇸';
};
export const getProviderData = (providerId: ComputeProvider, regionId: ComputeRegion): [ComputeProviderDataType, ComputeProviderDataType] => {
  const cloudProvider = getComputeProviderInfo(providerId);
  const cloudRegion = getComputeRegionInfo(regionId);
  return [cloudProvider, cloudRegion];
};
export const getFormattedDate = (dateStr: string) => {
  const date = new Date(dateStr);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  };
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
};
export const getCpuCoresFromLimit = (cpuLimit: string) => {
  if (!cpuLimit) return '-';
  const cpuLimitNumber = Number(cpuLimit.replace('m', ''));
  const cpuCores = (cpuLimitNumber / 1000).toFixed(1);
  return cpuCores;
};
export const getMemoryInGi = (memory: string | undefined, count?: number) => {
  // If there's no memory, return a dash
  if (!memory) return '-';

  // If there's no count, default to 1
  if (!count) count = 1;

  // Memory strings come in the format of "8Gi" or "8192Mi"
  const memoryNumber = Number(memory.replace('Gi', '').replace('Mi', ''));
  const totalMemory = memoryNumber * count;

  // If the memory is already in Gi, just return it
  if (memory.includes('Gi')) {
    // If there's only one gpu, pass the value right through
    if (count === 1) return memoryNumber % 1 === 0 ? memoryNumber : memoryNumber.toFixed(2);
    // We have multiple gpus, so we need to calculate the total memory
    return totalMemory % 1 === 0 ? totalMemory : totalMemory.toFixed(2);
  } else {
    // We're working with Mi, so we need to convert it to Gi
    return totalMemory / 1024 % 1 === 0 ? totalMemory / 1024 : (totalMemory / 1024).toFixed(2);
  }
};

require("./utils.linaria.module.css!=!../../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./utils.tsx");