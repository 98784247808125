import { cx } from '@linaria/core';
import { noop } from 'utils/fp';
import { IconButton } from 'components/Button';
import { PopoverAction } from 'components/Popover';
import { IconQuestion } from 'components/Icons';
import { useModalActions } from 'components/ModalManager/Context';
import { useCompositePopover } from 'hooks/useCompositePopover';
import {
  navBarDropdown,
  navLink__Active,
  navLink__Radius,
  btnNoBorder,
  notificationModalStyles,
  notificationModalWrapperStyles,
} from 'components/Navbar/Navbar.styles';
import { HelpPopover, HelpPopoverContentProps, MobileHelpItems } from './HelpPopover';

const HELP_MODAL_ID = 'help_modal_id';

export function HelpButton(props: HelpPopoverContentProps) {
  const { openModal, closeModal } = useModalActions();

  const closeMenuModal = () => closeModal({ id: HELP_MODAL_ID });

  const openMenuModal = () =>
    openModal({
      id: HELP_MODAL_ID,
      title: '',
      makeActions: noop,
      closeOnBackdropClick: true,
      contentWrapperClassName: notificationModalStyles,
      className: notificationModalWrapperStyles,
      content: <MobileHelpItems canReport={props.canReport} closePopup={closeMenuModal} />,
    });

  const { popoverState, togglePopoverOrModal } = useCompositePopover({
    breakpointWidth: 800,
    modal: {
      id: HELP_MODAL_ID,
      open: openMenuModal,
      close: closeMenuModal,
    },
  });

  return (
    <>
      <PopoverAction popover={popoverState}>
        <IconButton
          key="help-popover-desktop"
          aria-label="Help"
          variant="tertiary"
          tooltip="Help"
          size={20}
          onClick={togglePopoverOrModal}
          data-testid="navbar-help-btn"
          Icon={(iconProps) => <IconQuestion strokeWidth={1.5} {...iconProps} />}
          className={cx(btnNoBorder, navBarDropdown, navLink__Active, navLink__Radius, popoverState.visible && 'active')}
        />
      </PopoverAction>

      <HelpPopover popover={popoverState} canReport={props.canReport} />
    </>
  );
}
