import React from 'react';
import { defaultIconProps, IconProps } from './IconProps';

export const IconLightning: React.FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M8.83354 1.66666L1.41142 10.5732C1.12075 10.922 0.975413 11.0964 0.973192 11.2437C0.971261 11.3718 1.02832 11.4936 1.12792 11.5741C1.2425 11.6667 1.46952 11.6667 1.92357 11.6667H8.00021L7.16688 18.3333L14.589 9.42679C14.8797 9.07798 15.025 8.90357 15.0272 8.75628C15.0292 8.62823 14.9721 8.50641 14.8725 8.42592C14.7579 8.33333 14.5309 8.33333 14.0768 8.33333H8.00021L8.83354 1.66666Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconLightning.defaultProps = defaultIconProps;

export const IconLightningFilled: React.FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path opacity="0.2" d="M9 22.5L10.5 15L4.5 12.75L15 1.5L13.5 9L19.5 11.25L9 22.5Z" fill={color} />
    <path
      d="M20.2303 11.0786C20.2019 10.9584 20.1443 10.847 20.0625 10.7545C19.9807 10.6619 19.8774 10.591 19.7616 10.548L14.3607 8.52203L15.735 1.64734C15.7662 1.48761 15.7445 1.32209 15.6733 1.17576C15.6021 1.02943 15.4852 0.910224 15.3403 0.836138C15.1954 0.762052 15.0304 0.737102 14.8701 0.765053C14.7097 0.793004 14.5629 0.872339 14.4516 0.991087L3.95159 12.2411C3.86631 12.3309 3.80462 12.4405 3.77203 12.5601C3.73943 12.6796 3.73696 12.8053 3.76481 12.926C3.79267 13.0467 3.84999 13.1587 3.93166 13.2518C4.01334 13.345 4.11681 13.4164 4.23284 13.4598L9.63566 15.4858L8.26503 22.353C8.23391 22.5127 8.25558 22.6782 8.32679 22.8245C8.39799 22.9709 8.51485 23.0901 8.65974 23.1642C8.80464 23.2382 8.96969 23.2632 9.13001 23.2352C9.29032 23.2073 9.4372 23.128 9.54847 23.0092L20.0485 11.7592C20.1322 11.6693 20.1926 11.5603 20.2243 11.4416C20.256 11.3229 20.2581 11.1983 20.2303 11.0786ZM10.2535 20.0626L11.235 15.152C11.2702 14.9779 11.2423 14.7969 11.1565 14.6413C11.0706 14.4858 10.9324 14.3658 10.7663 14.3026L5.81253 12.4417L13.7457 3.94234L12.765 8.85296C12.7299 9.02712 12.7577 9.20809 12.8436 9.36364C12.9294 9.5192 13.0677 9.63922 13.2338 9.70234L18.1838 11.5586L10.2535 20.0626Z"
      fill="#343330"
    />
  </svg>
);

IconLightningFilled.defaultProps = defaultIconProps;
