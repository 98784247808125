import { pick } from 'rambda';

/**
 *
 * Youtube embed url formats :
 * https://www.youtube.com/embed/:id?query1=value&query2=value
 * https://www.youtube-nocookie.com/embed/:id?query1=value&query2=value
 */
export const youtubeOnlyRegex = /^https?:\/\/www\.youtube(-nocookie)?.com\/embed\/[\w&;=?-]+$/;

export function SanitizedIframe(props: React.IframeHTMLAttributes<HTMLIFrameElement>) {
  const allowedProps = ['src', 'height', 'width', 'title'];

  return props.src && youtubeOnlyRegex.test(props.src) ? (
    // eslint-disable-next-line jsx-a11y/iframe-has-title
    <iframe {...pick(allowedProps, props)} />
  ) : null;
}
