import generator from 'generate-password';
import { propOr } from 'rambda';

export interface PolicyInterface {
  minimum_length: number;
  maximum_length: number;
  upper_case_needed: boolean;
  lower_case_needed: boolean;
  numeric_needed: boolean;
  non_alphanumeric_needed: boolean;
  no_confusing_letters?: boolean;
  no_white_spaces?: boolean;
}

export interface PolicyRulesTextInterface {
  minimum_length?: string;
  maximum_length?: string;
  upper_case_needed?: string;
  lower_case_needed?: string;
  numeric_needed?: string;
  non_alphanumeric_needed?: string;
  no_confusing_letters?: string;
  no_white_spaces?: string;
}

export interface RulesInterface {
  [key: string]: { text?: string; isChecked?: boolean }; // @TODO: rename isCheck to isInvalid
}

interface RulesFunctionInterface {
  (str: string): boolean;
}

interface CheckRulesFunctionInterface {
  [key: string]: RulesFunctionInterface;
}

interface CheckPolicyNameInterface {
  (policyName: string): RulesFunctionInterface;
}

export const generatePasswordFromPolicies = (policies: PolicyInterface): string => {
  return generator.generate({
    length: Math.ceil(propOr(25, 'maximum_length')(policies) - propOr(6, 'minimum_length')(policies) / 2),
    uppercase: propOr(true, 'upper_case_needed')(policies),
    lowercase: propOr(true, 'lower_case_needed')(policies),
    numbers: propOr(true, 'numeric_needed')(policies),
    symbols: propOr(true, 'numeric_needed')(policies),
    excludeSimilarCharacters: propOr(true, 'no_confusing_letters')(policies),
    strict: true,
  });
};

// @TODO: rename getRules to getRuleText
export const getRules = (policies: PolicyInterface): PolicyRulesTextInterface => {
  return {
    ...(policies.lower_case_needed && { lower_case_needed: 'At least one lowercase letter.' }),
    ...(policies.non_alphanumeric_needed && { non_alphanumeric_needed: 'At least one symbol.' }),
    ...(policies.numeric_needed && { numeric_needed: 'At least one number.' }),
    ...(policies.upper_case_needed && { upper_case_needed: 'At least one uppercase letter.' }),
    ...(policies.no_confusing_letters && { no_confusing_letters: 'Do not use the characters "l", "1", "I", "O", "0"' }),
    ...(policies.maximum_length && { maximum_length: `Should be less than ${policies.maximum_length} characters.` }),
    ...(policies.minimum_length && { minimum_length: `Should be greater than ${policies.minimum_length} characters.` }),
    ...(policies.no_white_spaces && { no_white_spaces: 'Empty symbols are not allowed' }),
  };
};

const getCheckPasswordRules = (rulesValue: number | boolean | undefined): CheckRulesFunctionInterface => {
  return {
    lower_case_needed: (str: string): boolean => /[a-z]/.test(str),
    maximum_length: (str: string): boolean => (rulesValue ? str.length > 0 && str.length <= +rulesValue : true),
    minimum_length: (str: string): boolean => (rulesValue && typeof rulesValue === 'number' ? str.length >= rulesValue : true),
    non_alphanumeric_needed: (str: string): boolean => /([!"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~-])/.test(str),
    numeric_needed: (str: string): boolean => /\d/.test(str),
    upper_case_needed: (str: string): boolean => /[A-Z]/.test(str),
    no_confusing_letters: (str: string): boolean => !/[01IOl]/.test(str),
    no_white_spaces: (str: string): boolean => !/\s/.test(str),
    other_cases: (): boolean => true,
  };
};

export const checkPassword = (rulesValue: number | boolean | undefined): CheckPolicyNameInterface => {
  const rules: CheckRulesFunctionInterface = getCheckPasswordRules(rulesValue);

  return (policyName: keyof typeof rules): RulesFunctionInterface => {
    if (rules[policyName] !== undefined) {
      return rules[policyName];
    }
    console.warn('There is no rules for the policy name', policyName);
    return rules.other_cases;
  };
};

export const getCheckedPoliciesLength = (rulesObj: RulesInterface): number => {
  return Object.keys(rulesObj).filter((ruleKey: keyof typeof rulesObj) => {
    return !rulesObj[ruleKey]?.isChecked;
  }).length;
};

export const getPercentValue = (amountOfGlobalPolicies: number, checkedPoliciesLength: number): number => {
  if (!amountOfGlobalPolicies || !checkedPoliciesLength) {
    return 0;
  }
  return (checkedPoliciesLength * 100) / amountOfGlobalPolicies;
};

export const isValidPassword = (rules: RulesInterface): boolean => {
  return Object.keys(rules).length === getCheckedPoliciesLength(rules);
};

/*
 * Given the rules, we need to get an object from the server that correlates the rules
 * with error texts ("text")
 * that should be displayed to the user when the user does not follow the rule,
 * and a state showing whether this rule is executed or not ("isChecked")
 * */
export const getPreparedRules = (policies: PolicyInterface | undefined, value: string): RulesInterface => {
  if (!policies) {
    return {};
  }
  const policiesRuleText = getRules(policies);
  return Object.entries(policies).reduce((acc, [policyName, policyValue]) => {
    if (policyName in policiesRuleText) {
      const isChecked: boolean = value ? !checkPassword(policyValue)(policyName)(value as string) : false;
      return { [policyName]: { text: policiesRuleText[policyName as keyof PolicyRulesTextInterface], isChecked }, ...acc };
    }
    return acc;
  }, {});
};

/*
 * Having the object received in the function "getPreparedRules",
 * we change the value of isChecked depending on whether the user followed the rule or not
 * */
export const getChangedRules = (rules: RulesInterface, policies: PolicyInterface, val: string | undefined): RulesInterface => {
  const newRules = { ...(rules as RulesInterface) };
  Object.entries(policies).forEach(([policyName, policyValue]) => {
    if (policyValue && newRules[policyName]) {
      const isChecked = val ? checkPassword(policyValue)(policyName)(val as string) : true;
      newRules[policyName].isChecked = !isChecked;
    }
  });
  return newRules;
};
