import { defaultIconProps, IconProps } from './IconProps';

export const IconOpenbmb: import('react').FC<IconProps> = ({ size, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      id="Shape"
      fill="#00a2ff"
      fillRule="evenodd"
      stroke="none"
      d="M 1 17 L 37 17 L 37 1 L 1 1 Z M 21 37 L 57 37 L 57 21 L 21 21 Z M 1 57 L 38 57 L 38 41 L 1 41 Z M 21 77 L 57 77 L 57 61 L 21 61 Z M 1 97 L 37 97 L 37 81 L 1 81 Z"
    />
    <path
      id="Shape-copy"
      fill="#02d1eb"
      fillRule="evenodd"
      stroke="none"
      d="M 41 17 L 77 17 L 77 1 L 41 1 Z M 61 37 L 97 37 L 97 21 L 61 21 Z M 41 57 L 78 57 L 78 41 L 41 41 Z M 61 77 L 97 77 L 97 61 L 61 61 Z M 41 97 L 77 97 L 77 81 L 41 81 Z"
    />
  </svg>
);

IconOpenbmb.defaultProps = defaultIconProps;
