import { defaultIconProps, IconProps } from './IconProps';

export const IconServer04: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.3337 8.75016L17.9383 5.58736C17.7832 4.34684 17.7057 3.72657 17.4138 3.25916C17.1566 2.84734 16.785 2.51934 16.3445 2.3152C15.8445 2.0835 15.2194 2.0835 13.9692 2.0835H6.03145C4.78128 2.0835 4.15619 2.0835 3.65618 2.3152C3.21564 2.51934 2.84408 2.84734 2.58687 3.25916C2.29494 3.72657 2.21741 4.34684 2.06234 5.58736L1.66699 8.75016M4.58366 12.0835H15.417M4.58366 12.0835C2.97283 12.0835 1.66699 10.7777 1.66699 9.16683C1.66699 7.556 2.97283 6.25016 4.58366 6.25016H15.417C17.0278 6.25016 18.3337 7.556 18.3337 9.16683C18.3337 10.7777 17.0278 12.0835 15.417 12.0835M4.58366 12.0835C2.97283 12.0835 1.66699 13.3893 1.66699 15.0002C1.66699 16.611 2.97283 17.9168 4.58366 17.9168H15.417C17.0278 17.9168 18.3337 16.611 18.3337 15.0002C18.3337 13.3893 17.0278 12.0835 15.417 12.0835M5.00033 9.16683H5.00866M5.00033 15.0002H5.00866M10.0003 9.16683H15.0003M10.0003 15.0002H15.0003"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconServer04.defaultProps = defaultIconProps;
