import React, { ReactElement, FC, useMemo } from 'react';
import { useIsLoggedIn } from 'modules/Auth/hooks';

type Config = {
  hideFor: 'NONE' | 'GUEST' | 'USER';
  placeholder?: ReactElement | null;
};

function makeConfig(): Config {
  return {
    hideFor: 'NONE',
    placeholder: null,
  };
}

export function withAuth<T>(config = makeConfig()): (c: FC<T>) => FC<T> {
  return (Component: FC<T>): FC<T> =>
    (props: T) => {
      const baseConfig = makeConfig();
      const { hideFor, placeholder } = {
        ...baseConfig,
        ...config,
      };

      const isLoggedIn = useIsLoggedIn();

      if (getShouldRenderPlaceholder({ hideFor, isLoggedIn })) {
        return placeholder || null;
      }

      return <Component {...{ ...props, isLoggedIn }} />;
    };
}

export function withAuthOptimized<T>(config = makeConfig()): (c: FC<T>) => FC<T> {
  return (Component: FC<T>): FC<T> =>
    (props: T) => {
      const baseConfig = makeConfig();
      const { hideFor, placeholder } = {
        ...baseConfig,
        ...config,
      };

      // Extract auth state
      const isLoggedIn = useIsLoggedIn();

      // Memoize the result of getShouldRenderPlaceholder
      const shouldRenderPlaceholder = useMemo(() => getShouldRenderPlaceholder({ hideFor, isLoggedIn }), [hideFor, isLoggedIn]);

      if (shouldRenderPlaceholder) {
        return placeholder || null;
      }

      // Memoize the props object to avoid creating a new reference
      const enhancedProps = useMemo(() => ({ ...props, isLoggedIn }), [props, isLoggedIn]);

      return <Component {...enhancedProps} />;
    };
}

function getShouldRenderPlaceholder({ hideFor, isLoggedIn }: { hideFor: 'NONE' | 'GUEST' | 'USER'; isLoggedIn: boolean }): boolean {
  if (hideFor === 'GUEST' && !isLoggedIn) {
    return true;
  }

  if (hideFor === 'USER' && isLoggedIn) {
    return true;
  }

  return false;
}

export const testable = { getShouldRenderPlaceholder };
