import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { propOr } from 'rambda';
import { cx } from '@linaria/core';
import { withAuth } from 'hocs/withAuth/withAuth';
import { useAuthActions, useAuthState } from 'modules/Auth/AuthContext';
import { useCurrentUser, useIsOrgAccount } from 'modules/Auth/hooks';
import { Link } from 'components/Link';
import { Button, IconButton } from 'components/Button';
import { IconPlus, IconGlobe, IconCirclesFour, IconMessageSquareV2, IconServer, IconChart01, IconHome, IconTestTube } from 'components/Icons';
import { ButtonGroup } from 'components/deprecated/Button/ButtonGroup';
import { useDataCollection } from 'modules/AnalyticsCollection/AnalyticsCollectionProvider';
import { useCurrentStore } from 'modules/Listings/store/useCurrentStore';
import { useCreateApplicationModal } from 'modules/Apps/hooks';
import { flex } from 'styles/utils';
import { cssHelpers } from 'styles/utils/core';
import { useOrganizationInvitesList } from 'modules/Organizations/hooks';
import { AuthModalState } from 'modules/Auth/components/AuthModal';
import { useFeatureFlagsLD } from 'modules/FeatureFlagsLD/FeatureFlagsProviderLD';
import { computeDocumentation } from 'modules/User/components/Settings/ComputeClusters/utils';
import { CalloutBadge } from 'components/Badge/CalloutBadge';
import { JiraButton, JiraLinks } from 'components/Jira';
import { useIsViewableAsOrg } from 'modules/UsageDashboard/constants/accessLevel';
import { NotificationButton, MenuButton, HelpButton, NavLink, NavLinkProps, AccountSelectorButton } from './components';
import { useActiveNavLinkType, NavLinkType } from './Navbar.hooks';
import { logo, navBarDropdown, nav, navContent, navLink__Active, navLink__Radius, navAuthWrapper, hideBelowTab, btnNoBorder, createAppBtn, spacer, desktopAccountSelector } from './Navbar.styles';
interface NavTopLinkProps extends Omit<NavLinkProps, 'active'> {
  children: React.ReactNode;
  visible?: boolean;
  isActive?: boolean;
}

/**
 * Helper component to render a top-level navigation link in the navbar.
 *
 * @note Using NavLink directly inside Navbar produces SSR hydration errors
 * for "active" prop.
 */
const NavTopLink: React.FC<NavTopLinkProps> = ({
  visible = true,
  isActive,
  ...props
}) => {
  if (!visible) {
    return null;
  }
  return <NavLink active={isActive} {...props} />;
};
const SendFeedbackButton: React.FC = () => {
  return <JiraButton collectorId={JiraLinks.sendFeedback.collectorId} clickTargetSelector={JiraLinks.sendFeedback.clickTargetSelector}>
      <IconButton data-testid="send-feedback-btn" aria-label="send feedback" variant="tertiary" size={22} tooltip="Send feedback" Icon={IconMessageSquareV2} className={cx(btnNoBorder, navBarDropdown, navLink__Active, navLink__Radius)} />
    </JiraButton>;
};
export const NavbarInternal: React.FC<{
  isLoggedIn?: boolean;
}> = ({
  isLoggedIn
}) => {
  const {
    logout,
    openLoginModal
  } = useAuthActions();
  const {
    authData
  } = useAuthState();
  const router = useRouter();
  const currentUser = useCurrentUser();
  const {
    controlCenter,
    computeMerchandisingLDFF,
    nodePoolDeploymentsLDFF,
    enableHomePage,
    modelPlaygroundLDFF
  } = useFeatureFlagsLD();
  const {
    track
  } = useDataCollection();
  const {
    load
  } = useCurrentStore();
  const orgInvitesList = useOrganizationInvitesList();
  const currentPath = router.asPath;
  const userId = authData && propOr('', 'user_id')(currentUser);
  const [showProfile, setShowProfile] = useState(false);
  const activeNavLink = useActiveNavLinkType();
  const isOrg = useIsOrgAccount();
  const isViewableAsOrg = useIsViewableAsOrg();
  const {
    canCreateApplication,
    openCreateApplicationModal
  } = useCreateApplicationModal({
    onAppCreated: (createdApp, fromTemplate = false) => {
      load();
      router.push(`/${createdApp.userId}/${createdApp.appId}${!fromTemplate ? '?show_wizard_model_path_modal=true' : ''}`);
      track('User App Created', {
        context: 'regular'
      });
    }
  });

  // to ensure react doesnt show hydration mismatch on client vs server render
  useEffect(() => {
    setShowProfile(Boolean(isLoggedIn));
  }, [isLoggedIn]);
  const handleLogout = useCallback((event?: React.MouseEvent) => {
    event?.preventDefault();
    logout();
  }, [logout]);
  const dynamicComputeUrl = nodePoolDeploymentsLDFF ? '/settings/compute' : computeDocumentation.marketingPage;
  const onHeaderBtnClick = (btnText: string): void => {
    track('Navbar Button Clicked', {
      text: btnText,
      context: 'regular'
    });
  };
  const handleCreateApp = (): void => {
    onHeaderBtnClick('create_app');
    router.push({
      query: {
        ...router.query,
        run_create_app: true
      }
    }, undefined, {
      shallow: true
    });
    openCreateApplicationModal();
  };
  return <nav className={nav} data-testid="navbar">
      <div className={navContent}>
        <Link href="/explore" className={cssHelpers.height.content}>
          <div className={logo} data-testid="clarifai-logo" />
        </Link>

        <div className={cx(flex.init, flex.alignItems.center, 'fullWidth')}>
          <ul className={cx(flex.init, flex.alignItems.center, flex.gap.quarter, hideBelowTab)}>
            {showProfile && <AccountSelectorButton key="account-selector-desktop" className={desktopAccountSelector} accountSelectorProps={{
            withSectionTitle: true,
            onLogout: handleLogout,
            viewport: 'desktop'
          }} />}
            {enableHomePage && <NavTopLink key="home" href="/home" isActive={NavLinkType.home === activeNavLink} data-testid="headerNavHome" icon={IconHome} visible={showProfile}>
                Home
              </NavTopLink>}

            <NavTopLink key="profile" href={`/${userId}`} isActive={NavLinkType.profile === activeNavLink} data-testid="headerNavProfile" icon={IconCirclesFour} visible={showProfile}>
              {isOrg ? 'Projects' : 'Projects'}
            </NavTopLink>
            <NavTopLink key="playground" href="/playground" isActive={NavLinkType.playground === activeNavLink} visible={modelPlaygroundLDFF} icon={IconTestTube} isText>
              Playground
              <CalloutBadge tight color="#344054" background="#fef0c7">
                NEW
              </CalloutBadge>
            </NavTopLink>
            <NavTopLink key="compute" href={dynamicComputeUrl} isActive={NavLinkType.compute === activeNavLink} target={nodePoolDeploymentsLDFF ? '_self' : '_blank'} visible={computeMerchandisingLDFF} icon={IconServer} isText>
              Compute
              <CalloutBadge tight color="#344054" background="#fef0c7">
                NEW
              </CalloutBadge>
            </NavTopLink>
            <NavTopLink key="control-center" href="/control-center" isActive={NavLinkType.controlCenter === activeNavLink} data-testid="headerNavControlCenter" icon={IconChart01} visible={showProfile && controlCenter && isViewableAsOrg('/control-center')}>
              Control Center
            </NavTopLink>
            <NavTopLink key="community" href="/explore" isActive={NavLinkType.community === activeNavLink} data-testid="headerNavCommunity" icon={IconGlobe}>
              Community
            </NavTopLink>
          </ul>

          <div className={spacer} />

          {showProfile ? <ButtonGroup key="logged-in" className={navAuthWrapper} style={{
          minWidth: 0
        }}>
              <SendFeedbackButton />
              <HelpButton canReport={showProfile} />
              <NotificationButton key="notification-button" orgInvitesList={orgInvitesList} />
              {canCreateApplication && <NavLink href={`/${userId}/apps/new`} className={hideBelowTab}>
                  <Button key="create-an-app" LeftIcon={IconPlus} variant="tertiary" size="md" onClick={e => {
              e.preventDefault();
              handleCreateApp();
            }} className={createAppBtn}>
                    Create
                  </Button>
                </NavLink>}
              <MenuButton onLogout={handleLogout} canCreateApp={canCreateApplication} handleCreateApp={handleCreateApp} />
            </ButtonGroup> : <ButtonGroup key="logged-out" className={navAuthWrapper}>
              <HelpButton />

              <NavLink href="/login" currentPath={currentPath}>
                <Button variant="tertiary" size="md" onClick={e => {
              e.preventDefault();
              onHeaderBtnClick('Login');
              openLoginModal();
            }}>
                  Log in
                </Button>
              </NavLink>

              {process.env.NEXT_PUBLIC_SIGN_UP_DISABLED !== 'true' && <NavLink href="/signup" currentPath={currentPath}>
                  <Button size="md" onClick={e => {
              e.preventDefault();
              onHeaderBtnClick('Signup');
              openLoginModal(AuthModalState.SHOW_SIGN_UP);
            }}>
                    Sign up
                  </Button>
                </NavLink>}
            </ButtonGroup>}
        </div>
      </div>
    </nav>;
};
export const Navbar = withAuth<{
  isLoggedIn?: boolean;
}>()(NavbarInternal);