import { defaultIconProps, IconProps } from './IconProps';

export const IconCPU: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10989_20315)">
      <path
        d="M7.50033 1.6665V3.33317M12.5003 1.6665V3.33317M7.50033 16.6665V18.3332M12.5003 16.6665V18.3332M16.667 7.49984H18.3337M16.667 11.6665H18.3337M1.66699 7.49984H3.33366M1.66699 11.6665H3.33366M7.33366 16.6665H12.667C14.0671 16.6665 14.7672 16.6665 15.302 16.394C15.7724 16.1543 16.1548 15.7719 16.3945 15.3015C16.667 14.7667 16.667 14.0666 16.667 12.6665V7.33317C16.667 5.93304 16.667 5.23297 16.3945 4.69819C16.1548 4.22779 15.7724 3.84534 15.302 3.60565C14.7672 3.33317 14.0671 3.33317 12.667 3.33317H7.33366C5.93353 3.33317 5.23346 3.33317 4.69868 3.60565C4.22828 3.84534 3.84583 4.22779 3.60614 4.69819C3.33366 5.23297 3.33366 5.93304 3.33366 7.33317V12.6665C3.33366 14.0666 3.33366 14.7667 3.60614 15.3015C3.84583 15.7719 4.22828 16.1543 4.69868 16.394C5.23346 16.6665 5.93353 16.6665 7.33366 16.6665ZM8.83366 12.4998H11.167C11.6337 12.4998 11.8671 12.4998 12.0453 12.409C12.2021 12.3291 12.3296 12.2016 12.4095 12.0448C12.5003 11.8666 12.5003 11.6332 12.5003 11.1665V8.83317C12.5003 8.36646 12.5003 8.13311 12.4095 7.95485C12.3296 7.79804 12.2021 7.67056 12.0453 7.59067C11.8671 7.49984 11.6337 7.49984 11.167 7.49984H8.83366C8.36695 7.49984 8.13359 7.49984 7.95533 7.59067C7.79853 7.67056 7.67105 7.79804 7.59115 7.95485C7.50033 8.13311 7.50033 8.36646 7.50033 8.83317V11.1665C7.50033 11.6332 7.50033 11.8666 7.59115 12.0448C7.67105 12.2016 7.79853 12.3291 7.95533 12.409C8.13359 12.4998 8.36695 12.4998 8.83366 12.4998Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10989_20315">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconCPU.defaultProps = defaultIconProps;
