import { defaultIconProps, IconProps } from './IconProps';

export const IconControlCenterEmptyPinned: import('react').FC<IconProps> = ({ size, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 168 168" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <circle cx="83.9946" cy="84" r="80" fill="#FAFAFA" />
    <g filter="url(#filter0_d_95_1880)">
      <circle cx="83.9946" cy="84" r="60" fill="#F6F8FA" />
    </g>
    <g filter="url(#filter1_d_95_1880)">
      <circle cx="83.9946" cy="84" r="40" fill="#EFEFEF" />
    </g>
    <g filter="url(#filter2_ddi_95_1880)">
      <path
        d="M63.8523 63.5109C60.7678 63.9369 58.6126 66.7828 59.0386 69.8673L63.5107 102.247C63.9367 105.332 66.7825 107.487 69.8671 107.061L102.247 102.589C105.332 102.163 107.487 99.317 107.061 96.2325L102.589 63.8525C102.163 60.768 99.3168 58.6128 96.2322 59.0388L63.8523 63.5109Z"
        fill="url(#paint0_radial_95_1880)"
        stroke="#DFE3EA"
        strokeWidth="1.21841"
      />
    </g>
    <g clipPath="url(#clip0_95_1880)">
      <path
        d="M80.4012 88.9693L75.2515 95.7659M82.5982 79.0101L81.1772 80.8854C81.0613 81.0384 81.0033 81.1149 80.9341 81.1789C80.8727 81.2357 80.8047 81.285 80.7318 81.3259C80.6495 81.372 80.5589 81.4035 80.3776 81.4665L76.6149 82.7737C75.6371 83.1134 75.1481 83.2833 74.9495 83.5808C74.7764 83.84 74.7254 84.162 74.81 84.4619C74.907 84.8063 75.3196 85.1189 76.1446 85.744L84.6579 92.1946C85.483 92.8198 85.8955 93.1324 86.2533 93.1326C86.565 93.1329 86.8611 92.9967 87.0638 92.7599C87.2965 92.4882 87.3277 91.9715 87.3902 90.9383L87.6307 86.9622C87.6423 86.7706 87.6481 86.6749 87.6702 86.5832C87.6899 86.5019 87.719 86.4232 87.757 86.3486C87.7999 86.2647 87.8578 86.1882 87.9738 86.0352L89.3947 84.1599C89.4688 84.0621 89.5059 84.0132 89.548 83.9691C89.5853 83.93 89.6256 83.8938 89.6685 83.8608C89.7168 83.8236 89.7693 83.792 89.8745 83.7287L92.3527 82.2368C93.0757 81.8016 93.4372 81.584 93.575 81.2939C93.6956 81.0402 93.7111 80.7491 93.6182 80.484C93.512 80.1809 93.1757 79.9261 92.5031 79.4164L86.3237 74.7343C85.6511 74.2246 85.3148 73.9698 84.9943 73.9496C84.7139 73.9319 84.4379 74.0256 84.2263 74.2103C83.9843 74.4215 83.8726 74.8284 83.6492 75.6421L82.8835 78.4316C82.851 78.5499 82.8347 78.6091 82.812 78.6656C82.7919 78.7158 82.7679 78.7644 82.7403 78.811C82.7093 78.8634 82.6723 78.9123 82.5982 79.0101Z"
        stroke="white"
        strokeWidth="1.84372"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <ellipse cx="86" cy="120" rx="9.99998" ry="2" fill="#D6D6D6" />
    <defs>
      <filter id="filter0_d_95_1880" x="9.99463" y="10" width="148" height="148" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="7" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.920687 0 0 0 0 0.920687 0 0 0 0 0.920687 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_95_1880" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_95_1880" result="shape" />
      </filter>
      <filter id="filter1_d_95_1880" x="-0.00537109" y="0" width="168" height="168" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="22" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.85098 0 0 0 0 0.85098 0 0 0 0 0.85098 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_95_1880" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_95_1880" result="shape" />
      </filter>
      <filter
        id="filter2_ddi_95_1880"
        x="40.7746"
        y="43.2894"
        width="84.5499"
        height="84.5497"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2.51441" />
        <feGaussianBlur stdDeviation="8.80043" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.85098 0 0 0 0 0.85098 0 0 0 0 0.85098 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_95_1880" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2.43681" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.587256 0 0 0 0 0.587256 0 0 0 0 0.587256 0 0 0 1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_95_1880" result="effect2_dropShadow_95_1880" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_95_1880" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="3.61867" dy="5.10512" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect3_innerShadow_95_1880" />
      </filter>
      <radialGradient
        id="paint0_radial_95_1880"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(73.2292 70.1997) rotate(43.3623) scale(47.666 61.6746)"
      >
        <stop offset="0.221581" stopColor="#D9D9D9" />
        <stop offset="0.729167" stopColor="#B7BAC1" />
      </radialGradient>
      <clipPath id="clip0_95_1880">
        <rect width="25.5817" height="25.5817" fill="white" transform="translate(69.2832 73.699) rotate(-7.84857)" />
      </clipPath>
    </defs>
  </svg>
);

IconControlCenterEmptyPinned.defaultProps = defaultIconProps;
