import { cx } from '@linaria/core';
import { useAuthState } from 'modules/Auth/AuthContext';
import { useFeaturedFlags } from 'modules/FeaturedFlags/useFeaturedFlags';
import { useOpenCreateOrgModal } from 'modules/Organizations/hooks';
import { TextTooltip } from 'components/Tooltip';
import { IconPlus } from 'components/Icons';
import { MAX_ORGANIZATIONS } from 'modules/Organizations/constants';
import { tooltipCls } from 'modules/Models/components/ModelViewer/Header/Header.styles';
import { Button } from 'components/ui_library/Button';
import { account } from '../Account/styles';
import { createOrgButton } from './styles';

export function CreateNewOrgButton() {
  const { authData } = useAuthState();
  const [hasOrgAccess, canCreateMultipleOrgs] = useFeaturedFlags(['OrgCreateOrganization', 'MultiOrganization']);
  const openCreateOrgModal = useOpenCreateOrgModal();

  const orgsList = authData?.organizations?.map(({ organization }) => organization) || [];
  const isMaxOrgsReached = orgsList?.length >= MAX_ORGANIZATIONS;
  const allowedToCreateMultiOrgs = canCreateMultipleOrgs && orgsList?.length < MAX_ORGANIZATIONS;

  const showTooltipText =
    orgsList.length === 1 && !allowedToCreateMultiOrgs
      ? 'Multiple Organizations feature is available on the Enterprise plan'
      : isMaxOrgsReached
      ? 'You reached the maximum number of Organizations'
      : null;

  if (!hasOrgAccess) {
    return null;
  }

  return (
    <TextTooltip arrowProps={{ className: 'darkArrow' }} hidden={!showTooltipText} showArrow text={showTooltipText} className={tooltipCls}>
      <Button
        data-testid="navbar-create-org-btn"
        variant="unstyled"
        onClick={openCreateOrgModal}
        disabled={isMaxOrgsReached || (orgsList.length === 1 && !allowedToCreateMultiOrgs)}
        className={cx(account, createOrgButton, 'fullWidth')}
      >
        <IconPlus size={20} />
        Create new Organization
      </Button>
    </TextTooltip>
  );
}
