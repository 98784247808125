import { usePopoverState } from 'reakit';
import { makeUserFullName } from 'utils/users';
import { useCurrentUser, useIsOrgAccount } from 'modules/Auth/hooks';
import { Popover, PopoverAction } from 'components/Popover';
import { Account } from 'components/Navbar/components/Account/Account';
import { accountSelectorPopoverWrapper, selectedAccountStyles } from './styles';
import { AccountSelector, AccountSelectorProps } from './AccountSelector';

interface AccountSelectorButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  accountSelectorProps?: Omit<AccountSelectorProps, 'onClose'>;
}

export const AccountSelectorButton: React.FC<AccountSelectorButtonProps> = ({ className, accountSelectorProps, ...rest }) => {
  const popover = usePopoverState({
    placement: 'bottom-start',
  });
  const currentUser = useCurrentUser();
  const isOrgAccount = useIsOrgAccount();

  if (!currentUser) {
    return null;
  }

  return (
    <div className={className}>
      <PopoverAction popover={popover} data-testid="navbar-account-avatar" {...rest}>
        {/* Account Switcher */}
        <Account
          withArrow
          iconSize={20}
          className={selectedAccountStyles}
          type={isOrgAccount ? 'org' : 'user'}
          name={makeUserFullName(currentUser)}
        />
      </PopoverAction>
      <Popover data-testid="account-popover" aria-label="Account" popover={popover} className={accountSelectorPopoverWrapper}>
        {/* Account Options */}
        <AccountSelector onClose={popover.hide} {...accountSelectorProps} />
      </Popover>
    </div>
  );
};
