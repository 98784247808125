import { css, cx } from '@linaria/core';
import { flex, theming, breakpoints } from 'styles/utils';
import { HEADER_HEIGHT } from 'styles/constants';
import { ellipsis } from 'polished';
export const nav = "omponents__Navbar__Navbar_styles_ts-nav-n1x0kh9h";
export const navContent = cx("omponents__Navbar__Navbar_styles_ts-navContent-ndy5qxz", flex.init, flex.alignItems.center, flex.gap[1]);
export const hideBelowTab = "omponents__Navbar__Navbar_styles_ts-hideBelowTab-hj9bowv";
export const hideAboveTab = "omponents__Navbar__Navbar_styles_ts-hideAboveTab-hyb8b34";
const _baseImage = "omponents__Navbar__Navbar_styles_ts-_baseImage-_8knknk";
const _logo = "omponents__Navbar__Navbar_styles_ts-_logo-_1pbrb98";
export const logo = cx(_baseImage, _logo);
const _profile = "omponents__Navbar__Navbar_styles_ts-_profile-_1885f31";
export const _baseIcon = "omponents__Navbar__Navbar_styles_ts-_baseIcon-_kfpm5m";
export const profile = cx("omponents__Navbar__Navbar_styles_ts-profile-pgm5nmc", _baseImage, _profile, _baseIcon);
export const navLink__Active = "omponents__Navbar__Navbar_styles_ts-navLink__Active-n6xg5mv";
export const navLink__Radius = "omponents__Navbar__Navbar_styles_ts-navLink__Radius-nat7fs8";
export const navLink__Text = "omponents__Navbar__Navbar_styles_ts-navLink__Text-n1xjo8a6";
export const buttonIcon = "omponents__Navbar__Navbar_styles_ts-buttonIcon-b1hl153r";
export const spacer = "omponents__Navbar__Navbar_styles_ts-spacer-s12xr55x";
export const navAuthWrapper = cx("omponents__Navbar__Navbar_styles_ts-navAuthWrapper-n1g7gaxg", flex.init, flex.alignItems.center, flex.justifyContent.end);
export const navBarDropdown = "omponents__Navbar__Navbar_styles_ts-navBarDropdown-n14vkbs0";
export const createAppBtn = "omponents__Navbar__Navbar_styles_ts-createAppBtn-c3ys3go";
export const popoverSectionTitle = "omponents__Navbar__Navbar_styles_ts-popoverSectionTitle-prro349";
export const accountMenuItem = "omponents__Navbar__Navbar_styles_ts-accountMenuItem-a2pzrk1";
export const accountMenuGroupTitle = "omponents__Navbar__Navbar_styles_ts-accountMenuGroupTitle-a1ja01zv";
export const accountMenuGroup = "omponents__Navbar__Navbar_styles_ts-accountMenuGroup-a1jmfthj";
export const menuText = "omponents__Navbar__Navbar_styles_ts-menuText-m1ra2uzb";
export const accountPopoverLayout = "omponents__Navbar__Navbar_styles_ts-accountPopoverLayout-au9xq96";
export const accountPopover = cx("omponents__Navbar__Navbar_styles_ts-accountPopover-a1jdoz7l", accountPopoverLayout);
export const btnNoBorder = "omponents__Navbar__Navbar_styles_ts-btnNoBorder-b408x3v";
export const badgeStyles = "omponents__Navbar__Navbar_styles_ts-badgeStyles-b50ukd9";
export const notiticationPopoverStyles = "omponents__Navbar__Navbar_styles_ts-notiticationPopoverStyles-nhrgqdh";
export const notificationModalWrapperStyles = cx("omponents__Navbar__Navbar_styles_ts-notificationModalWrapperStyles-n15y75iy", 'no-padding');
export const notificationModalStyles = "omponents__Navbar__Navbar_styles_ts-notificationModalStyles-n911j7n";
export const emptyNotifications = "omponents__Navbar__Navbar_styles_ts-emptyNotifications-egla7bw";
export const desktopAccountSelector = "omponents__Navbar__Navbar_styles_ts-desktopAccountSelector-d17kx0br";
export const mobileAccountSelector = "omponents__Navbar__Navbar_styles_ts-mobileAccountSelector-mtygl7q";

require("./Navbar.styles.linaria.module.css!=!../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./Navbar.styles.ts");