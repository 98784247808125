import { ModelTypeIcon } from 'components/ModelTypeIcon';
import { Pill } from 'components/Pill';
import { pillLightBlueBase } from 'components/Pill/Pill_styles';
import { flex } from 'styles/utils';
import { cx } from '@linaria/core';
import { Column } from 'components/Table/type';
import { CellContext } from '@tanstack/react-table';
import { IDatasetsRecord, IModelsRecord, IModuleRecord, IWorkflowRecord, Tabs } from './constantAndTypes';

export const modelColumns: Column<IModelsRecord>[] = [
  {
    accessorKey: 'id',
    header: 'Model Id',
    size: 240,
  },
  {
    accessorKey: 'description',
    header: 'Description',
    size: 280,
  },
  {
    accessorKey: 'input',
    header: 'Input',
    size: 120,
  },
  {
    accessorKey: 'output',
    header: 'Output',
    size: 120,
  },
  {
    accessorKey: 'model_type_id',
    header: 'Model Type',
    size: 200,
    cell: (props: CellContext<IModelsRecord, IModelsRecord['model_type_id']>) => <ModelTypeIcon modelTypeId={props.getValue()} />,
  },
] as Column<IModelsRecord>[];

export const workflowColumns: Column<IWorkflowRecord>[] = [
  {
    accessorKey: 'id',
    header: 'Workflow ID',
    size: 300,
    cell: (props: CellContext<IWorkflowRecord, IWorkflowRecord['id']>) => {
      return (
        <div className={cx(flex.init, flex.direction.column, flex.alignItems.start, flex.gap.quarter)}>
          <span> {props.getValue()}</span>
          {props.getValue() === props.row.original?.default_workflow_id && <Pill className={pillLightBlueBase}>Base Workflow</Pill>}
        </div>
      );
    },
  },
  {
    accessorKey: 'description',
    header: 'Description',
    size: 300,
  },
  {
    accessorKey: 'nodes',
    header: 'Included models',
    size: 300,
    cell: (props: CellContext<IWorkflowRecord, IWorkflowRecord['nodes']>) => {
      return (
        props
          .getValue()
          ?.map((node) => node?.model?.id)
          .join(', ') ?? ''
      );
    },
  },
] as Column<IWorkflowRecord>[];

export const datasetColumns: Column<IDatasetsRecord>[] = [
  {
    accessorKey: 'id',
    header: 'Dataset Id',
    size: 200,
  },
  {
    accessorKey: 'description',
    header: 'Description',
    size: 270,
  },
  {
    accessorKey: 'version',
    header: 'Version',
    size: 150,
    cell: (props: CellContext<IDatasetsRecord, IDatasetsRecord['version']>) => {
      return props.getValue()?.id ?? '';
    },
  },
  {
    accessorKey: 'inputs_count',
    header: 'Inputs',
    size: 80,
    meta: {
      sortMetaData: {
        sortAscLabel: 'Sort (Lowest-Highest)',
        sortDescLabel: 'Sort (Highest-Lowest)',
      },
    },
  },
  {
    accessorKey: 'annotations_count',
    header: 'Annotations',
    size: 80,
    meta: {
      sortMetaData: {
        sortAscLabel: 'Sort (Lowest-Highest)',
        sortDescLabel: 'Sort (Highest-Lowest)',
      },
    },
  },
] as Column<IDatasetsRecord>[];

export const moduleColumns: Column<IModuleRecord>[] = [
  {
    accessorKey: 'id',
    header: 'Module Id',
    size: 300,
  },
  {
    accessorKey: 'description',
    header: 'Description',
    size: 300,
  },
] as Column<IModuleRecord>[];

export type ColumnType = Column<IModelsRecord>[] | Column<IWorkflowRecord>[] | Column<IDatasetsRecord>[] | Column<IModuleRecord>[];

export const TABLE_COLUMNS_MAPPING: Record<Tabs, ColumnType> = {
  Models: modelColumns,
  Workflows: workflowColumns,
  Datasets: datasetColumns,
  Modules: moduleColumns,
};

export const makeColumns: (tabId: string) => ColumnType = (tabId) => {
  return TABLE_COLUMNS_MAPPING[tabId as Tabs] ?? [];
};

export const datasetsRecordMapper = (datasets: CF.API.Datasets.Dataset[]) => {
  return datasets.map((dataset) => ({
    ...dataset,
    inputs_count: !dataset.version || !dataset.version.metrics ? 0 : dataset.version.metrics?.['/'].inputs_count,
    annotations_count:
      !dataset.version || !dataset.version.metrics
        ? 0
        : +dataset.version.metrics?.['/'].bounding_boxes_count +
          +dataset.version.metrics?.['/'].masks_count +
          +dataset.version.metrics?.['/'].polygons_count +
          +dataset.version.metrics?.['/'].positive_input_tags_count +
          +dataset.version.metrics?.['/'].points_count,
  }));
};

export const workflowMapper = (workflows: CF.API.Workflows.Workflow[], app: CF.API.Apps.App) => {
  return workflows.map((workflow) => ({
    ...workflow,
    default_workflow_id: app.default_workflow?.id,
  }));
};

export const modelsRecordMapper = (models: CF.API.Models.Model[], modelTypesMap: Record<string, CF.API.Models.ModelTypesEntity>) => {
  return models.map((model) => ({
    ...model,
    model_type_id: model.model_type_id,
    input: modelTypesMap[model.model_type_id]?.input_fields,
    output: modelTypesMap[model.model_type_id]?.output_fields,
  }));
};
