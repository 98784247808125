import { either } from 'fp-ts';
import { listPublicWorkflowsTE, listWorkflowsByAppTE } from 'api/workflows';
import { useCurrentUserId } from 'modules/Auth/hooks';
import { errorToReactLeft, pipe } from 'utils/fp';
import { queryKeys, useQueryTE } from 'utils/react-query';
import { ReactLeft } from 'utils/uiStates/uiStates';
import { useRouter } from 'next/router';
import { alphabeticalSort } from 'utils/collections';
import { NestedSelectItem } from 'components/Select/SelectControlled';
import { SelectOption } from 'components/Select';

export interface WorkflowOption extends SelectOption {
  workflow: CF.API.Workflows.Workflow;
}

export function usePublicWorkflows(): WorkflowOption[] {
  const userId = useCurrentUserId();
  const { data } = useQueryTE([queryKeys.Workflows, { userOrOrgId: userId, public: true }], listPublicWorkflowsTE({ userId }, errorToReactLeft), {
    enabled: Boolean(userId),
  });

  return pipe(
    data,
    either.map(({ workflows }) =>
      workflows
        .map((workflow) => ({ workflow, value: workflow.id, name: workflow.id }))
        // Temporary workaround to sort on the client side since existing list public_workflows API is deprecated
        .sort((a, b) => a.name.localeCompare(b.name)),
    ),
    either.getOrElse<ReactLeft, WorkflowOption[]>(() => []),
  );
}

export function useAppWorkflows(): WorkflowOption[] {
  const userId = useCurrentUserId();
  const appId = useRouter().query.appId as string;

  const { data } = useQueryTE(
    [queryKeys.Workflows, { userOrOrgId: userId, appId }],
    listWorkflowsByAppTE({ userOrOrgId: userId, appId, params: 'per_page=100' }, errorToReactLeft),
    {
      enabled: Boolean(userId),
    },
  );

  return pipe(
    data,
    either.map(({ workflows }) => workflows.map((workflow) => ({ workflow, value: workflow.id, name: workflow.id }))),
    either.getOrElse<ReactLeft, WorkflowOption[]>(() => []),
  );
}

export function useAppAndPublicWorkflowOptions(): [NestedSelectItem<WorkflowOption>[], WorkflowOption[]] {
  const publicWorkflows = usePublicWorkflows();
  const appWorkflows = useAppWorkflows();

  const pubOptions = alphabeticalSort(publicWorkflows);
  const appOptions = alphabeticalSort(appWorkflows);

  const options = [
    {
      segmentTitle: 'Public workflows',
      list: pubOptions,
    },
    {
      segmentTitle: 'App workflows',
      list: appOptions,
    },
  ];

  const allWorkflows = publicWorkflows.concat(appWorkflows);

  return [options, allWorkflows];
}
