import { defaultIconProps, IconProps } from './IconProps';

export const IconNew: React.FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10989_32994)">
      <path
        d="M5.41699 10.8334L6.07071 12.1408C6.29195 12.5833 6.40257 12.8045 6.55035 12.9962C6.68149 13.1663 6.834 13.3189 7.00412 13.45C7.19584 13.5978 7.41708 13.7084 7.85956 13.9296L9.16699 14.5834L7.85956 15.2371C7.41708 15.4583 7.19584 15.5689 7.00412 15.7167C6.834 15.8478 6.68149 16.0004 6.55035 16.1705C6.40257 16.3622 6.29195 16.5834 6.07071 17.0259L5.41699 18.3334L4.76328 17.0259C4.54204 16.5834 4.43142 16.3622 4.28363 16.1705C4.1525 16.0004 3.99999 15.8478 3.82986 15.7167C3.63815 15.5689 3.4169 15.4583 2.97442 15.2371L1.66699 14.5834L2.97442 13.9296C3.4169 13.7084 3.63815 13.5978 3.82986 13.45C3.99999 13.3189 4.1525 13.1663 4.28363 12.9962C4.43142 12.8045 4.54204 12.5833 4.76328 12.1408L5.41699 10.8334Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5003 1.66669L13.4825 4.22037C13.7175 4.83139 13.835 5.13691 14.0178 5.39389C14.1797 5.62165 14.3787 5.82064 14.6065 5.98259C14.8634 6.16532 15.169 6.28283 15.78 6.51784L18.3337 7.50002L15.78 8.48221C15.169 8.71722 14.8634 8.83472 14.6065 9.01745C14.3787 9.1794 14.1797 9.37839 14.0178 9.60615C13.835 9.86314 13.7175 10.1686 13.4825 10.7797L12.5003 13.3334L11.5181 10.7797C11.2831 10.1686 11.1656 9.86314 10.9829 9.60615C10.8209 9.37839 10.622 9.1794 10.3942 9.01745C10.1372 8.83472 9.8317 8.71722 9.22067 8.48221L6.66699 7.50002L9.22067 6.51784C9.8317 6.28283 10.1372 6.16532 10.3942 5.98259C10.622 5.82064 10.8209 5.62165 10.9829 5.39389C11.1656 5.13691 11.2831 4.83139 11.5181 4.22037L12.5003 1.66669Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10989_32994">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconNew.defaultProps = defaultIconProps;
