export const queryKeys = {
  Annotations: 'Annotations',
  AppCollaborators: 'AppCollaborators',
  AppLevelScopes: 'AppLevelScopes',
  AppMembers: 'AppMembers',
  AppResources: 'AppResources',
  Apps: 'apps',
  AuditLogSearch: 'AuditLogSearch',
  CollaboratorsAndAppOwner: 'CollaboratorsAndAppOwner',
  CloudProviders: 'CloudProviders',
  CloudProviderRegions: 'CloudProviderRegions',
  ComputeCluster: 'ComputeCluster',
  ComputeClusters: 'ComputeClusters',
  Concepts: 'Concepts',
  ControlCenterConfig: 'ControlCenterConfig',
  DataSet: 'dataSet',
  DataSets: 'dataSets',
  DataSetVersion: 'dataSetVersion',
  DataSetVersions: 'dataSetVersions',
  DatasetVersionConcepts: 'DatasetVersionConcepts',
  Deployment: 'Deployment',
  Deployments: 'Deployments',
  Disable2FA: 'Disable2FA',
  GetDatasets: 'GetDatasets',
  GetDuplicateApp: 'GetDuplicateApp',
  GetEvaluation: 'GetEvaluation',
  GetInvoices: 'GetInvoices',
  GetModel: 'GetModel',
  IdentityProvider: 'IdentityProvider',
  IdentityProviders: 'IdentityProviders',
  InitiateEnable2FA: 'InitiateEnable2FA',
  InputStatus: 'InputStatus',
  InstanceTypes: 'InstanceTypes',
  ListApps: 'ListApps',
  ListEvaluations: 'ListEvaluations',
  ListEvaluationsForModel: 'ListEvaluationsForModel',
  ListInputAddJobs: 'ListInputAddJobs',
  LoggedInUser: 'me',
  ListPATs: 'ListPATs',
  LoginInfo: 'LoginInfo',
  LogEntries: 'LogEntries',
  marketingBanners: 'AuthBanners',
  Models: 'Models',
  ModelTypes: 'ModelTypes',
  ModelVersions: 'ModelVersions',
  ModelPredictions: 'ModelPredictions',
  Modules: 'Modules',
  NodePool: 'NodePool',
  NodePools: 'NodePools',
  OrganizationAuthMethods: 'OrganizationAuthMethods',
  OrganizationMembers: 'OrganizationMembers',
  Organizations: 'organizations',
  OrgRoles: 'OrgRoles',
  Plans: 'plans',
  remainingTime: 'remainingTime',
  resourcePricing: 'resourcePricing',
  resourceViewer: 'resourceViewer',
  Roles: 'Roles',
  Scopes: 'Scopes',
  SSOMethods: 'SSOMethods',
  TaskInputCounts: 'TaskInputCounts',
  Tasks: 'Tasks',
  TeamApps: 'TeamApps',
  TeamMembers: 'TeamMembers',
  Teams: 'Teams',
  TeamsByOrgAndApp: 'TeamsByOrgAndApp',
  User2faMethods: 'User2faMethods',
  UserBilling: 'userBilling',
  UserCreditCards: 'userCreditCards',
  UserOrganizationInvitations: 'UserOrganizationInvitations',
  Users: 'users',
  Workflows: 'Workflows',
  GenerateImage: 'GenerateImage',
} as const;
