import { cx } from '@linaria/core';
import { Button } from 'components/ui_library/Button';
import { TextSmall } from 'components/Popover';
import { IconBuilding, IconUserCircle, IconChevronDown } from 'components/Icons';
import { Tag } from 'components/Tag';
import { noop } from 'utils/fp';
import { account, selectedAccountRow, userTag, orgTag } from './styles';

export interface AccountProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick'> {
  type: 'user' | 'org';
  name: string;
  id?: string;
  selected?: boolean;
  onSelect?: () => void;
  withTag?: boolean;
  withArrow?: boolean;
  iconSize?: number;
}

type AccountIconProps = Pick<AccountProps, 'type' | 'name' | 'iconSize'>;

export const AccountTag: React.FC<Pick<AccountIconProps, 'type'>> = ({ type }) => (
  <Tag data-testid="account-tag" id={type} onChange={noop} className={cx(type === 'user' ? userTag : orgTag, 'tag')}>
    {type}
  </Tag>
);

export const AccountIcon: React.FC<AccountIconProps> = ({ type, iconSize = 20 }) => {
  if (type === 'org') {
    return <IconBuilding data-testid="org-icon" size={iconSize} />;
  }
  return <IconUserCircle data-testid="user-icon" size={iconSize} />;
};

export const Account: React.FC<AccountProps> = ({ type, name, selected, id, onSelect, withTag, withArrow, className, iconSize, ...rest }) => (
  <Button
    data-testid="account-btn"
    variant="unstyled"
    size="md"
    className={cx(account, selected && selectedAccountRow, 'account', className, withArrow && 'with-arrow')}
    onClick={onSelect}
    {...rest}
  >
    <AccountIcon type={type} name={name} iconSize={iconSize} />
    <TextSmall className="name">
      {name}
      {id && <TextSmall className="id">{id}</TextSmall>}
    </TextSmall>
    {withArrow && <IconChevronDown />}
    {withTag && <AccountTag type={type} />}
  </Button>
);
