import { cx } from '@linaria/core';
import { Link } from 'components/Link';
import { flex } from 'styles/utils';
import { IconProps } from 'components/Icons/IconProps';
import { navLink__Text, navLink__Active, navLink__Radius } from 'components/Navbar/Navbar.styles';

export interface NavLinkProps extends Omit<React.HTMLAttributes<HTMLAnchorElement>, 'href'> {
  href: string;
  active?: boolean;
  currentPath?: string;
  query?: Record<string, string>;
  icon?: React.FC<IconProps>;
  isText?: boolean;
  target?: '_blank' | '_self' | '_parent' | '_top';
}

export const NavLink: React.FC<NavLinkProps> = ({ href, children, active, query, icon: Icon, currentPath, isText, ...props }) => {
  const isTextItem = isText || typeof children === 'string';

  const wrappedChildren = Icon ? (
    <div className={cx(flex.init, flex.gap.half, flex.alignItems.center)}>
      <Icon size={16} />
      {children}
    </div>
  ) : (
    children
  );

  return (
    <Link
      href={{ pathname: href, query: query || (currentPath ? { from: currentPath } : undefined) }}
      className={cx(navLink__Active, active && 'active', isTextItem && cx(navLink__Radius, navLink__Text))}
      {...props}
    >
      {wrappedChildren}
    </Link>
  );
};
