import { defaultIconProps, IconProps } from './IconProps';

export const IconMicrosoft: import('react').FC<IconProps> = ({ size, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <rect x="1.33325" y="1.3335" width="6.33333" height="6.33333" fill="#F25022" />
    <rect x="8.33325" y="1.3335" width="6.33333" height="6.33333" fill="#80BA01" />
    <rect x="8.33325" y="8.3335" width="6.33333" height="6.33333" fill="#FFB902" />
    <rect x="1.33325" y="8.3335" width="6.33333" height="6.33333" fill="#02A4EF" />
  </svg>
);

IconMicrosoft.defaultProps = defaultIconProps;
