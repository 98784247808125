import { ColumnFiltersState, Column } from '@tanstack/react-table';
import { CSSProperties } from 'react';

export const MIN_MOBILE_ROW_HEIGHT_PX = 36;
export const MIN_ROW_HEIGHT_PX = 47;
export const MAX_ROW_HEIGHT_PX = 65;
export const MIN_ROW_HEIGHT_PX_MOBILE = 40;

export const getFiltersWithHeaderName = (
  filters: ColumnFiltersState,
  columnsHeaderMapping: Record<string, string>,
): { id: string; value: string; headerName: string }[] => {
  return filters.map((filter) => {
    return { ...filter, value: filter.value as string, headerName: columnsHeaderMapping[filter.id] ?? filter.id };
  });
};

export const getCellStyles = <D extends unknown>(column: Column<D, unknown>, isFooter: boolean = false): CSSProperties => {
  const footerStyles = isFooter ? { borderColor: `#98A2B3` } : {};
  const isStickyLeft =
    column.columnDef.meta?.sticky === 'left' ? { left: 0, borderRight: `2px solid #dfe3ea`, boxShadow: '2px 0 4px -2px rgba(0, 0, 0, 0.15)' } : {};
  const isStickyRight =
    column.columnDef.meta?.sticky === 'right' ? { right: 0, borderLeft: `2px solid #dfe3ea`, boxShadow: '-2px 0 4px -2px rgba(0, 0, 0, 0.15)' } : {};
  return { width: column.getSize(), ...isStickyLeft, ...isStickyRight, ...footerStyles };
};
