import { errorMsg } from 'components/Formik/Forms.styles';
import { InputField } from 'components/Formik/InputField';
import { SelectField } from 'components/Formik/SelectField';
import { Col, Row } from 'components/Grid';
import { useFormikContext } from 'formik';
import { countryCollection, jobRoleCollection } from 'modules/User/components/Settings/ContactInformation/constants';
import { alphabeticalSort } from 'utils/collections';
import { Checkbox } from 'components/Checkbox';
import { cx } from '@linaria/core';
import { IconGlobeLocate, IconBriefcase, IconBuilding } from 'components/Icons';
import React from 'react';
import { FormValues } from '../utils';
import { checkboxLabel, textLinkExternal } from '../../styles';
import { overrideSelectStyles } from '../OnboardingDialog.styles';

interface ProfileInfoProps extends React.HTMLAttributes<HTMLDivElement> {
  handleUserIdChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  userIdError?: string;
  isGDPRConsentPending?: boolean;
  isConsentGranted?: boolean;
  handleLegalCheckboxChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ProfileInfo: React.FC<ProfileInfoProps> = ({
  userIdError,
  handleUserIdChange,
  isGDPRConsentPending,
  isConsentGranted,
  handleLegalCheckboxChange,
  ...wrapperProps
}) => {
  const countries = alphabeticalSort(countryCollection);
  const jobRoles = jobRoleCollection;
  const { values, errors, initialValues } = useFormikContext<FormValues>();
  const isNameEmpty = !(initialValues.firstName?.length && initialValues.lastName?.length);
  const isNameError = errors.firstName || errors.lastName;

  return (
    <div {...wrapperProps}>
      <Row spacing="1.5rem">
        <Col xs={12}>
          <InputField onChange={handleUserIdChange} label="User ID" name="userId" id="userId" placeholder="Enter a User ID..." required />
          <p className="input-hint">It will help people find you in the Community.</p>
          {values.userId && userIdError && <p className={errorMsg}>{userIdError}</p>}
        </Col>
        {(isNameEmpty || isNameError) && (
          <>
            <Col xs={12} md={6}>
              <InputField label="First name" name="firstName" id="firstName" placeholder="Write your first name..." required />
            </Col>
            <Col xs={12} md={6}>
              <InputField label="Last name" name="lastName" id="lastName" placeholder="Write your last name..." required />
            </Col>
          </>
        )}
        <Col xs={12} md={6}>
          <InputField
            LeftIcon={<IconBuilding size={20} color="#667085" />}
            label="Company name"
            name="company"
            id="company"
            placeholder="Write your company name..."
            required
          />
        </Col>
        <Col xs={12} md={6}>
          <SelectField
            modal
            selectElementClassName={values.onboardingJobRole ? undefined : overrideSelectStyles}
            LeftIcon={<IconBriefcase size={20} color="#667085" />}
            label="Job role"
            name="onboardingJobRole"
            id="onboardingJobRole"
            placeholder="Select job role"
            options={jobRoles}
            required
          />
        </Col>
        <Col xs={12} md={6}>
          <InputField
            LeftIcon={<IconBriefcase size={20} color="#667085" />}
            label="Job title"
            name="onboardingJobTitle"
            id="onboardingJobTitle"
            placeholder="Write your job title..."
          />
        </Col>
        <Col xs={12} md={6}>
          <SelectField
            modal
            selectElementClassName={values.country ? undefined : overrideSelectStyles}
            LeftIcon={<IconGlobeLocate size={20} color="#667085" />}
            label="Country"
            name="country"
            id="country"
            placeholder="Select country"
            options={countries}
          />
        </Col>
        {isGDPRConsentPending && (
          <Col xs={12}>
            <Checkbox
              onChange={handleLegalCheckboxChange}
              checked={isConsentGranted}
              data-testid="legal-consent"
              className={cx(checkboxLabel, 'checkbox-label')}
            >
              I agree to Clarifai&apos;s{' '}
              <a className={textLinkExternal} rel="noreferrer" target="_blank" href="https://www.clarifai.com/terms">
                Terms of Service
              </a>{' '}
              and have read and acknowledge the{' '}
              <a className={textLinkExternal} rel="noreferrer" target="_blank" href="https://www.clarifai.com/privacy-policy">
                Privacy Policy
              </a>
              .
            </Checkbox>
          </Col>
        )}
      </Row>
    </div>
  );
};
