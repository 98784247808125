/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback, ChangeEvent, useMemo } from 'react';
import { cx } from '@linaria/core';
import { useRouter } from 'next/router';
import { Button } from 'components/deprecated/Button';
import { IconCloseRound } from 'components/Icons';
import { SelectOption } from 'components/Select/SelectControlled';
import { flex } from 'styles/utils';
import { useIdValidation$ } from 'hooks/useIdValidation$';
import { useCurrentUserId, useIsOrgAccount } from 'modules/Auth/hooks';
import { Form, Formik } from 'formik';
import { InputField } from 'components/Formik/InputField';
import { SelectField } from 'components/Formik/SelectField';
import { CheckboxField } from 'components/Formik/CustomCheckboxField';
import { cssHelpers } from 'styles/utils/core';
import { useMutationTE } from 'utils/react-query';
import cogoToast from 'cogo-toast';
import { object, string } from 'yup';
import { appIdTooltipText } from 'modules/Apps/constants';
import { either, pipe } from 'utils/fp';
import { reactLeftToJSX } from 'utils/uiStates/uiStates';
import { duplicateApplicationWrapper, dropdownWrapper, heading, subheading, closeIcon } from './styles';
import { useDuplicateApplicationTE, useAppOptions, useUserAndOrgsOptions } from './DuplicateApplication.hooks';
type FormValues = {
  selectedUser: SelectOption;
  selectedDestination?: SelectOption;
  existing_app_id?: string;
  new_app_id?: string;
  new_app_name?: string;
};
type RequestValues = {
  selectedUser: SelectOption;
  selectedDestination?: SelectOption;
  existing_app_id?: string;
  new_app_id?: string;
  new_app_name?: string;
  filters: {
    [key: string]: boolean;
  };
};
type DuplicationFilter = {
  copy_concepts: boolean;
  copy_inputs: boolean;
  copy_annotations: boolean;
  copy_models: boolean;
  copy_workflows: boolean;
  [key: string]: boolean;
};
const duplicateAppValidationSchema = object().shape({
  // eslint-disable-next-line react/forbid-prop-types
  selectedUser: object({
    value: string().required('Required')
  }),
  // eslint-disable-next-line react/forbid-prop-types
  selectedDestination: object({
    value: string().required('Required')
  })
});
export const DuplicateApplication = ({
  closeAction,
  refetchList,
  entityId
}: {
  closeAction: () => void;
  refetchList: () => void;
  entityId: string;
}): JSX.Element => {
  const router = useRouter();
  const query = (useRouter().query as {
    userOrOrgId: string;
    appId: string;
  });
  const isOrgAccount = useIsOrgAccount();
  const [defaultSelectedUserOption, userAndOrgsList] = useUserAndOrgsOptions();
  const userId = useCurrentUserId();
  const [filters, setFilters] = useState<DuplicationFilter>({
    copy_concepts: true,
    copy_inputs: false,
    copy_annotations: false,
    copy_models: false,
    copy_workflows: false
  });
  const defaultValues = {
    selectedUser: defaultSelectedUserOption,
    selectedDestination: undefined,
    new_app_id: undefined,
    copy_concepts: true,
    copy_inputs_annotations: false,
    copy_models_workflows: false
  };

  // Don't show logged-in user and org accounts, when current user is an org account
  const showUserAndOrgsList = useMemo(() => isOrgAccount ? [defaultSelectedUserOption] : userAndOrgsList, [isOrgAccount, defaultSelectedUserOption, userAndOrgsList]);
  const makeDuplicateAppTE = useDuplicateApplicationTE({
    userId: query.userOrOrgId,
    appId: entityId
  });
  const {
    mutate,
    isLoading
  } = useMutationTE((values: RequestValues) => makeDuplicateAppTE(values)(), {
    onSuccess: ({
      app_duplications: [duplicatedApp]
    }) => {
      cogoToast.success(`Copying ${duplicatedApp.new_app_name} has begun...`);
      refetchList();
      closeAction();
      router.push(`/${userId}/apps`);
    },
    onError: ({
      props
    }) => {
      cogoToast.error(props?.reason || 'Could not Duplicate Application', {
        heading: 'Error'
      });
    }
  });
  const [onIdChange, {
    errorString
  }] = useIdValidation$({
    userId,
    entityName: 'app'
  });
  const handleCheckboxChange = useCallback((event: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void) => {
    const checkboxState = event.target.checked;
    const filterChangeMap: {
      [key: string]: DuplicationFilter;
    } = {
      copy_concepts: {
        copy_concepts: checkboxState,
        copy_inputs: false,
        copy_annotations: false,
        copy_models: false,
        copy_workflows: false
      },
      copy_inputs_annotations: {
        ...filters,
        copy_concepts: checkboxState,
        copy_inputs: checkboxState,
        copy_annotations: checkboxState
      },
      copy_models_workflows: {
        ...filters,
        copy_concepts: checkboxState,
        copy_models: checkboxState,
        copy_workflows: checkboxState
      }
    };

    // cc = filters.copy_concepts
    // cia = filters.copy_concepts && filters.copy_inputs && filters.copy_annotations
    // cmw = filters.copy_concepts && filters.copy_models && filters.copy_models

    setFieldValue('copy_concepts', filterChangeMap[event.target.name].copy_concepts);
    setFieldValue('copy_inputs_annotations', filterChangeMap[event.target.name].copy_concepts && filterChangeMap[event.target.name].copy_inputs && filterChangeMap[event.target.name].copy_annotations);
    setFieldValue('copy_models_workflows', filterChangeMap[event.target.name].copy_concepts && filterChangeMap[event.target.name].copy_models && filterChangeMap[event.target.name].copy_models);
    setFilters(filterChangeMap[event.target.name]);
  }, [filters]);
  const sanitizeFilters = useCallback(() => {
    const data = {
      ...filters
    };
    Object.keys(filters).forEach(key => !data[key] && delete data[key]);
    return data;
  }, [filters]);
  const handleAppIdChange = (event: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void): void => {
    const {
      value
    } = event.target;
    onIdChange(value);
    setFieldValue('new_app_id', value);
  };
  const handleSubmit = (values: FormValues): void => {
    mutate({
      ...values,
      filters: sanitizeFilters()
    });
  };
  return <div className={duplicateApplicationWrapper}>
      <h1 className={heading}>Copy / Duplicate App</h1>
      <p className={subheading}>
        Duplicate a Clarifai Application in your own workspace in which you can manage data and models. You can also duplicate specific resources from
        within an application.
      </p>
      <IconCloseRound size={24} className={closeIcon} onClick={closeAction} />
      <Formik<FormValues> initialValues={defaultValues} validationSchema={duplicateAppValidationSchema} onSubmit={handleSubmit}>
        {({
        isValid,
        dirty,
        values,
        setFieldValue
      }) => {
        const appOptionsE = useAppOptions(values.selectedUser.value, entityId);
        return <Form>
              <SelectField formGroupClassName={cssHelpers.margin.yb[1]} className={dropdownWrapper} name="selectedUser" label="Copy to" placeholder="Select a User or Organization" options={showUserAndOrgsList} onChange={option => {
            setFieldValue('selectedUser', option);
            setFieldValue('selectedDestination', null);
          }} modal />

              {pipe(appOptionsE, either.fold(reactLeftToJSX(), (appOptions = []) => <SelectField formGroupClassName={cssHelpers.margin.yb[1]} className={dropdownWrapper} name="selectedDestination" placeholder="Select an App" options={[{
            value: 'new_app',
            name: 'as a New App'
          }, ...appOptions]} modal />))}

              {values.selectedDestination?.value === 'new_app' && <InputField formGroupClassName={cssHelpers.margin.yb[1]} name="new_app_id" label="New App name" placeholder="Enter Unique Identifier" customError={errorString} tooltip={{
            title: 'Copy App Name',
            text: appIdTooltipText
          }} onChange={event => handleAppIdChange(event, setFieldValue)} />}

              <p>Select content to copy</p>
              <div className={cx(flex.init, flex.direction.column, 'fullWidth')}>
                <CheckboxField data-testid="copy_concepts" name="copy_concepts" onChange={event => handleCheckboxChange((event as ChangeEvent<HTMLInputElement>), setFieldValue)}>
                  Concepts
                </CheckboxField>
                <CheckboxField data-testid="copy_inputs_annotations" name="copy_inputs_annotations" onChange={event => handleCheckboxChange((event as ChangeEvent<HTMLInputElement>), setFieldValue)}>
                  Inputs and Annotations
                </CheckboxField>
                <CheckboxField data-testid="copy_models_workflows" name="copy_models_workflows" onChange={event => handleCheckboxChange((event as ChangeEvent<HTMLInputElement>), setFieldValue)}>
                  Models and Workflows
                </CheckboxField>
              </div>

              <div className={cx(flex.init, flex.alignItems.center, flex.justifyContent.end)}>
                <Button className={cssHelpers.margin.x[1]} type="button" kind="outline" id="duplicate-application-cancel" data-testid="Cancel" onClick={closeAction}>
                  Cancel
                </Button>
                <Button kind="primary" className="bold" id="duplicate-application-submit" type="submit" data-testid="Confirm" disabled={isLoading || !isValid || !dirty}>
                  Confirm
                </Button>
              </div>
            </Form>;
      }}
      </Formik>
    </div>;
};