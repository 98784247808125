import { PopoverAction } from 'components/Popover';
import { IconButton, IconButtonProps } from 'components/Button';
import { cx } from '@linaria/core';
import { noop } from 'utils/fp';
import { useModalActions } from 'components/ModalManager/Context';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import {
  btnNoBorder,
  navBarDropdown,
  navLink__Active,
  navLink__Radius,
  notificationModalStyles,
  notificationModalWrapperStyles,
} from 'components/Navbar/Navbar.styles';
import { useCompositePopover } from 'hooks/useCompositePopover';
import { BadgeIcon, DesktopNotificationsPopover, MobileModalContent } from './NotificationsPopover';

const NOTIFICATIONS_MODAL_ID = 'notifications_modal_id';

interface NotificationButtonProps extends Omit<IconButtonProps, 'Icon' | 'aria-label' | 'onClick'> {
  orgInvitesList?: CF.API.Organizations.OrgMemberInvite[];
}

export function NotificationButton({ orgInvitesList, ...props }: NotificationButtonProps) {
  const router = useRouter();
  const { openModal, closeModal } = useModalActions();

  const closeNotificationsModal = () => closeModal({ id: NOTIFICATIONS_MODAL_ID });

  const openNotificationsModal = useCallback(
    () =>
      openModal({
        id: NOTIFICATIONS_MODAL_ID,
        title: '',
        makeActions: noop,
        contentWrapperClassName: notificationModalStyles,
        className: notificationModalWrapperStyles,
        closeOnBackdropClick: true,
        content: <MobileModalContent orgInvitesList={orgInvitesList} />,
      }),
    [openModal, orgInvitesList],
  );

  const { popoverState, togglePopoverOrModal } = useCompositePopover({
    breakpointWidth: 800,
    modal: {
      id: NOTIFICATIONS_MODAL_ID,
      open: openNotificationsModal,
      close: closeNotificationsModal,
    },
  });

  /**
   * Don't show notifications for joining org page, otherwise there would be dual
   * way to join an org. Currently only "org invite" notifications are shown,
   * hence we need to hide notifications entirely. In future when we will support
   * other types of notifications, then we can filter out the org invite ones.
   */
  if (router.pathname.startsWith('/join_org')) {
    return null;
  }

  return (
    <>
      <PopoverAction popover={popoverState}>
        <IconButton
          key="notifications-popover"
          aria-label="notifications"
          variant="tertiary"
          size={20}
          data-testid="show-notifications-btn"
          tooltip="Notifications"
          Icon={(iconProps) => <BadgeIcon badgeProps={{ badgeContent: orgInvitesList?.length }} {...iconProps} />}
          className={cx(btnNoBorder, navBarDropdown, navLink__Active, navLink__Radius, popoverState.visible && 'active')}
          onClick={togglePopoverOrModal}
          {...props}
        />
      </PopoverAction>
      <DesktopNotificationsPopover popover={popoverState} orgInvitesList={orgInvitesList} />
    </>
  );
}
