import { HostedURL, Input } from 'api/grpc/resources';
import getConfig from 'next/config';
import * as UTIF from 'utif';
import { clarifaiFetchWithAuth } from './request/clarifaiFetch';
import { cookieClientStorage } from './cookieStorage';
import { blobToBase64 } from './general';
import { appStorage } from './appStorage';

type ImageSizes = 'orig' | 'tiny' | 'small' | 'large';
const { publicRuntimeConfig } = getConfig() ?? {};
const defaultSecureDataHost = (publicRuntimeConfig?.SECURE_DATA_HOST_DOMAIN || '').replace(/(^\w+:|^)\/\//, '');

export function isUrlSecured(url: string, secureDataHost: string = defaultSecureDataHost): boolean {
  return Boolean(secureDataHost?.length && url.includes(secureDataHost));
}

export async function getDataSecurily(dataUrl: string): Promise<Response> {
  const sessionToken = cookieClientStorage.get('session_token');
  const withCredentials = !!isClarifaiDomain();
  const fetchedSecureData = await clarifaiFetchWithAuth({
    apiHost: '',
    path: dataUrl,
    method: 'GET',
    sessionToken,
    withCredentials,
  });
  return fetchedSecureData;
}

export async function getBase64ImageSecurily(imageUrl: string): Promise<string> {
  const fetchedSecureImage = await getDataSecurily(imageUrl);
  const blobData = await fetchedSecureImage.blob();
  const secureImage = await blobToBase64(blobData);
  return secureImage;
}

export type FetchImageUrlProps = {
  url: string;
  fallbackUrl?: string | ((image: string) => string);
};
export function ensureDomainHasSessionToken() {
  const { setDomainLevel, get } = cookieClientStorage;
  const authData = appStorage.get('authData') as CF.LocalUser;
  const localSessionToken = authData?.session_token;
  // Check if the cookie is set and is the same as the localSessionToken
  const existingCookie = get('session_token');
  if (!existingCookie && localSessionToken) {
    // Set the cookie to the domain
    setDomainLevel('session_token', localSessionToken);
  }
}
export function isClarifaiDomain() {
  if (typeof window === 'undefined') return false;
  const host = window.location.host.toLowerCase();
  if (host === 'clarifai.com') return true;
  const regEx = /\.clarifai\.com$/g;
  const matchedResult = host.match(regEx);
  return !!matchedResult?.length;
}

function checkUrlFromClarifaiHost(url: string): boolean {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.hostname === 'clarifai.com' || parsedUrl.hostname.endsWith('.clarifai.com');
  } catch {
    return false;
  }
}
export function isValidClarifaiUrl(url: string) {
  // samples.clarifai.com is a special case, as it does not support CORS * requests
  // it can be in any part of url e.g samples.clarifai.com or s3.amazonaws.com/samples.clarifai.com
  const urlIsSamplesClarifai = url.includes('samples.clarifai.com');
  if (urlIsSamplesClarifai) return false;
  return isClarifaiDomain() && checkUrlFromClarifaiHost(url);
}

export async function fetchImageUrl({ url, fallbackUrl }: FetchImageUrlProps): Promise<string> {
  try {
    const _isUrlSecured = isUrlSecured(url);
    if (isClarifaiDomain() && _isUrlSecured) {
      ensureDomainHasSessionToken();
      return url;
    }

    if (_isUrlSecured) {
      return await getBase64ImageSecurily(url);
    }
    if (fallbackUrl) return typeof fallbackUrl === 'string' ? fallbackUrl : fallbackUrl(url);
    return url;
  } catch {
    throw new Error('An error occurred while fetching the image URL');
  }
}

async function extractVideoThumbnail(data: CF.API.Inputs.VideoInputData): Promise<string> {
  if (data?.video && 'thumbnail_url' in data.video) {
    const videoThumbnailUrl = data.video.thumbnail_url as string;
    return fetchImageUrl({
      url: videoThumbnailUrl,
      fallbackUrl: `${videoThumbnailUrl}?t=${Date.now()}`,
    });
  }
  if (data?.video && 'base64' in data.video && data?.video?.base64) return `data:video/mp4;base64,${data.video.base64}`;
  return '';
}

export async function extractThumbUrl(input: CF.API.Inputs.AnyInput, imageSize: ImageSizes = 'orig'): Promise<string> {
  if ('video' in input.data) {
    return extractVideoThumbnail(input.data);
  }
  if ('text' in input.data) {
    return `${input.data.text.thumbnail_url}?t=${Date.now()}` || '';
  }
  if ('url' in input.data.image) {
    if (input.data.image.hosted) {
      const { prefix, suffix, sizes } = input.data.image.hosted;
      let decidedSize = 'orig';
      if (imageSize === 'orig') {
        if (sizes?.includes('small')) {
          decidedSize = 'small';
        } else if (sizes?.includes('tiny')) {
          decidedSize = 'tiny';
        }
      } else {
        decidedSize = imageSize;
      }
      const imageUrl = `${prefix}/${decidedSize}/${suffix}`;
      return fetchImageUrl({
        url: imageUrl,
        fallbackUrl: `${imageUrl}?t=${Date.now()}`,
      });
    } else {
      const imageUrl = input.data.image.url;

      return fetchImageUrl({
        url: imageUrl,
        fallbackUrl: `${imageUrl}?t=${Date.now()}`,
      });
    }
  } else {
    return `data:image/jpeg;base64,${input.data.image.base64}`;
  }
}

function getHostedImage(hosted: HostedURL): Promise<string> {
  const sizes = hosted.getSizesList();
  const prefix = hosted.getPrefix();
  const suffix = hosted.getSuffix();

  const preferredSize = sizes.includes('small') ? 'small' : sizes.includes('tiny') ? 'tiny' : 'orig';

  const imageUrl = `${prefix}/${preferredSize}/${suffix}`;

  return fetchImageUrl({
    url: imageUrl,
    fallbackUrl: `${imageUrl}?t=${Date.now()}`,
  });
}

/// Direct copy of `extractThumbUrl` but written to work with GRPC Inputs
export async function getInputThumbnailUrl(input: Input): Promise<string> {
  const data = input.getData();
  if (!data) {
    return '';
  }

  const video = data.getVideo();
  if (video) {
    const hosted = video.getHostedThumbnail();
    if (hosted) {
      return getHostedImage(hosted);
    }

    if (video.getThumbnailUrl()) {
      return `${video.getThumbnailUrl()}?t=${Date.now()}`;
    }
    return '';
  }

  const text = data.getText();
  if (text) {
    return text.getUrl() || '';
  }

  const image = data.getImage();
  if (image) {
    const hosted = image.getHosted();
    if (hosted) {
      return getHostedImage(hosted);
    }

    if (image.getUrl()) {
      return `${image.getUrl()}?t=${Date.now()}`;
    }

    return `data:image/jpeg;base64,${image.getBase64_asB64()}`;
  }

  return '';
}

export const extractMediaUrl = (input: CF.API.Inputs.ImageInput | CF.API.Inputs.VideoInput): string => {
  if ('video' in input.data) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return 'url' in input.data.video ? input.data.video.url! : `data:video/mp4;base64,${input.data.video.base64}`;
  }
  return 'url' in input.data.image ? `${input.data.image.url}` : `data:image/jpeg;base64,${input.data.image.base64}`;
};

/**
 * Obtain the hosted url for an input at the specified size. Typically used for inputs that were uploaded to an app.
 */
/* istanbul ignore next */
export const extractHostedUrl = async (input: CF.API.Inputs.Input, size: 'orig' | 'tiny' | 'small' | 'large' = 'orig'): Promise<string> => {
  let prefix: string | undefined = '';
  let suffix: string | undefined = '';
  const inputData = input.data;

  if ('image' in inputData) {
    prefix = inputData.image.hosted?.prefix;
    suffix = inputData.image.hosted?.suffix;
  }

  if ('video' in inputData) {
    prefix = inputData.video.hosted?.prefix;
    suffix = inputData.video.hosted?.suffix;
  }

  if ('text' in inputData) {
    prefix = inputData.text.hosted?.prefix;
    suffix = inputData.text.hosted?.suffix;
  }

  const inputUrl = `${prefix}/${size}/${suffix}`;

  const fallbackImageUrl = [prefix || '', size, suffix || ''].join('/');

  return fetchImageUrl({
    url: inputUrl,
    fallbackUrl: fallbackImageUrl,
  });
};

export const isVideo = (input: Input) => !!input.getData()?.hasVideo();

export const isText = (input: Input) => !!input.getData()?.hasText();

export const getVideoInfo = (input: Input | null) => {
  return input?.getData()?.getVideo()?.getVideoInfo();
};

// .tif images cannot be displayed in the browser, so we need to handle them differently
// This function will be called when a .tif image is uploaded and convert it to a .png image
export async function tiffToJpeg(tiffBase64: string): Promise<string> {
  return new Promise((resolve, reject) => {
    try {
      // Decode the base64 string
      const binaryString = window.atob(tiffBase64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i += 1) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      // Decode the TIFF image
      const ifds = UTIF.decode(bytes.buffer);
      UTIF.decodeImage(bytes.buffer, ifds[0]);
      const rgba = UTIF.toRGBA8(ifds[0]);

      // Create an off-screen canvas
      const canvas = document.createElement('canvas');
      canvas.width = ifds[0].width;
      canvas.height = ifds[0].height;
      const ctx = canvas.getContext('2d');
      const imageData = ctx?.createImageData(ifds[0].width, ifds[0].height);
      imageData?.data.set(rgba);
      if (imageData) ctx?.putImageData(imageData, 0, 0);

      // Convert the canvas to a JPEG Base64 string
      const jpegBase64 = canvas.toDataURL('image/jpeg').split(',')[1]; // Remove the data URL part

      resolve(jpegBase64);
    } catch (error: unknown) {
      if (error instanceof Error) {
        reject(new Error(`Error converting TIFF to JPEG: ${error.message}`));
      } else {
        reject(new Error('An unknown error occurred while converting TIFF to JPEG.'));
      }
    }
  });
}
