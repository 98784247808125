import { defaultIconProps, IconProps } from './IconProps';

export const IconCode02: React.FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M14.167 14.1667L18.3337 10L14.167 5.83333M5.83366 5.83333L1.66699 10L5.83366 14.1667M11.667 2.5L8.33366 17.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconCode02.defaultProps = defaultIconProps;
