import { useIsOrgAccount } from 'modules/Auth/hooks';
import { useCurrentUserOrg } from 'modules/Organizations/hocs';
import {
  ORGANIZATION_ROLE_ID_ADMIN,
  ORGANIZATION_ROLE_ID_FINANCIAL_MANAGER,
  ORGANIZATION_ROLE_ID_MEMBER,
  ORGANIZATION_ROLE_ID_MEMBER_MIN,
  ORGANIZATION_ROLE_ID_ORG_USER,
  RolesT,
} from 'modules/Organizations/constants';
import { useFeatureFlagsLD } from 'modules/FeatureFlagsLD/FeatureFlagsProviderLD';

/* This is a map of the allowed roles for each page in the control center reflecting as it is what we should have on
   LDF allowedOrgRolesForControlCenter. When you update one of these roles, you should update the LDF flag as well and vice-versa.
*/
export const offlineAllowedOrgRolesForControlCenter: Record<string, RolesT[]> = {
  '/control-center': [
    ORGANIZATION_ROLE_ID_ADMIN,
    ORGANIZATION_ROLE_ID_MEMBER,
    ORGANIZATION_ROLE_ID_MEMBER_MIN,
    ORGANIZATION_ROLE_ID_ORG_USER,
    ORGANIZATION_ROLE_ID_FINANCIAL_MANAGER,
  ],
  '/control-center/overview': [
    ORGANIZATION_ROLE_ID_ADMIN,
    ORGANIZATION_ROLE_ID_MEMBER,
    ORGANIZATION_ROLE_ID_MEMBER_MIN,
    ORGANIZATION_ROLE_ID_ORG_USER,
    ORGANIZATION_ROLE_ID_FINANCIAL_MANAGER,
  ],
  '/control-center/usage': [
    ORGANIZATION_ROLE_ID_ADMIN,
    ORGANIZATION_ROLE_ID_MEMBER,
    ORGANIZATION_ROLE_ID_MEMBER_MIN,
    ORGANIZATION_ROLE_ID_ORG_USER,
    ORGANIZATION_ROLE_ID_FINANCIAL_MANAGER,
  ],
  '/control-center/details': [
    ORGANIZATION_ROLE_ID_ADMIN,
    ORGANIZATION_ROLE_ID_MEMBER,
    ORGANIZATION_ROLE_ID_MEMBER_MIN,
    ORGANIZATION_ROLE_ID_ORG_USER,
    ORGANIZATION_ROLE_ID_FINANCIAL_MANAGER,
  ],
  '/control-center/teams-logs': [ORGANIZATION_ROLE_ID_ADMIN],
  '/control-center/costs': [ORGANIZATION_ROLE_ID_ADMIN, ORGANIZATION_ROLE_ID_FINANCIAL_MANAGER],
};

export function useIsViewableAsOrg() {
  const isOrg = useIsOrgAccount();
  const org = useCurrentUserOrg();
  const orgRole = org?.role.id;
  const { allowedOrgRolesForControlCenter = offlineAllowedOrgRolesForControlCenter } = useFeatureFlagsLD();

  return (pathname: string) => {
    return isOrg ? allowedOrgRolesForControlCenter[pathname]?.includes(orgRole as RolesT) : true;
  };
}

export const useAllowedOrgRoles = (pathname: string) => {
  return (): RolesT[] => {
    const { allowedOrgRolesForControlCenter } = useFeatureFlagsLD();
    return allowedOrgRolesForControlCenter?.[pathname];
  };
};
