import { AuthModalState } from './components/AuthModal';

export const enum EventTypes {
  LoggedIn = 'USER_LOGGED_IN',
  LoggedOut = 'USER_LOGGED_OUT',
}

export const TRACK_EVENT_NAMES: Record<AuthModalState, string> = {
  [AuthModalState.SHOW_SIGN_IN]: 'Sign In Dialog Opened',
  [AuthModalState.SHOW_SIGN_UP]: 'Sign Up Dialog Opened',
  [AuthModalState.SHOW_PENDING_VERIFICATION]: 'Pending Verification Dialog Opened',
  [AuthModalState.SHOW_FORGOT_PASSWORD]: 'Forgot Password Dialog Opened',
  [AuthModalState.SHOW_2FA]: '2FA Dialog Opened',
};
