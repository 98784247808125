import { defaultIconProps, IconProps } from './IconProps';

export const IconFlagBanner: import('react').FC<IconProps> = ({ size, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path opacity="0.2" d="M21.75 5.25L17.4647 14.25H3.75L8.25 9.75L3.75 5.25H21.75Z" fill="#344054" />
    <path
      d="M22.3839 4.84969C22.3163 4.74266 22.2228 4.65447 22.112 4.59333C22.0011 4.53219 21.8767 4.50008 21.7501 4.5H3.7501C3.60168 4.49988 3.45656 4.54381 3.33312 4.62621C3.20967 4.70861 3.11345 4.82579 3.05664 4.96291C2.99982 5.10002 2.98497 5.25092 3.01396 5.39648C3.04294 5.54204 3.11447 5.67573 3.21948 5.78063L7.18979 9.75L3.21948 13.7194C3.11447 13.8243 3.04294 13.958 3.01396 14.1035C2.98497 14.2491 2.99982 14.4 3.05664 14.5371C3.11345 14.6742 3.20967 14.7914 3.33312 14.8738C3.45656 14.9562 3.60168 15.0001 3.7501 15H16.277L13.5732 20.6775C13.528 20.7667 13.5011 20.8641 13.4941 20.9639C13.4871 21.0637 13.5001 21.1638 13.5324 21.2585C13.5646 21.3532 13.6155 21.4405 13.682 21.5152C13.7486 21.5899 13.8293 21.6505 13.9196 21.6936C14.0099 21.7366 14.1079 21.7611 14.2079 21.7657C14.3078 21.7703 14.4076 21.7548 14.5015 21.7203C14.5954 21.6857 14.6814 21.6327 14.7544 21.5644C14.8275 21.4961 14.8862 21.4138 14.927 21.3225L22.427 5.5725C22.4814 5.45817 22.506 5.33193 22.4984 5.20554C22.4909 5.07916 22.4515 4.95673 22.3839 4.84969ZM16.9904 13.5H5.56042L8.78073 10.2806C8.85046 10.211 8.90578 10.1283 8.94352 10.0372C8.98127 9.94616 9.00069 9.84856 9.00069 9.75C9.00069 9.65144 8.98127 9.55384 8.94352 9.4628C8.90578 9.37175 8.85046 9.28903 8.78073 9.21938L5.56042 6H20.5604L16.9904 13.5Z"
      fill="#343330"
    />
  </svg>
);

IconFlagBanner.defaultProps = defaultIconProps;
