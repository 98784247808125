import { defaultIconProps, IconProps } from './IconProps';

export const IconUserCircle: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10989_29726)">
      <path
        d="M4.43057 16.1986C4.93751 15.0043 6.12109 14.1666 7.50033 14.1666H12.5003C13.8796 14.1666 15.0631 15.0043 15.5701 16.1986M13.3337 7.91663C13.3337 9.75758 11.8413 11.25 10.0003 11.25C8.15938 11.25 6.66699 9.75758 6.66699 7.91663C6.66699 6.07568 8.15938 4.58329 10.0003 4.58329C11.8413 4.58329 13.3337 6.07568 13.3337 7.91663ZM18.3337 9.99996C18.3337 14.6023 14.6027 18.3333 10.0003 18.3333C5.39795 18.3333 1.66699 14.6023 1.66699 9.99996C1.66699 5.39759 5.39795 1.66663 10.0003 1.66663C14.6027 1.66663 18.3337 5.39759 18.3337 9.99996Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10989_29726">
        <rect width={size} height={size} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconUserCircle.defaultProps = defaultIconProps;
