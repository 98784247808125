import { Tooltip as TooltipComponent } from 'components/Tooltip';
import { TooltipInitialState } from 'reakit/Tooltip';
import { tooltipArrowStyled, tooltipContentStyled, tooltipWrapperStyled } from './Tooltip.styled';
type TooltipProps = {
  text?: string | JSX.Element;
  children: React.ReactElement;
  initialState?: TooltipInitialState;
};
// Documentation https://reakit.io/docs/tooltip/
export const Tooltip: React.FC<TooltipProps> = ({
  text,
  children,
  initialState
}) => {
  if (!text) return children;
  return <TooltipComponent showArrow initialState={{
    ...{
      placement: 'auto'
    },
    ...initialState
  }} className={tooltipWrapperStyled} arrowProps={{
    className: tooltipArrowStyled
  }} data={<div className={tooltipContentStyled}>{text}</div>}>
      {children}
    </TooltipComponent>;
};