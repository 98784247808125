import { cx } from '@linaria/core';
import { useMemo, forwardRef } from 'react';
import type { ButtonProps as RBtnProps } from 'reakit';
import { textRegular } from 'styles/typography';
import { cssHelpers } from 'styles/utils/core';
import { IconProps } from 'components/Icons/IconProps';
import { VariantTypes, StyledButton } from './Button.styles';
import { ButtonLoader } from './ButtonLoader';
export type ButtonProps = RBtnProps & {
  size?: 'sm' | 'md';
  iconSize?: number;
  variant?: VariantTypes;
  loading?: boolean;
  RightIcon?: React.ElementType<IconProps>;
  LeftIcon?: React.ElementType<IconProps>;
};
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
  RightIcon,
  LeftIcon,
  loading = false,
  size = 'sm',
  iconSize,
  variant = 'primary',
  children,
  className,
  disabled,
  ...rest
}, ref) => {
  const getIconSize = useMemo(() => {
    if (iconSize) return iconSize;
    return size === 'sm' ? 14 : 16;
  }, [iconSize, size]);
  return <StyledButton {...rest} ref={ref} data-loading={loading} className={cx(RightIcon || LeftIcon ? 'icon-button' : '', `btn`, textRegular, className, size, disabled && 'disabled', variant === 'link' && 'link')} disabled={disabled} variant={variant}>
        {LeftIcon && <LeftIcon data-testid="left-button-icon" className={cx(cssHelpers.margin.xr.half)} size={getIconSize} />}
        {children}
        {RightIcon && <RightIcon data-testid="right-button-icon" className={cx(cssHelpers.margin.xl.half)} size={getIconSize} />}
        {loading && <ButtonLoader />}
      </StyledButton>;
});