import { IconProps } from 'components/Icons/IconProps';

export const IconOracle = ({ size, ...rest }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    {...rest}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        fill="#F00"
        d="M 6.632812 15.761719 C 3.429688 15.761719 0.832031 13.167969 0.832031 9.96875 C 0.832031 6.765625 3.429688 4.167969 6.632812 4.167969 L 13.371094 4.167969 C 16.570312 4.167969 19.167969 6.765625 19.167969 9.96875 C 19.167969 13.167969 16.570312 15.761719 13.371094 15.761719 Z M 13.21875 13.714844 C 15.292969 13.714844 16.972656 12.039062 16.972656 9.96875 C 16.972656 7.894531 15.292969 6.210938 13.21875 6.210938 L 6.78125 6.210938 C 4.707031 6.210938 3.027344 7.894531 3.027344 9.96875 C 3.027344 12.039062 4.707031 13.714844 6.78125 13.714844 Z M 13.21875 13.714844 "
      />
    </g>
  </svg>
);
