import { cx, css } from '@linaria/core';
import { flex, theming } from 'styles/utils';
import { ellipsis } from 'polished';
export const account = cx("omponents__Navbar__components__Account__styles_ts-account-a21827g", flex.init, flex.alignItems.center);
export const selectedAccountRow = "omponents__Navbar__components__Account__styles_ts-selectedAccountRow-s1d7m6m5";
export const avatar = cx("omponents__Navbar__components__Account__styles_ts-avatar-axldssh", flex.init, flex.alignItems.center, flex.justifyContent.center);
export const _basicTag = "omponents__Navbar__components__Account__styles_ts-_basicTag-_1qwwi7a";
export const userTag = cx("omponents__Navbar__components__Account__styles_ts-userTag-u11ujwn9", _basicTag);
export const orgTag = cx("omponents__Navbar__components__Account__styles_ts-orgTag-o4gyi03", _basicTag);

require("./styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./styles.ts");