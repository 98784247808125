import React, { ChangeEvent, useEffect, forwardRef, useCallback, KeyboardEvent } from 'react';
import { cx } from '@linaria/core';
import { Alert } from 'components/Alert';
import { IconInfoFill } from 'components/Icons';
import { useIdValidation$ } from 'hooks/useIdValidation$';
import { useCurrentUser, useIsOrgAccount } from 'modules/Auth/hooks';
import { Form, Formik } from 'formik';
import { InputField } from 'components/Formik/InputField';
import { errorMsg } from 'components/Formik/Forms.styles';
import { useMutationTE } from 'utils/react-query';
import cogoToast from 'cogo-toast';
import { SelectOption } from 'components/Select/SelectControlled';
import { object, string } from 'yup';
import * as crypto from 'crypto';
import { Spinner } from 'components/Spinner/Spinner';
import { appStorage } from 'utils/appStorage';
import { HUBSPOT_CUSTOM_EVENTS_NAME, sendEvent } from 'utils/hubspotApi/hubspotApi';
import { appIdTooltipText } from 'modules/Apps/constants';
import { useDuplicateApplicationTE, useUserAndOrgsOptions } from 'modules/Apps/components/DuplicateApplication/DuplicateApplication.hooks';
import { alertText, overrideInputField, createAppGormGroup, inputField, fieldDesc, fieldTopMargin, fieldBottomMargin } from './styles';
import { usePublicWorkflows } from './CreateApplication.hooks';

type FormValues = {
  appId: string;
  description: string;
};

type RequestValues = {
  selectedUser: SelectOption;
  selectedDestination?: SelectOption;
  existing_app_id?: string;
  new_app_id?: string;
  new_app_name?: string;
  filters?: {
    [key: string]: boolean;
  };
  destination_app?: {
    description?: string;
    id?: string;
  };
};
export const DUPLICATE_APP_ID = 'duplicateAppId';

const duplicateAppValidationSchema = object().shape({
  appId: string().required('Required'),
  description: string().min(2, 'Should be greater than 2'),
});

export interface DuplicateApplicationFormProps {
  onAppDuplicated: (createdApp: CF.API.Apps.DuplicatedApp) => void;
  setIsLoaded?: (flag: boolean) => void;
  setSubmitDisabled: (flag: boolean) => void;
  appId: string;
  userOrOrgId: string;
}

export const DuplicateApplicationForm = forwardRef<HTMLFormElement, DuplicateApplicationFormProps>(
  ({ onAppDuplicated, setSubmitDisabled, setIsLoaded, appId, userOrOrgId }, forwardedRef) => {
    const [defaultSelectedUserOption] = useUserAndOrgsOptions();
    const publicWorkflows = usePublicWorkflows();
    const user = useCurrentUser();
    const isOrgAccount = useIsOrgAccount();

    const makeDuplicateAppTE = useDuplicateApplicationTE({ userId: userOrOrgId, appId });
    const { mutate, isLoading } = useMutationTE((values: RequestValues) => makeDuplicateAppTE(values)(), {
      onSuccess: ({ app_duplications: [duplicatedApp] }) => {
        if (appStorage.get('first_app_not_created')) {
          sendEvent({ email: user?.email, eventName: HUBSPOT_CUSTOM_EVENTS_NAME.firstAppCreated, properties: {} });
          appStorage.rm('first_app_not_created');
        }
        appStorage.set(`${DUPLICATE_APP_ID}-${duplicatedApp.new_app_id}`, duplicatedApp.id);
        cogoToast.success(`Copying ${duplicatedApp.new_app_name} has begun...`);
        onAppDuplicated(duplicatedApp);
      },
      onError: ({ props }) => {
        cogoToast.error(props?.reason || 'Could not Create Application', { heading: 'Error' });
      },
    });

    const [onIdChange, { errorString }] = useIdValidation$({
      userId: user?.user_id || '',
      entityName: 'app',
    });

    const handleSubmit = (values: FormValues): void => {
      const data = {
        selectedUser: defaultSelectedUserOption,
        destination_app: {
          user_id: user?.user_id,
          description: values.description,
          id: values.appId,
        },
        filters: {
          copy_concepts: true,
          copy_workflows: true,
          copy_installed_module_versions: true,
        },
      };
      mutate(data);
    };

    const handleAppIdChange = (
      event: ChangeEvent<HTMLInputElement>,
      setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void,
    ): void => {
      const value = event.target.value.replaceAll(/[^\dA-Za-z-]/g, '-');
      onIdChange(value);
      setFieldValue('appId', value);
    };

    const handleKeyDown = useCallback((event: KeyboardEvent) => {
      event.stopPropagation();
    }, []);

    return (
      <>
        {publicWorkflows?.length ? (
          <Formik onSubmit={handleSubmit} initialValues={{ appId: '', description: '' }} validationSchema={duplicateAppValidationSchema}>
            {({ isValid, dirty, values, setFieldValue, errors }) => {
              useEffect(() => {
                setFieldValue('appId', `${appId}-${crypto.randomBytes(6).toString('hex')}`.slice(0, 45));
              }, [appId]);

              useEffect(() => {
                setSubmitDisabled(!isValid || !dirty || isLoading || !!errorString);
              }, [isValid, dirty, isLoading, errorString]);

              useEffect(() => {
                if (publicWorkflows?.length > 0 && setIsLoaded) {
                  setIsLoaded(true);
                }
              }, [publicWorkflows, setIsLoaded]);

              return (
                <Form className={overrideInputField} ref={forwardedRef}>
                  <div className={createAppGormGroup}>
                    <InputField
                      name="appId"
                      label="App ID"
                      placeholder="Enter Unique Identifier"
                      customError={errorString}
                      tooltip={{ title: 'App ID', text: appIdTooltipText }}
                      onChange={(event) => handleAppIdChange(event, setFieldValue)}
                      className={inputField}
                      onKeyDown={handleKeyDown}
                      truncateError
                      required
                      style={{ minHeight: 40 }}
                    />
                    {values.appId?.length > 0 && <p className={errorMsg}>{errorString}</p>}

                    <div className={cx(fieldDesc, (errorString.length > 0 || errors.appId) && fieldTopMargin)}>
                      Choose a clear and descriptive name for your app, such as face-detection, logo-detection, moderation, etc.
                    </div>
                  </div>
                  <div className={errors.description ? fieldBottomMargin : createAppGormGroup}>
                    <InputField name="description" label="Short Description" placeholder="Enter Short Description" onKeyDown={handleKeyDown} />
                  </div>

                  {!isOrgAccount && (
                    <Alert id="info-alert" type="info" data-testid="api-key-message" className={alertText}>
                      <IconInfoFill size={18} color="blue" />
                      <span>
                        <b>Note:</b>an API Key will be created automatically for this application
                      </span>
                    </Alert>
                  )}
                </Form>
              );
            }}
          </Formik>
        ) : (
          <Spinner />
        )}
      </>
    );
  },
);
