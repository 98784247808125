import { defaultIconProps, IconProps } from './IconProps';

export const IconRocket02: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M9.99967 12.5L7.49967 10M9.99967 12.5C11.1637 12.0573 12.2804 11.499 13.333 10.8334M9.99967 12.5V16.6667C9.99967 16.6667 12.5247 16.2084 13.333 15C14.233 13.65 13.333 10.8334 13.333 10.8334M7.49967 10C7.94313 8.84957 8.50151 7.74676 9.16634 6.70838C10.1373 5.15587 11.4894 3.87758 13.0938 2.99512C14.6983 2.11266 16.5019 1.65535 18.333 1.66671C18.333 3.93338 17.683 7.91671 13.333 10.8334M7.49967 10H3.33301C3.33301 10 3.79134 7.47504 4.99967 6.66671C6.34967 5.76671 9.16634 6.66671 9.16634 6.66671M3.74967 13.75C2.49967 14.8 2.08301 17.9167 2.08301 17.9167C2.08301 17.9167 5.19967 17.5 6.24967 16.25C6.84134 15.55 6.83301 14.475 6.17467 13.825C5.85076 13.5159 5.42409 13.3372 4.97653 13.3234C4.52897 13.3096 4.09207 13.4615 3.74967 13.75Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconRocket02.defaultProps = defaultIconProps;
