import { defaultIconProps, IconProps } from './IconProps';

export const IconFlask: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      opacity="0.2"
      d="M19.5002 20.2501H4.50023C4.36745 20.2501 4.23704 20.2149 4.12233 20.148C4.00761 20.0812 3.9127 19.985 3.8473 19.8695C3.78189 19.7539 3.74834 19.6231 3.75006 19.4903C3.75179 19.3576 3.78873 19.2276 3.85711 19.1138L6.71461 14.3513C7.95492 14.1188 9.71461 14.2191 11.9993 15.3751C15.0227 16.906 17.1321 16.5854 18.3274 16.0922L20.1405 19.1138C20.2088 19.2274 20.2457 19.3571 20.2476 19.4896C20.2494 19.6222 20.2161 19.7528 20.151 19.8683C20.086 19.9837 19.9915 20.0799 19.8771 20.147C19.7628 20.214 19.6328 20.2496 19.5002 20.2501Z"
      fill={color}
    />
    <path
      d="M20.7835 18.7284L15 9.08625V3.75H15.75C15.9489 3.75 16.1397 3.67098 16.2804 3.53033C16.421 3.38968 16.5 3.19891 16.5 3C16.5 2.80109 16.421 2.61032 16.2804 2.46967C16.1397 2.32902 15.9489 2.25 15.75 2.25H8.25002C8.05111 2.25 7.86034 2.32902 7.71969 2.46967C7.57904 2.61032 7.50002 2.80109 7.50002 3C7.50002 3.19891 7.57904 3.38968 7.71969 3.53033C7.86034 3.67098 8.05111 3.75 8.25002 3.75H9.00002V9.08625L3.21658 18.7284C3.08018 18.9558 3.0065 19.2154 3.00306 19.4805C2.99961 19.7457 3.06652 20.007 3.19697 20.2379C3.32741 20.4688 3.51673 20.6609 3.74563 20.7948C3.97454 20.9287 4.23484 20.9995 4.50002 21H19.5C19.7654 21 20.0261 20.9295 20.2554 20.7959C20.4847 20.6622 20.6744 20.4701 20.8052 20.2391C20.936 20.0081 21.0031 19.7466 20.9998 19.4812C20.9965 19.2158 20.9228 18.9561 20.7863 18.7284H20.7835ZM10.3931 9.67969C10.4633 9.56342 10.5003 9.43016 10.5 9.29437V3.75H13.5V9.29437C13.4998 9.43016 13.5368 9.56342 13.6069 9.67969L17.19 15.6562C16.065 15.8784 14.4647 15.7847 12.3385 14.7084C10.8469 13.9537 9.42752 13.5534 8.09908 13.5075L10.3931 9.67969ZM4.50002 19.5L7.17565 15.0394C8.51158 14.8772 10.0172 15.2128 11.6588 16.0444C13.44 16.9453 14.94 17.2519 16.1588 17.2519C16.7786 17.2546 17.3957 17.1694 17.9916 16.9988L19.5 19.5H4.50002Z"
      fill={color}
    />
  </svg>
);

IconFlask.defaultProps = defaultIconProps;
