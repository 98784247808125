import { cx } from '@linaria/core';
import { flex } from 'styles/utils/core';
import { useMutationTE } from 'utils/react-query';
import { Link } from 'components/Link';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useAuthActions } from 'modules/Auth/AuthContext';
import { LoginPayload } from 'api/users';
import { SSOProviders } from 'modules/Auth/components/Login/SSOProviders';
import { renderLeftError } from 'utils/renderLeftError';
import { LoginForm } from 'modules/Auth/components/Login/LoginForm';
import { IconClarifai } from 'components/Icons';
import { contentStyles, headerStyles, signupLinkStyles, signupDivStyles } from './styles';

export const SignInView = ({
  onSignin,
  onSignUpClick,
  onForgotPasswordClick,
}: {
  onSignin: (data: CF.LocalUser) => void;
  onSignUpClick: () => void;
  onForgotPasswordClick?: () => void;
}): JSX.Element => {
  const router = useRouter();
  const { asPath } = router;
  const { makeLoginTE } = useAuthActions();

  const {
    dataE,
    mutate: login,
    isLoading,
  } = useMutationTE((body: LoginPayload) => makeLoginTE(body)(), {
    onSuccess: (data: CF.LocalUser) => {
      if (!data?.two_factor_auth_enabled) {
        onSignin(data);
      }
    },
  });

  const handleLogin = useCallback(
    (values: LoginPayload): void => {
      login(values);
    },
    [login],
  );

  return (
    <div className={contentStyles}>
      <div className={cx(flex.init, flex.direction.row, flex.justifyContent.start, headerStyles)}>
        <IconClarifai size={48} />
        <span>Log in to Clarifai</span>
      </div>
      {renderLeftError(dataE)}
      <LoginForm
        handleLoginFormSubmit={handleLogin}
        isLoading={isLoading}
        showRemeberMeAndForgotPassword
        onForgotPasswordClick={onForgotPasswordClick}
      />
      <SSOProviders isLoading={isLoading} recoveryPath={asPath} />
      <div className={cx(flex.init, flex.direction.row, flex.justifyContent.center, signupDivStyles)}>
        <span className="title">Don’t have an account?</span>
        <Link
          href="/signup"
          onClick={(e) => {
            e.preventDefault();
            onSignUpClick();
          }}
        >
          <span className={signupLinkStyles}>Sign up</span>
        </Link>
      </div>
    </div>
  );
};
