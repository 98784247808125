export const CONTACT_US_LINK =
  'https://www.clarifai.com/company/contact?hsCtaTracking=6103e0be-65e4-4feb-942c-dc344db41e41%7C0b2d5cb9-56da-481e-b09c-b37fee70a135%C3%A5';

export const DISCORD_LINK = 'https://discord.com/invite/26upV8Y4Nd';

export const PRODUCT_BOARD_LINK = 'https://portal.productboard.com/clarifai/1-clarifai-product-portal';

export const API_STATUS_LINK = 'https://status.clarifai.com';

export const DOCUMENTATION_LINK = 'https://docs.clarifai.com';

export const CHANGELOG_LINK = 'https://docs.clarifai.com/product-updates/changelog';

export const QUICK_START_GUIDE_LINK = 'https://docs.clarifai.com/clarifai-basics/community';
