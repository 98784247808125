import { defaultIconProps, IconProps } from './IconProps';

export const IconClarifai: import('react').FC<IconProps> = ({ size }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.3553 1.80337C11.3158 1.03119 12.6842 1.03119 13.6447 1.80337L14.8103 2.74045C15.3125 3.14415 15.9197 3.39567 16.5603 3.4653L18.0471 3.62691C19.2723 3.76008 20.2399 4.72767 20.3731 5.95288L20.5347 7.43972C20.6043 8.08026 20.8558 8.68749 21.2595 9.18966L22.1966 10.3553C22.9688 11.3158 22.9688 12.6842 22.1966 13.6447L21.2595 14.8103C20.8558 15.3125 20.6043 15.9197 20.5347 16.5603L20.3731 18.0471C20.2399 19.2723 19.2723 20.2399 18.0471 20.3731L16.5603 20.5347C15.9197 20.6043 15.3125 20.8558 14.8103 21.2595L13.6447 22.1966C12.6842 22.9688 11.3158 22.9688 10.3553 22.1966L9.18966 21.2595C8.68749 20.8558 8.08027 20.6043 7.43972 20.5347L5.95288 20.3731C4.72767 20.2399 3.76008 19.2723 3.62691 18.0471L3.4653 16.5603C3.39567 15.9197 3.14415 15.3125 2.74045 14.8103L1.80337 13.6447C1.03119 12.6842 1.03119 11.3158 1.80337 10.3553L2.74045 9.18966C3.14415 8.68749 3.39567 8.08027 3.4653 7.43972L3.62691 5.95288C3.76008 4.72767 4.72767 3.76008 5.95288 3.62691L7.43972 3.4653C8.08026 3.39567 8.68749 3.14415 9.18965 2.74045L10.3553 1.80337Z"
      fill="url(#paint0_linear_2828_6069)"
      stroke="#99B8F7"
      strokeWidth="0.75"
    />
    <path
      d="M8.07364 13.2434C7.35648 13.246 6.7719 12.6995 6.76903 12.0237C6.76616 11.3479 7.34611 10.8038 8.05618 10.8013C8.76624 10.7987 9.35082 11.3452 9.35366 12.0143C9.3565 12.6834 8.79081 13.2408 8.07364 13.2434ZM14.7524 7.51165C13.7938 7.51514 13.7545 8.27806 12.2917 8.28339C10.8148 8.28876 10.6909 7.52643 9.81747 7.52961C9.10031 7.53222 8.52749 8.08297 8.53033 8.75207C8.53317 9.42116 9.11775 9.9677 9.82781 9.96511C10.7935 9.9616 10.8329 9.19868 12.2956 9.19336C13.7725 9.18798 13.8965 9.95031 14.7699 9.94713C15.487 9.94452 16.0598 9.39377 16.057 8.72468C16.0542 8.05558 15.4696 7.50904 14.7524 7.51165ZM14.7801 14.0353C13.8144 14.0389 13.775 14.8018 12.3123 14.8071C10.8354 14.8125 10.7114 14.0501 9.83807 14.0533C9.1209 14.0559 8.54808 14.6067 8.55092 15.2758C8.55376 15.9449 9.13834 16.4914 9.84841 16.4888C10.8141 16.4853 10.8535 15.7224 12.3162 15.7171C13.7931 15.7117 13.917 16.474 14.7904 16.4709C15.5076 16.4682 16.0804 15.9175 16.0776 15.2484C16.0747 14.5793 15.4973 14.0327 14.7801 14.0353ZM16.6835 10.7699C15.7107 10.7734 15.7496 11.5762 14.5282 11.5806C13.3993 11.5847 13.3817 10.7886 12.2953 10.7925C11.6207 10.795 11.0693 11.3791 11.072 12.0148C11.0747 12.6504 11.6382 13.2304 12.3695 13.2278C13.3423 13.2242 13.3034 12.4215 14.5247 12.417C15.6537 12.4129 15.6713 13.2091 16.7577 13.2051C17.4322 13.2027 17.9836 12.6186 17.9809 11.9829C17.9853 11.3472 17.4219 10.7672 16.6835 10.7699Z"
      fill="white"
    />
    <defs>
      <linearGradient id="paint0_linear_2828_6069" x1="12" y1="0" x2="12" y2="24" gradientUnits="userSpaceOnUse">
        <stop stopColor="#195AFF" />
        <stop offset="1" stopColor="#0046D4" />
      </linearGradient>
    </defs>
  </svg>
);

IconClarifai.defaultProps = defaultIconProps;
