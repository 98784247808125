import { defaultIconProps, IconProps } from './IconProps';

export const IconHammerDuotone: import('react').FC<IconProps> = ({ size, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      opacity="0.2"
      d="M23.0306 12.0939L20.3438 14.7808C20.2032 14.921 20.0127 14.9998 19.8141 14.9998C19.6155 14.9998 19.425 14.921 19.2844 14.7808L15.7594 11.2502L13.1344 13.8752L10.1344 10.8752L12.7594 8.25016L7.5 2.97954C9.02561 2.29033 10.7251 2.08263 12.3718 2.38413C14.0185 2.68563 15.5341 3.482 16.7166 4.66704L23.0306 11.0317C23.1005 11.1014 23.1559 11.1842 23.1938 11.2753C23.2316 11.3664 23.2511 11.4641 23.2511 11.5628C23.2511 11.6615 23.2316 11.7592 23.1938 11.8503C23.1559 11.9415 23.1005 12.0242 23.0306 12.0939Z"
      fill="#343330"
    />
    <path
      d="M23.5633 10.4999L17.239 4.13242C15.5499 2.44673 13.2611 1.5 10.8748 1.5C8.48844 1.5 6.1996 2.44673 4.51054 4.13242L4.5021 4.14086L3.21116 5.47492C3.07743 5.61863 3.00538 5.80905 3.01047 6.00529C3.01557 6.20153 3.0974 6.38795 3.2384 6.52454C3.3794 6.66112 3.56833 6.73698 3.76463 6.73582C3.96094 6.73466 4.14896 6.65659 4.28835 6.51836L5.57554 5.18898C6.0934 4.67122 6.68437 4.23218 7.3296 3.88586L11.689 8.24992L2.68897 17.2499C2.54965 17.3892 2.43913 17.5546 2.36373 17.7366C2.28832 17.9186 2.24951 18.1137 2.24951 18.3107C2.24951 18.5077 2.28832 18.7028 2.36373 18.8848C2.43913 19.0668 2.54965 19.2322 2.68897 19.3715L4.62866 21.3112C4.76795 21.4505 4.93333 21.561 5.11534 21.6364C5.29735 21.7118 5.49243 21.7506 5.68944 21.7506C5.88645 21.7506 6.08154 21.7118 6.26355 21.6364C6.44556 21.561 6.61093 21.4505 6.75022 21.3112L15.7502 12.3112L17.0946 13.6555L18.7502 15.3093C18.8895 15.4486 19.0549 15.5591 19.2369 15.6345C19.4189 15.7099 19.614 15.7488 19.811 15.7488C20.008 15.7488 20.2031 15.7099 20.3851 15.6345C20.5671 15.5591 20.7325 15.4486 20.8718 15.3093L23.5615 12.6196C23.8425 12.3386 24.0006 11.9576 24.0009 11.5602C24.0013 11.1627 23.8439 10.7814 23.5633 10.4999ZM5.68897 20.2499L3.75022 18.3102L10.1252 11.9352L12.064 13.8749L5.68897 20.2499ZM15.2196 10.7174L13.1252 12.8127L11.1855 10.8749L13.2808 8.77961C13.3506 8.70995 13.4059 8.62724 13.4436 8.53619C13.4814 8.44514 13.5008 8.34754 13.5008 8.24898C13.5008 8.15042 13.4814 8.05283 13.4436 7.96178C13.4059 7.87073 13.3506 7.78801 13.2808 7.71836L8.84272 3.28117C10.125 2.92004 11.4803 2.90695 12.7693 3.24324C14.0582 3.57954 15.2344 4.25308 16.1768 5.19461L20.3224 9.36742L17.6252 12.0618L16.2808 10.7174C16.2112 10.6477 16.1285 10.5924 16.0374 10.5546C15.9464 10.5169 15.8488 10.4975 15.7502 10.4975C15.6517 10.4975 15.5541 10.5169 15.463 10.5546C15.372 10.5924 15.2893 10.6477 15.2196 10.7174ZM19.8133 14.248L18.6883 13.123L21.3752 10.4296L22.5002 11.5546L19.8133 14.248Z"
      fill="#343330"
    />
  </svg>
);

IconHammerDuotone.defaultProps = defaultIconProps;
