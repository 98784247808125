import { useId } from 'react-aria';
import { defaultIconProps, IconProps } from './IconProps';

export const IconProfessionalPlan: import('react').FC<IconProps> = ({ size, ...rest }) => {
  const id = useId();

  return (
    <svg width={size} height={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath={`url(#clip0_2151_2735_${id})`}>
        <path
          d="M18.2847 12.2558C16.9692 13.5713 18.0382 14.7563 16.0456 16.7489C14.0336 18.7609 12.7422 17.7983 11.5524 18.9881C10.5755 19.965 10.6026 21.5592 11.5912 22.5477C12.5798 23.5363 14.1836 23.5538 15.1509 22.5865C16.4664 21.271 15.3974 20.086 17.39 18.0934C19.402 16.0814 20.6934 17.044 21.8832 15.8542C22.8601 14.8772 22.833 13.2831 21.8444 12.2945C20.8558 11.306 19.2617 11.2788 18.2847 12.2558Z"
          fill="#34938E"
        />
        <path
          d="M16.0819 4.82949C14.7567 6.15467 15.9914 7.2926 14.3276 8.95633C12.7896 10.4943 11.5939 9.33725 10.114 10.8172C9.19503 11.7361 9.30061 13.3507 10.2398 14.2898C11.1789 15.229 12.8031 15.3249 13.7994 14.3286C15.1246 13.0034 13.8899 11.8655 15.5537 10.2017C17.0917 8.66374 18.2874 9.8208 19.7674 8.34086C20.6863 7.42194 20.5807 5.80739 19.6415 4.86825C18.7121 3.91943 17.0879 3.82352 16.0819 4.82949Z"
          fill="#33F1E8"
        />
        <path
          d="M7.94777 20.1809C6.97081 21.1579 5.36701 21.1404 4.36854 20.142C3.37008 19.1435 3.36251 17.5496 4.32979 16.5823C5.29708 15.615 6.90088 15.6325 7.88946 16.6211C8.87804 17.6096 8.92473 19.204 7.94777 20.1809Z"
          fill="#33F1E8"
        />
        <path
          d="M8.64641 2.61702C7.34058 3.92285 8.40952 5.10786 6.41691 7.10047C4.40496 9.11242 3.11355 8.14988 1.92379 9.33964C0.946831 10.3166 0.973963 11.9107 1.96254 12.8993C2.95112 13.8879 4.55492 13.9053 5.52221 12.9381C6.83771 11.6226 5.76877 10.4375 7.76138 8.44493C9.77333 6.43298 11.0647 7.39552 12.2545 6.20576C13.2315 5.2288 13.2043 3.63467 12.2158 2.6461C11.2272 1.65752 9.62337 1.64006 8.64641 2.61702Z"
          fill="#33F1E8"
        />
      </g>
      <defs>
        <clipPath id={`clip0_2151_2735_${id}`}>
          <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

IconProfessionalPlan.defaultProps = defaultIconProps;
