import { css } from '@linaria/core';
import { baseUnits, breakpoints, theming } from 'styles/utils';
export const jobRoleSelectWrapper = "odules__Auth__components__OnboardingDialog__OnboardingDialog_styles_ts-jobRoleSelectWrapper-j4kkscy";
export const onboardingDialogClassName = "odules__Auth__components__OnboardingDialog__OnboardingDialog_styles_ts-onboardingDialogClassName-o1qvgk4a";
export const onboardingDialogBackdropClassName = "odules__Auth__components__OnboardingDialog__OnboardingDialog_styles_ts-onboardingDialogBackdropClassName-ov9dkgw";
export const wrapper = "odules__Auth__components__OnboardingDialog__OnboardingDialog_styles_ts-wrapper-wnag2hh";
export const animatedViewsDiv = "odules__Auth__components__OnboardingDialog__OnboardingDialog_styles_ts-animatedViewsDiv-a150ngrx";
export const welcomeMsgWrapper = "odules__Auth__components__OnboardingDialog__OnboardingDialog_styles_ts-welcomeMsgWrapper-wv7bfjv";
export const accountVerifiedBadge = "odules__Auth__components__OnboardingDialog__OnboardingDialog_styles_ts-accountVerifiedBadge-a1fnqhng";
export const overrideSelectStyles = "odules__Auth__components__OnboardingDialog__OnboardingDialog_styles_ts-overrideSelectStyles-orumvna";

require("./OnboardingDialog.styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./OnboardingDialog.styles.ts");