import { noop } from 'utils/fp';
import { Popover, PopoverStateReturn } from 'components/Popover';
import { cssHelpers } from 'styles/utils/core';
import { accountPopover, accountMenuItem, accountPopoverLayout, mobileAccountSelector } from 'components/Navbar/Navbar.styles';
import { AccountSelector, PopoverButton, PopoverLink, PopoverSection } from 'components/Navbar';
import { usePathWithoutQueryParams } from 'components/Navbar/Navbar.hooks';
import { useAccountPopoverLinks } from './hooks';
export interface DesktopMenuItemsProps {
  onLogout: React.MouseEventHandler<HTMLButtonElement>;
  closePopup?: () => void;
}
export interface MobileMenuItemsProps {
  onLogout: () => void;
  canCreateApp: boolean;
  handleCreateApp: () => void;
  closePopup?: () => void;
}
export interface AccountPopoverProps extends Omit<DesktopMenuItemsProps, 'closePopup'> {
  popover: PopoverStateReturn;
}
export interface PopoverLinkProps {
  href: string;
  text: string;
  className?: string;
  badge?: string | undefined;
  externalLink?: boolean;
}
export const MobileMenuItems: React.FC<MobileMenuItemsProps> = ({
  onLogout,
  closePopup = noop,
  canCreateApp,
  handleCreateApp
}) => {
  const path = usePathWithoutQueryParams();
  const sections = useAccountPopoverLinks({
    onLogout
  });
  return <section className={accountPopoverLayout}>
      <PopoverSection>
        <div className={cssHelpers.margin.x.half}>
          <AccountSelector viewport="mobile" onClose={closePopup} withTag />
        </div>
      </PopoverSection>
      {canCreateApp && <PopoverSection>
          <PopoverButton className={accountMenuItem} text="Create an App" onClick={handleCreateApp} />
        </PopoverSection>}
      {sections.map(({
      id,
      links,
      title
    }) => {
      return <PopoverSection key={id} title={title}>
            {links.map(({
          text,
          handleClick,
          url,
          badge,
          externalLink
        }) => {
          return url && url !== path ? <PopoverLink className={accountMenuItem} href={url} text={text} badge={badge} key={`${id}_${url}`} externalLink={externalLink} /> : <PopoverButton className={accountMenuItem} text={text} badge={badge} onClick={handleClick || closePopup} key={`${id}_${url}`} />;
        })}
          </PopoverSection>;
    })}
    </section>;
};
export const DesktopMenuItems: React.FC<DesktopMenuItemsProps> = ({
  onLogout,
  closePopup = noop
}) => {
  const path = usePathWithoutQueryParams();
  const sections = useAccountPopoverLinks({
    onLogout,
    desktopOnly: true
  });
  return <>
      <PopoverSection className={mobileAccountSelector}>
        <AccountSelector viewport="desktop" onClose={closePopup} withTag />
      </PopoverSection>
      {sections.map(({
      id,
      links,
      title
    }) => {
      return <PopoverSection key={id} title={title}>
            {links.map(({
          text,
          handleClick,
          url,
          badge,
          externalLink
        }) => {
          return url && url !== path ? <PopoverLink href={url} text={text} badge={badge} key={`${id}_${url}`} externalLink={externalLink} /> : <PopoverButton text={text} badge={badge} onClick={handleClick || closePopup} key={`${id}_${url}`} />;
        })}
          </PopoverSection>;
    })}
    </>;
};
export const MenuPopover: React.FC<AccountPopoverProps> = ({
  popover,
  onLogout
}): JSX.Element => {
  const closePopup = (): void => {
    popover.hide();
  };
  return <Popover data-testid="navbar-menu-popover" aria-label="Account" popover={popover} className={accountPopover}>
      <DesktopMenuItems onLogout={onLogout} closePopup={closePopup} />
    </Popover>;
};