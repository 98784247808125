export const ORGANIZATION_ROLE_ID_ADMIN = 'org-owner';

export const ORGANIZATION_ROLE_ID_MEMBER = 'org-member';

export const ORGANIZATION_ROLE_ID_MEMBER_MIN = 'org-member-min-access-role';

export const ORGANIZATION_ROLE_ID_FINANCIAL_MANAGER = 'org-financial-manager';

export const ORGANIZATION_ROLE_ID_ORG_USER = 'org-user';

export type RolesT =
  | typeof ORGANIZATION_ROLE_ID_ADMIN
  | typeof ORGANIZATION_ROLE_ID_MEMBER
  | typeof ORGANIZATION_ROLE_ID_MEMBER_MIN
  | typeof ORGANIZATION_ROLE_ID_FINANCIAL_MANAGER
  | typeof ORGANIZATION_ROLE_ID_ORG_USER;

export const GTC_ESSENTIAL_PLAN_ID = 'gtc-essential-plan';

export const GTC_PROFESSIONAL_PLAN_ID = 'gtc-professional-plan';

export const ESSENTIAL_PLAN_ID = 'developer-plan';

export const PROFESSIONAL_PLAN_ID = 'professional-plan';

export const COMMUNITY_PLAN_ID = 'community-plan';

export const ENTERPRISE_PLAN_ID = 'enterprise-plan';

export const INTERNAL_PLAN_ID = 'internal-plan';

export const TEAM_ROLE_TYPE = 0 as CF.API.Organizations.TeamRoleType;

export const ORG_ROLE_TYPE = 1 as CF.API.Organizations.OrgRoleType;

export const MAX_ORGANIZATIONS = 20;
