import { cx, css } from '@linaria/core';
import { ellipsis } from 'polished';
import { theming, flex } from 'styles/utils';
import { accountPopover } from 'components/Navbar/Navbar.styles';
export const submenuIcon = "omponents__Sidebar__Sidebar_styles_ts-submenuIcon-s16imete";
export const sidebarPadding = "omponents__Sidebar__Sidebar_styles_ts-sidebarPadding-syidbo";
export const removeFlexGap = "omponents__Sidebar__Sidebar_styles_ts-removeFlexGap-rw3ts3m";
export const sidebarBorder = "omponents__Sidebar__Sidebar_styles_ts-sidebarBorder-s1njuued";
export const userArea = cx("omponents__Sidebar__Sidebar_styles_ts-userArea-u4aiv36", flex.init, flex.alignItems.center);
export const accountSwitchPopover = cx("omponents__Sidebar__Sidebar_styles_ts-accountSwitchPopover-a1p16xyf", accountPopover);
export const accountSwitchItem = cx("omponents__Sidebar__Sidebar_styles_ts-accountSwitchItem-aoxo9xk", flex.init, flex.alignItems.center);
export const accountSwitchAvatar = "omponents__Sidebar__Sidebar_styles_ts-accountSwitchAvatar-ah173c9";

require("./Sidebar.styles.linaria.module.css!=!../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./Sidebar.styles.ts");