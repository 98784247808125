import '@uiw/react-md-editor/markdown-editor.css';
import '@uiw/react-markdown-preview/markdown.css';
import dynamic from 'next/dynamic';
import type { MarkdownPreviewProps } from '@uiw/react-markdown-preview';
import { SanitizedIframe } from './SanitizedIframe';
import type { MDEProps } from './MarkdownEditor';
export const MarkdownEditor = dynamic<MDEProps>(() => import('./MarkdownEditor').then(mod => mod.MarkdownEditor_), {
  ssr: false
});
const MarkdownPreviewUnsafe = dynamic<MarkdownPreviewProps>(import('@uiw/react-markdown-preview').then(x => x.default), {
  ssr: false
});
export function MarkdownPreview(props: MarkdownPreviewProps) {
  return <MarkdownPreviewUnsafe {...props} wrapperElement={{
    ...(props.wrapperElement || {}),
    'data-color-mode': 'light'
  }} components={{
    ...(props.components || {}),
    script: EmptyComponent,
    iframe: SanitizedIframe,
    video: EmptyComponent,
    audio: EmptyComponent
  }} />;
}
const EmptyComponent = () => null;