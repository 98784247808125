import { cx } from '@linaria/core';
import { noop } from 'utils/fp';
import { IconButton } from 'components/Button';
import { PopoverAction } from 'components/Popover';
import { IconHamburgerMenu2 } from 'components/Icons';
import { useModalActions } from 'components/ModalManager/Context';
import { useCompositePopover } from 'hooks/useCompositePopover';
import {
  navBarDropdown,
  navLink__Active,
  navLink__Radius,
  btnNoBorder,
  notificationModalStyles,
  notificationModalWrapperStyles,
} from 'components/Navbar/Navbar.styles';
import { MenuPopover, MobileMenuItems, MobileMenuItemsProps } from './MenuPopover';

type MenuButtonProps = Pick<MobileMenuItemsProps, 'onLogout' | 'canCreateApp' | 'handleCreateApp'>;

const MENU_MODAL_ID = 'menu_modal_id';

export function MenuButton(props: MenuButtonProps) {
  const { openModal, closeModal } = useModalActions();

  const closeMenuModal = () => closeModal({ id: MENU_MODAL_ID });

  const openMenuModal = () =>
    openModal({
      id: MENU_MODAL_ID,
      title: '',
      makeActions: noop,
      closeOnBackdropClick: true,
      contentWrapperClassName: notificationModalStyles,
      className: notificationModalWrapperStyles,
      content: (
        <MobileMenuItems
          closePopup={closeMenuModal}
          canCreateApp={props.canCreateApp}
          handleCreateApp={props.handleCreateApp}
          onLogout={props.onLogout}
        />
      ),
    });

  const { popoverState, togglePopoverOrModal } = useCompositePopover({
    breakpointWidth: 800,
    modal: {
      id: MENU_MODAL_ID,
      open: openMenuModal,
      close: closeMenuModal,
    },
  });

  return (
    <>
      <PopoverAction popover={popoverState}>
        <IconButton
          key="menu-popover-desktop"
          aria-label="Menu"
          variant="tertiary"
          size={20}
          tooltip="Menu"
          data-testid="navbar-menu-btn"
          onClick={togglePopoverOrModal}
          Icon={(iconProps) => <IconHamburgerMenu2 {...iconProps} strokeWidth={2} />}
          className={cx(btnNoBorder, navBarDropdown, navLink__Active, navLink__Radius, popoverState.visible && 'active')}
        />
      </PopoverAction>

      <MenuPopover popover={popoverState} onLogout={props.onLogout} />
    </>
  );
}
