/* eslint-disable no-console */
import { Link } from 'components/Link';
import { SSOButtonProps } from 'modules/Auth/components/types';
import { ssoButton } from 'modules/Auth/components/styles';
import { useDataCollection } from 'modules/AnalyticsCollection/AnalyticsCollectionProvider';
import { useEffect, useState } from 'react';
import { useAuthActions } from 'modules/Auth/AuthContext';
import { Spinner } from 'components/Spinner';
import { either } from 'fp-ts';
import { pipe } from 'utils/fp';
import cogoToast from 'cogo-toast';

export const SSOButton = ({ auth_link, name, icon, is_signup }: SSOButtonProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const { closeLoginModal, refetchLogin, refetchOrg, onAuthSuccess } = useAuthActions();
  const { track } = useDataCollection();

  const onSSOClick = (): void => {
    setIsLoading(true);
    track(`SSO ${name} Clicked`, { pageName: 'login_page', name });
  };

  useEffect(() => {
    const handler = async (e: MessageEvent) => {
      if (e.data.type === 'login') {
        const [loggedInUserE, orgListE] = await Promise.all([refetchLogin(), refetchOrg()]);
        const loggedInUserDataOrErrorE = loggedInUserE.data || loggedInUserE.error;

        const orgListDataOrErrorE = orgListE.data || orgListE.error || either.left({});
        const organizations = pipe(
          orgListDataOrErrorE,
          either.fold(
            () => [],
            (data) => data?.organizations || [],
          ),
        );

        // This case will never reach as react-query's response will always have either data or error.
        if (!loggedInUserDataOrErrorE) {
          return;
        }

        // Login request is more important than org list request. If login fails, then all of the
        // further requests which depend on it (eg. org list) would fail as well.
        pipe(
          loggedInUserDataOrErrorE,
          either.fold(
            (err) => {
              const errorDescription = err?.props?.reason || err?.props?.title;
              const errorTitle = `Failed to ${is_signup ? 'sign up' : 'log in'}`;

              if (errorDescription) {
                cogoToast.error(errorDescription, { heading: errorTitle });
              } else {
                cogoToast.error(errorTitle);
              }

              setIsLoading(false);
            },
            (loginData) => {
              if (is_signup) {
                const user = loginData.user as CF.API.Users.User;
                track('SSO Sign Up Success', { userId: user?.id, email: user?.primary_email });
              }

              onAuthSuccess(
                {
                  ...loginData.user,
                  organizations,
                } as unknown as CF.LocalUser,
                true,
              );
              setIsLoading(false);
              closeLoginModal();
            },
          ),
        );
      }
    };
    window.addEventListener('message', handler, false);
    return () => {
      window.removeEventListener('message', handler, false);
    };
  }, []);

  return (
    <Link
      onClick={(e) => {
        e.preventDefault();
        const newWindowWidth = 800;
        const newWindowHeight = 600;
        const screenWidth = window.innerWidth
          ? window.innerWidth
          : document.documentElement.clientWidth
          ? document.documentElement.clientWidth
          : window.screen.width;
        const screenHeight = window.innerHeight
          ? window.innerHeight
          : document.documentElement.clientHeight
          ? document.documentElement.clientHeight
          : window.screen.height;

        const left = (screenWidth - newWindowWidth) / 2;
        const top = (screenHeight - newWindowHeight) / 2;

        window.open(auth_link, 'pagename', `top=${top},left=${left},popup,resizable,height=${newWindowHeight},width=${newWindowWidth}`);
        onSSOClick();
      }}
      href={auth_link}
      className={ssoButton}
      data-testid={`sso-provider-${name}`}
    >
      {name === 'github' && <img className="icon" src="/sso_icons/github-new.svg" alt="github-icon" />}
      {name === 'okta' && <img className="icon" src="/sso_icons/okta-new.svg" alt="okta-icon" />}
      {name !== 'github' && name !== 'okta' && <img className="icon" src={icon} alt={`${name}-icon`} />}

      <div className="text-wrapper">
        <span className="sso-provider-text">{is_signup ? 'Sign up with ' : 'Log in with '}</span>
        <span className="sso-provider">{name}</span>
      </div>
      {isLoading && <Spinner />}
    </Link>
  );
};
