import { defaultIconProps, IconProps } from './IconProps';

export const IconCopy02: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g clipPath="url(#clip0_10989_18235)">
      <path
        d="M13.3337 6.66675V4.33341C13.3337 3.39999 13.3337 2.93328 13.152 2.57676C12.9922 2.26316 12.7372 2.00819 12.4236 1.8484C12.0671 1.66675 11.6004 1.66675 10.667 1.66675H4.33366C3.40024 1.66675 2.93353 1.66675 2.57701 1.8484C2.2634 2.00819 2.00844 2.26316 1.84865 2.57676C1.66699 2.93328 1.66699 3.39999 1.66699 4.33341V10.6667C1.66699 11.6002 1.66699 12.0669 1.84865 12.4234C2.00844 12.737 2.2634 12.992 2.57701 13.1518C2.93353 13.3334 3.40024 13.3334 4.33366 13.3334H6.66699M9.33366 18.3334H15.667C16.6004 18.3334 17.0671 18.3334 17.4236 18.1518C17.7372 17.992 17.9922 17.737 18.152 17.4234C18.3337 17.0669 18.3337 16.6002 18.3337 15.6667V9.33341C18.3337 8.39999 18.3337 7.93328 18.152 7.57676C17.9922 7.26316 17.7372 7.00819 17.4236 6.8484C17.0671 6.66675 16.6004 6.66675 15.667 6.66675H9.33366C8.40024 6.66675 7.93353 6.66675 7.57701 6.8484C7.2634 7.00819 7.00844 7.26316 6.84865 7.57676C6.66699 7.93328 6.66699 8.39999 6.66699 9.33341V15.6667C6.66699 16.6002 6.66699 17.0669 6.84865 17.4234C7.00844 17.737 7.2634 17.992 7.57701 18.1518C7.93353 18.3334 8.40024 18.3334 9.33366 18.3334Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10989_18235">
        <rect width={20} height={20} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconCopy02.defaultProps = defaultIconProps;
