import { useModalState } from 'components/ModalManager/Context';
import { useCallback, useEffect, useMemo } from 'react';
import { useWindowSize } from 'react-use';
import { PopoverStateReturn, usePopoverState } from 'reakit';

export type UseCompositePopoverProps = {
  /**
   * Use useModalActions hook to create open and close functions
   */
  modal: {
    id: string;
    open: () => void;
    close: () => void;
  };
  /**
   * Breakpoint width to toggle between modal and popover
   */
  breakpointWidth: number;
  /**
   * Use this prop to pass custom popover options, or an existing popover state
   */
  popover?: Partial<PopoverStateReturn>;
};

export type UseCompositePopoverReturn = {
  /**
   * Whether either popover or modal is visible
   */
  visible: boolean;
  /**
   * @note Even if you pass custom popover options, always use this state to control the popover
   */
  popoverState: PopoverStateReturn;
  /**
   * Based on window's width, toggle opening modal for mobile and popover for desktop.
   * Use it as onClick handler for the trigger element.
   */
  togglePopoverOrModal: (evt?: React.MouseEvent) => void;
  openPopoverOrModal: () => void;
  closePopoverOrModal: () => void;
};

export function useCompositePopover(props: UseCompositePopoverProps): UseCompositePopoverReturn {
  const { modals } = useModalState();
  const { width: windowWidth } = useWindowSize();
  const popoverState = usePopoverState(props.popover || { placement: 'bottom-end' });
  const modalState = useMemo(() => modals.find((modal) => modal.id === props.modal.id), [props, modals]);

  // Whether either popover or modal is visible
  const visible = useMemo(() => Boolean(popoverState.visible || (modalState && !modalState.closed)), [popoverState.visible, modalState]);

  const togglePopoverOrModal = useCallback(
    (evt?: React.MouseEvent) => {
      evt?.stopPropagation();
      evt?.preventDefault();

      if (windowWidth < props.breakpointWidth) {
        // Since mobile shows a modal, clicking the backdrop will anyway close the modal,
        // so no need of extra logic to toggle it
        props.modal.open();
        return;
      }

      // Toggle the popover for desktop
      if (popoverState.visible) {
        popoverState.hide();
      } else {
        popoverState.show();
      }
    },
    [windowWidth, popoverState, props.modal],
  );

  const openPopoverOrModal = useCallback(() => {
    if (visible) {
      return;
    }

    if (windowWidth < props.breakpointWidth) {
      props.modal.open();
    } else {
      popoverState.show();
    }
  }, [visible, windowWidth, popoverState, props.modal]);

  const closePopoverOrModal = useCallback(() => {
    if (!visible) {
      return;
    }

    if (windowWidth < props.breakpointWidth) {
      props.modal.close();
    } else {
      popoverState.hide();
    }
  }, [visible, windowWidth, popoverState, props.modal]);

  // If window's width gets resized, then close either the modal or popover
  // whichever is not meant to be shown for that screen size.
  useEffect(() => {
    if (windowWidth < props.breakpointWidth && popoverState.visible) {
      popoverState.hide();
    }

    if (windowWidth >= props.breakpointWidth && modalState && !modalState.closed) {
      props.modal.close();
    }
  }, [windowWidth, modalState, popoverState]);

  return {
    visible,
    popoverState,
    togglePopoverOrModal,
    openPopoverOrModal,
    closePopoverOrModal,
  };
}
