import { useIsOwner } from 'components/NoAppState/NoAppState.hooks';
import { useAuthState } from 'modules/Auth/AuthContext';
import { useCurrentUserId } from 'modules/Auth/hooks';
import { useRouter } from 'next/router';
import { propOr } from 'rambda';
import { useEffect, useRef, useState } from 'react';

export enum NavLinkType {
  profile = 'profile',
  collaborations = 'collaborations',
  community = 'community',
  controlCenter = 'controlCenter',
  compute = 'compute',
  playground = 'playground',
  home = 'home',
}

const COMMUNITY_PAGES = ['/explore', '/explore/models', '/explore/modules', '/explore/workflows', '/explore/apps', '/explore/datasets'];

/**
 * Determines which NavLink type to show as active.
 */
export function useActiveNavLinkType(): NavLinkType | null {
  const { authData } = useAuthState();
  const router = useRouter();
  const currentUserId = useCurrentUserId();
  const isResourceOwner = useIsOwner();
  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const delayTime = timeoutId.current ? 3000 : 100;

  const [activeLink, setActiveLink] = useState<NavLinkType | null>(null);

  const getActiveNavLinkType = () => {
    const loggedInUserId = propOr('', 'user_id')(authData || {});
    const pageUserOrOrgId = router.query?.userOrOrgId as string;
    const currentPathWithoutQueryParam = router.asPath?.split('?')[0];
    const isAnExplorePage = COMMUNITY_PAGES.some((page) => currentPathWithoutQueryParam === page);
    const isPageOfAnotherUserOrOrg = pageUserOrOrgId && pageUserOrOrgId !== currentUserId;

    if (currentPathWithoutQueryParam.startsWith('/home')) {
      return NavLinkType.home;
    }

    if (currentPathWithoutQueryParam.startsWith('/playground')) {
      return NavLinkType.playground;
    }

    if (currentPathWithoutQueryParam.startsWith(`/${loggedInUserId}/collaborations`)) {
      return NavLinkType.collaborations;
    }

    if (currentPathWithoutQueryParam.startsWith(`/control-center`)) {
      return NavLinkType.controlCenter;
    }

    if (currentPathWithoutQueryParam.startsWith(`/settings/compute`)) {
      return NavLinkType.compute;
    }

    if (isResourceOwner) {
      return NavLinkType.profile;
    }

    if (isAnExplorePage || isPageOfAnotherUserOrOrg) {
      return NavLinkType.community;
    }
    return null;
  };

  useEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    timeoutId.current = setTimeout(() => {
      setActiveLink(getActiveNavLinkType());
    }, delayTime);

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [router, currentUserId, authData, isResourceOwner]);

  return activeLink;
}

export function usePathWithoutQueryParams() {
  const { asPath } = useRouter();
  return asPath?.split('?')[0] || '';
}
