export const JiraLinks = {
  bugReport: {
    collectorId: '0b68141c',
    clickTargetSelector: '#atlwdg-trigger.atlwdg-RIGHT',
  },
  sendFeedback: {
    collectorId: '257d76d7',
    clickTargetSelector: '#atlwdg-trigger.atlwdg-SUBTLE',
  },
};
