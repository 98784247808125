import { Popover, PopoverStateReturn } from 'components/Popover';
import { PopoverButton, PopoverLink, PopoverSection } from 'components/Navbar';
import { useDataCollection } from 'modules/AnalyticsCollection/AnalyticsCollectionProvider';
import { useFeatureFlagsLD } from 'modules/FeatureFlagsLD/FeatureFlagsProviderLD';
import { accountPopover, accountPopoverLayout } from 'components/Navbar/Navbar.styles';
import {
  API_STATUS_LINK,
  CHANGELOG_LINK,
  CONTACT_US_LINK,
  DISCORD_LINK,
  DOCUMENTATION_LINK,
  PRODUCT_BOARD_LINK,
  QUICK_START_GUIDE_LINK,
} from 'constants/externalLinks';
import { JiraButton, JiraLinks } from 'components/Jira';

export interface HelpPopoverContentProps {
  canReport?: boolean;
}
export interface HelpPopoverProps extends HelpPopoverContentProps {
  popover: PopoverStateReturn;
}

export interface MobileHelpItemsProps extends HelpPopoverContentProps {
  closePopup?: () => void;
}

function HelpPopoverContents(props: HelpPopoverContentProps): JSX.Element {
  const { track } = useDataCollection();
  const { changelog } = useFeatureFlagsLD();

  const onHelpMenuClick = (btnText: string): void => {
    track('Help Menu Clicked', { param1: 'main_header', param2: btnText });
  };

  return (
    <>
      <PopoverSection title="HELP">
        <PopoverLink externalLink href={CONTACT_US_LINK} text="Contact Us" rel="noreferrer" onClick={() => onHelpMenuClick('Contact Us')} />
        <PopoverLink externalLink href={DOCUMENTATION_LINK} text="Documentation" rel="noreferrer" onClick={() => onHelpMenuClick('Documentation')} />
        <PopoverLink
          externalLink
          href={QUICK_START_GUIDE_LINK}
          text="Quick Start Guide"
          rel="noreferrer"
          onClick={() => onHelpMenuClick('Create Guide')}
        />
        <PopoverLink externalLink href={API_STATUS_LINK} text="API Status" rel="noreferrer" onClick={() => onHelpMenuClick('API Status')} />
        <PopoverLink
          externalLink
          href={DISCORD_LINK}
          text="Join our Discord Channel"
          onClick={() => onHelpMenuClick('Join our Discord Channel')}
          rel="noreferrer"
          data-testid="join-discord"
        />
        <PopoverLink
          externalLink
          href={PRODUCT_BOARD_LINK}
          text="Product Roadmap"
          data-testid="product-roadmap"
          rel="noreferrer"
          onClick={() => onHelpMenuClick('Product Roadmap')}
        />
      </PopoverSection>

      {props.canReport && (
        <PopoverSection title="REPORT">
          <JiraButton collectorId={JiraLinks.bugReport.collectorId} clickTargetSelector={JiraLinks.bugReport.clickTargetSelector}>
            <PopoverButton text="Report a Bug" onClick={() => onHelpMenuClick('Report a Bug')} />
          </JiraButton>
        </PopoverSection>
      )}

      {changelog && (
        <PopoverSection title="LATEST RELEASE NOTES">
          <PopoverLink externalLink href={CHANGELOG_LINK} text={changelog} rel="noreferrer" onClick={() => onHelpMenuClick('Latest Release Notes')} />
        </PopoverSection>
      )}
    </>
  );
}

export function MobileHelpItems({ canReport }: MobileHelpItemsProps): JSX.Element {
  return (
    <section className={accountPopoverLayout}>
      <HelpPopoverContents canReport={canReport} />
    </section>
  );
}

export function HelpPopover(props: HelpPopoverProps): JSX.Element | null {
  return (
    <Popover aria-label="Help" data-testid="navbar-help-popover" popover={props.popover} className={accountPopover}>
      <HelpPopoverContents canReport={props.canReport} />
    </Popover>
  );
}
